/* eslint-disable no-console */
import React from 'react';
import PropTypes from 'prop-types';
import ReactRouterProptypes from 'react-router-prop-types';

class PVErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    componentDidCatch(error, info) {
        // Display fallback UI
        this.setState({ hasError: true });
        // You can also log the error to an error reporting service
        console.warn(info);
        console.log(error);
    }

    render() {
        const { hasError } = this.state;
        // eslint-disable-next-line react/prop-types
        const { children, history } = this.props;
        if (hasError) {
            history.push('/Error/500');
        }
        return children;
    }
}

PVErrorBoundary.propTypes = {
    children: PropTypes.node.isRequired,
    history: ReactRouterProptypes.history.isRequired,
};

export default PVErrorBoundary;
