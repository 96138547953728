import fetchJson from './api';
import { actions } from './contentSlice';

const getLandingBasePath = (landingName) => {
    const landings = ['dom', 'intl', 'wire'];
    if (landings.indexOf(landingName) > -1) {
        return 'LandingPages/SharedCarousel';
    }
    return 'LandingPages/Main/MainCarousel';
};
const getLandingJson = (landingName) => {
    return async (dispatch) => {
        const landing = (landingName || '').toLowerCase().trim();
        const basePath = `${getLandingBasePath(landing)}/json`;
        let path = '';
        switch (landing) {
            case 'dom':
                path = `${basePath}/PVMainDom.json`;
                break;
            case 'wire':
                path = `${basePath}/PVMainWire.json`;
                break;
            case 'intl':
                path = `${basePath}/PVMainIntl.json`;
                break;
            default:
                path = `${basePath}/PVMainGlobal.json`; //main
        }
        const response = await fetchJson(path).catch((err) => Promise.reject(err));
        const json = await response.json();
        if (response.ok) {
            const jsonContent = json;
            dispatch(actions.setLandingContent({ landingName: landing, jsonContent }));
            return Promise.resolve(jsonContent);
        }
        return Promise.reject();
    };
};
const contentAsync = { getLandingJson, getLandingBasePath };
export { getLandingJson, getLandingBasePath };
export default contentAsync;
