import Storage from 'react-native-storage';
import { PVPaymentTypeEnum } from '../components/PVWebstore/PropTypes/PVPropsPaymentInfo';
import { selectPaymentSlice } from '../redux/checkout-payment/selectors';
import { selectShippingSlice } from '../redux/checkout-shipping/selectors';
import { selectSlice } from '../redux/checkout-addresses/selectors';
import { selectCheckoutSelectionsSlice } from '../redux/checkout-selections/selectors';
import { selectSessionId } from '../redux/userProfile/selectors';

const storageParams = {
    storageBackend: window.localStorage,
};

const saveToLocalStorage = (expires, data, key) => {
    const storage = new Storage({
        ...storageParams,
    });
    storage.save({ expires, key, data });
};

const saveForPaypalRedirect = (token) => {
    return async (_dispatch, getState) => {
        const state = getState();
        const reduxData = {
            token,
            checkoutPayment: selectPaymentSlice(state),
            checkoutShipping: selectShippingSlice(state),
            checkoutAddresses: selectSlice(state),
            checkoutSelections: selectCheckoutSelectionsSlice(state),
            SessionId: selectSessionId(state),
        };
        const { checkoutPayment, SessionId } = reduxData;
        if (checkoutPayment.payment && checkoutPayment.payment.type !== PVPaymentTypeEnum.PayPal) {
            return Promise.reject(
                new Error('Unable to save state when payment type is not PayPal')
            );
        }
        const expires = 2000 * 60 * 60;
        const key = `${SessionId}Paypal`;
        saveToLocalStorage(expires, reduxData, key);
        return Promise.resolve(reduxData);
    };
};
const isPaypalDataStored = (SessionId) => {
    const key = `${SessionId}Paypal`;
    const storage = new Storage({ ...storageParams });
    return storage
        .load({ key })
        .then(() => true)
        .catch(() => false);
};
const getPaypalFromStorage = (SessionId) => {
    const key = `${SessionId}Paypal`;
    const storage = new Storage({ ...storageParams });
    return storage
        .load({ key })
        .then((data) => {
            return Promise.resolve(data);
        })
        .catch((error) => {
            if (error.name && error.name === 'NotFoundError') {
                throw new Error('Unable to resume from PayPal');
            }
        });
};
const destroyPaypalStorage = (SessionId) => {
    const key = `${SessionId}Paypal`;
    const storage = new Storage({ ...storageParams });
    return storage.remove({ key });
};

export const paypal = {
    saveForPaypalRedirect,
    getPaypalFromStorage,
    destroyPaypalStorage,
    isPaypalDataStored,
};
const helpers = { saveToLocalStorage };
export default helpers;
