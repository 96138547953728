import { WebServiceHosts, AuthHeader } from '../../helpers/siteConfigConstants';
import { shouldFetch, ABORTREASONS } from '../../helpers/apiShared';

const postShipToAddress = (address, id, isCpu = false) => {
    const uri = `${
        WebServiceHosts.WebStore
    }/api/checkout/addresses/shipto/${id}/${isCpu.toString()}`;
    const body = address || {};
    return fetch(uri, {
        headers: { Authorization: AuthHeader, 'Content-Type': 'application/json' },
        cache: 'no-cache',
        method: 'POST',
        body: JSON.stringify(body),
    });
};

const putFreightSelection = (freightQuoteList) => {
    const uri = `${WebServiceHosts.WebStore}/api/checkout/freight/quote`;
    const body = freightQuoteList || [];
    return fetch(uri, {
        headers: { Authorization: AuthHeader, 'Content-Type': 'application/json' },
        cache: 'no-cache',
        method: 'PUT',
        body: JSON.stringify(body),
    });
};

const postPayment = (paymentInfo) => {
    const uri = `${WebServiceHosts.WebStore}/api/checkout/payment`;
    const body = paymentInfo || {};
    return fetch(uri, {
        headers: { Authorization: AuthHeader, 'Content-Type': 'application/json' },
        cache: 'no-cache',
        method: 'POST',
        body: JSON.stringify(body),
    });
};

const shouldFetchSelection = (stateSlice, delayMs = 500) => {
    let booFetch = false;
    if (!stateSlice) {
        return true;
    }
    const fetchStatus = shouldFetch(stateSlice.isFetching, stateSlice.receivedAt, delayMs);
    booFetch = fetchStatus === ABORTREASONS.NO_ABORT;
    return booFetch;
};

const api = { postShipToAddress, putFreightSelection, shouldFetchSelection, postPayment };
export default api;
export { api, postShipToAddress, putFreightSelection, shouldFetchSelection, postPayment };
