import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { selectCartItems } from '../../../../../redux/cart/selectors';
import { parseUrl, getPriceString } from '../../../../../helpers/textFunctions';
import '../../../../../../Content/PVMiniCart.css';
import getFallbackImage from '../../../../../helpers/getFallbackImage';

const PrevArrow = (props) => {
    const { onClick } = props;
    return (
        <button
            className="slick-arrow slick-prev"
            style={{ cursor: 'pointer' }}
            onClick={onClick}
            type="submit"
        >
            <i className="fa fa-chevron-left fa-2x" aria-label="previous" />
        </button>
    );
};
PrevArrow.propTypes = {
    onClick: PropTypes.func,
};
PrevArrow.defaultProps = {
    onClick: () => {},
};
const NextArrow = (props) => {
    const { onClick } = props;
    return (
        <button
            className="slick-arrow slick-next"
            onClick={onClick}
            style={{ cursor: 'pointer' }}
            type="submit"
        >
            <i className="fa fa-chevron-right fa-2x" aria-label="next" />
        </button>
    );
};
NextArrow.propTypes = {
    onClick: PropTypes.func,
};
NextArrow.defaultProps = {
    onClick: () => {},
};
const PVCartFlyoutSlider = (props) => {
    const { cartItems } = props;
    const sliderSettings = {
        dots: false,
        slidesToScroll: 1,
        slidesToShow: 4,
        infinite: cartItems.length >= 4,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 1199,
                settings: {
                    vertical: false,
                    verticalSwiping: false,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 991,
                settings: {
                    vertical: false,
                    verticalSwiping: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 767,
                settings: 'unslick',
            },
        ],
    };
    return (
        <React.Fragment>
            {cartItems.length > 0 ? (
                <React.Fragment>
                    <Slider {...sliderSettings}>
                        {cartItems.map((item) => {
                            return (
                                <div
                                    className="minicart-content-wrapper product-cart-item product-item style1"
                                    key={item.ItemID}
                                >
                                    <Link
                                        to={`/Shop/Product/${item.ItemID}`}
                                        className="thumb-inner"
                                    >
                                        <img
                                            src={parseUrl(item.ThumbnailImagePath)}
                                            onError={(e) => getFallbackImage(e)}
                                            alt={
                                                (item.ShortDesc || '').length > 0
                                                    ? item.ShortDesc
                                                    : item.Name
                                            }
                                            height={100}
                                            className="center-block"
                                        />
                                    </Link>
                                    <div className="product-info">
                                        <Link to={`/Shop/Product/${item.ItemID}`}>
                                            <div className="row mini-cart-product-name-row">
                                                <div className="col-xs-12">
                                                    <div className="product-name">{item.Name}</div>
                                                </div>
                                            </div>
                                            <div className="row mini-cart-product-qty-price-row">
                                                <div className="col-xs-12">
                                                    <span className="pull-left">
                                                        <strong>Qty: {item.Quantity}</strong>
                                                    </span>
                                                    <span className="pull-right">
                                                        <strong>
                                                            Price: {getPriceString(item.Price)}
                                                        </strong>
                                                    </span>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            );
                        })}
                    </Slider>
                </React.Fragment>
            ) : (
                ''
            )}
        </React.Fragment>
    );
};

PVCartFlyoutSlider.propTypes = {
    cartItems: PropTypes.arrayOf(PropTypes.object),
};
PVCartFlyoutSlider.defaultProps = {
    cartItems: [],
};
const mapStateToProps = (state) => {
    return {
        cartItems: selectCartItems(state),
    };
};

export default connect(mapStateToProps)(PVCartFlyoutSlider);
