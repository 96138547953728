import { createSlice } from '@reduxjs/toolkit';
import { propsByActionType, APIACTIONS } from '../../helpers/apiShared';

const checkoutAddressesSlice = createSlice({
    name: 'checkoutAddresses',
    initialState: {},
    reducers: {
        getLastUsedSent: (state, action) => {
            const fetchProps = propsByActionType(APIACTIONS.REQUEST);
            const { payload } = action;
            const lastUsedAddress = { payload, ...fetchProps };
            return { ...state, lastUsedAddress };
        },
        getLastUsedSuccess: (state, action) => {
            const fetchProps = propsByActionType(APIACTIONS.SUCCESS);
            const { payload } = action;
            const lastUsedAddress = { ...payload, ...fetchProps };
            return { ...state, lastUsedAddress };
        },
        getLastUsedFailure: (state, action) => {
            const fetchProps = propsByActionType(APIACTIONS.FAILURE);
            const { payload } = action;
            const lastUsedAddress = { ...payload, ...fetchProps };
            return { ...state, lastUsedAddress };
        },
        invalidateLastUsedAddress: (state, _action) => {
            return { ...state, lastUsedAddress: {} };
        },
        getSavedListSent: (state, action) => {
            const fetchProps = propsByActionType(APIACTIONS.REQUEST);
            const { payload } = action;
            const savedList = { payload, ...fetchProps };
            return { ...state, savedList };
        },
        getSavedListSuccess: (state, action) => {
            const fetchProps = propsByActionType(APIACTIONS.SUCCESS);
            const { payload } = action;
            const savedList = { ...payload, ...fetchProps };
            return { ...state, savedList };
        },
        getSavedListFailure: (state, action) => {
            const fetchProps = propsByActionType(APIACTIONS.FAILURE);
            const { payload } = action;
            const savedList = { ...payload, ...fetchProps };
            return { ...state, savedList };
        },
        getAddressSearchSent: (state, action) => {
            const fetchProps = propsByActionType(APIACTIONS.REQUEST);
            const { payload } = action;
            const AddressSearch = { payload, ...fetchProps };
            return { ...state, AddressSearch };
        },
        getAddressSearchSuccess: (state, action) => {
            const fetchProps = propsByActionType(APIACTIONS.SUCCESS);
            const { payload } = action;
            const AddressSearch = { ...payload, ...fetchProps };
            return { ...state, AddressSearch };
        },
        getAddressSearchFailure: (state, action) => {
            const fetchProps = propsByActionType(APIACTIONS.FAILURE);
            const { payload } = action;
            const AddressSearch = { ...payload, ...fetchProps };
            return { ...state, AddressSearch };
        },
        getCityStateSent: (state, action) => {
            const fetchProps = propsByActionType(APIACTIONS.REQUEST);
            const { payload } = action;
            const zip = { payload, ...fetchProps };
            const oldZip = state.zip;
            return { ...state, zip: { ...oldZip, ...zip } };
        },
        getCityStateSuccess: (state, action) => {
            const fetchProps = propsByActionType(APIACTIONS.SUCCESS);
            const { zip, json } = action.payload;
            const oldZip = state.zip;
            const data = { [zip]: { ...fetchProps, ...json } };
            return { ...state, zip: { ...oldZip, ...data } };
        },
        getCityStateFailure: (state, action) => {
            const fetchProps = propsByActionType(APIACTIONS.FAILURE);
            const { zip, json } = action.payload;
            const data = { [zip]: { ...fetchProps, ...json } };
            const oldZip = state.zip;
            return { ...state, zip: { ...oldZip, ...data } };
        },
        setAddressId: (state, action) => {
            const selectedAddressId = action.payload;
            return { ...state, selectedAddressId };
        },
        setShippingAddressLocal: (state, action) => {
            const fetchProps = propsByActionType(APIACTIONS.LOCALCHANGE);
            return {
                ...state,
                shippingAddress: { ...action.payload.shippingAddress, ...fetchProps },
            };
        },
        setShippingAddressSent: (state, action) => {
            const fetchProps = propsByActionType(APIACTIONS.REQUEST);
            const shippingAddressServer = { ...fetchProps, ...action.payload };
            return { ...state, shippingAddressServer };
        },
        setShippingAddressSuccess: (state, action) => {
            const fetchProps = propsByActionType(APIACTIONS.SUCCESS);
            return {
                ...state,

                shippingAddressServer: { ...action.payload.shippingAddress, ...fetchProps },
            };
        },
        setShippingAddressFailure: (state, action) => {
            const fetchProps = propsByActionType(APIACTIONS.FAILURE);
            const shippingAddressServer = { ...fetchProps, ...action.payload };
            return { ...state, shippingAddressServer };
        },
        getPickupLocationsSent: (state, action) => {
            const fetchProps = propsByActionType(APIACTIONS.REQUEST);
            const { payload } = action;
            const pickupLocations = { ...payload, ...fetchProps };
            return { ...state, pickupLocations };
        },
        getPickupLocationsSuccess: (state, action) => {
            const fetchProps = propsByActionType(APIACTIONS.SUCCESS);
            const list = action.payload;
            const pickupLocations = { list, ...fetchProps };
            return { ...state, pickupLocations };
        },
        getPickupLocationsFailure: (state, action) => {
            const fetchProps = propsByActionType(APIACTIONS.FAILURE);
            const oldLocations = state.pickupLocations;
            const error = action.payload;
            const pickupLocations = { ...oldLocations, ...fetchProps, error };
            return { ...state, pickupLocations };
        },
        invalidateCheckoutAddresses: (_state, _action) => {
            return {};
        },
        rehydrateAddresses: (state, action) => {
            const { payload } = action;
            return { ...state, ...payload };
        },
    },
});

export const { actions, reducer } = checkoutAddressesSlice;
