import React from 'react';
import PropTypes from 'prop-types';
import PVImageSlider from '../Carousel/PVImageSlider';

let dealerSrcs = [
    {
        id: 0,
        description: 'ATT Commercial',
        imageSrc: 'b7cd2c2f-e65f-403d-87af-dcfede3df809',
        link: '/Partner/ATTForBusiness',
        reactLink: true,
    },
    {
        id: 1,
        description: 'ATT Residential',
        imageSrc: '77edd244-ab3a-44ca-844e-b59e32347daf',
        link: '/Partner/ATTResidential',
        reactLink: true,
    },
    {
        id: 2,
        description: 'CenturyLink',
        imageSrc: 'bda7819e-6cc7-47e5-ae64-38bc70b73602',
        link: '/Partner/Centurylink',
        reactLink: true,
    },
    {
        id: 3,
        description: 'Frontier',
        imageSrc: 'c00bfca1-e6c8-4f9a-8586-b1df0e59aed3',
        link: '/Partner/Frontier',
        reactLink: true,
    },
    {
        id: 4,
        description: 'Viasat',
        imageSrc: '58963627-66dd-4ff3-8afa-d1b54bf05e55',
        link: '/Partner/ViasatInternet',
        reactLink: true,
    },
    {
        id: 5,
        description: 'Windstream',
        imageSrc: 'fb9f5d5c-4f10-4bbd-8506-bc6fb183ceed',
        link: '/Partner/KineticbyWindstream',
        reactLink: true,
    },
];

const PVPartnerSliderCMS = (props) => {
    const { title, sliderContent, sliderClass } = props;

    if (sliderContent.length > 0) {
        dealerSrcs = [...sliderContent];
    }

    return (
        <PVImageSlider
            slideAmt={6}
            sectionTitle={title}
            content={dealerSrcs}
            slideClass={sliderClass}
        />
    );
};

PVPartnerSliderCMS.propTypes = {
    title: PropTypes.string,
    sliderContent: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            description: PropTypes.string.isRequired,
            imageSrc: PropTypes.string.isRequired,
            link: PropTypes.string.isRequired,
            reactLink: PropTypes.bool.isRequired,
        })
    ),
    sliderClass: PropTypes.string,
};

PVPartnerSliderCMS.defaultProps = {
    title: 'Vendors',
    sliderContent: [],
    sliderClass: '',
};

export default PVPartnerSliderCMS;
