import { combineReducers } from 'redux';
import { enableES5, setAutoFreeze } from 'immer';
import { reducer as userProfile } from './userProfile/userProfileSlice';
import { reducer as menus } from './menus/menusSlice';
import { reducer as products } from './products';
import { reducer as cart } from './cart/cartSlice';
import { reducer as alerts } from './alerts/alertSlice';
import { reducer as checkoutSelections } from './checkout-selections/checkoutSelectionsSlice';
import { reducer as checkoutAddresses } from './checkout-addresses/checkoutAddressesSlice';
import { reducer as orderHistory } from './orderhistory/orderedSlice';
import { reducer as checkoutShipping } from './checkout-shipping/checkoutShippingSlice';
import { reducer as checkoutPayment } from './checkout-payment/checkoutPaymentSlice';
import { reducer as checkoutSteps } from './checkout-steps/checkoutStepsSlice';
import { reducer as siteUtils } from './siteUtils/siteUtilsSlice';
import { reducer as content } from './content/contentSlice';
import { reducer as formRedux } from 'redux-form';
//import { reducer as supportTicket } from './supportTicket/supportTicketSlice';

enableES5();
setAutoFreeze(false);

const rootReducer = combineReducers({
    userProfile,
    products,
    menus,
    cart,
    alerts,
    checkoutSelections,
    checkoutAddresses,
    checkoutShipping,
    checkoutPayment,
    orderHistory,
    content,
    checkoutSteps, //needs a fuller implementation
    siteUtils,
    form: formRedux,
    //supportTicket,
});

export default rootReducer;
