import { createSlice } from '@reduxjs/toolkit';

const menuSlice = createSlice({
    name: 'menu',
    initialState: {},
    reducers: {
        replaceMenus: (state, action) => {
            const topMenus = action.payload;
            return { ...state, topMenus };
        },
    },
});

export const { actions, reducer } = menuSlice;
export const { replaceMenus } = actions;
export default reducer;
