import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';

const PVBaseModal = (props) => {
    Modal.setAppElement(document.querySelector('#ClientApp'));
    const { children, ...modalProps } = props;
    return <Modal {...modalProps}>{children}</Modal>;
};

PVBaseModal.propTypes = {
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
};

export default PVBaseModal;
