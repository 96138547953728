import * as selectionsSelectors from './selectors';
import * as selectionsAsync from './actionsAsync';
import { actions as selectionsActions } from './checkoutSelectionsSlice';

const rdxSelections = {
    actionsAsync: selectionsAsync,
    selectors: selectionsSelectors,
    actions: selectionsActions,
};

export default rdxSelections;
