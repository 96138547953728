import { createSlice } from '@reduxjs/toolkit';
import produce from 'immer';
import * as cloneDeep from 'clone-deep';

import { propsByActionType, APIACTIONS } from '../../../helpers/apiShared';

const featuredSlice = createSlice({
    name: 'featured',
    initialState: {},
    reducers: {
        getFeaturedSent: (state, action) => {
            const fetchProps = propsByActionType(APIACTIONS.REQUEST);
            const { featuredType, itemId } = action.payload;
            const newState = produce(state, (draftInitial) => {
                const draft = cloneDeep(draftInitial);
                draft[featuredType] = draft[featuredType] || {};
                if (itemId && itemId.length > 0) {
                    draft[featuredType][itemId] = { ...fetchProps, list: [] };
                } else {
                    draft[featuredType] = { ...fetchProps, list: [] };
                }
                draft.invalid = false;
                return draft;
            });
            return { ...state, ...newState };
        },
        getFeaturedSuccess: (state, action) => {
            const fetchProps = propsByActionType(APIACTIONS.SUCCESS);
            const { featuredType, itemId, list } = action.payload;
            const newState = produce(state, (draftInitial) => {
                const draft = cloneDeep(draftInitial);
                draft[featuredType] = draft[featuredType] || {};
                if (itemId && itemId.length > 0) {
                    draft[featuredType][itemId] = { ...fetchProps, list };
                } else {
                    draft[featuredType] = { ...fetchProps, list };
                }
                draft.invalid = false;
                return draft;
            });
            return { ...state, ...newState };
        },
        getFeaturedFailure: (state, action) => {
            const fetchProps = propsByActionType(APIACTIONS.FAILURE);
            const { featuredType, itemId, error } = action.payload;
            const newState = produce(state, (draftInitial) => {
                const draft = cloneDeep(draftInitial);
                draft[featuredType] = draft[featuredType] || {};
                if (itemId && itemId.length > 0) {
                    const old = draft[featuredType][itemId];
                    draft[featuredType][itemId] = { ...old, ...fetchProps, error };
                } else {
                    const old = draft[featuredType];
                    draft[featuredType] = { ...old, ...fetchProps, error };
                }
                draft.invalid = false;
                return draft;
            });

            return { ...state, ...newState };
        },
        invalidateFeatured: (_state, _action) => {
            return {};
        },
    },
});

export const { actions, reducer } = featuredSlice;
export default reducer;
