import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { withRouter } from 'react-router-dom';
import Autosuggest from 'react-autosuggest';
import { getItemIds } from '../../../../redux/products/items/actionsAsync';
import { selectItemIdsList } from '../../../../redux/products/items/selectors';

const PVSearchAutoSuggest = (props) => {
    const { id, startValue, history, itemIDs, apiGetIds } = props;

    const [value, setValue] = useState('');
    const [suggestions, setSuggestions] = useState([]);

    useEffect(() => {
        setValue(startValue);
        if (itemIDs.length === 0) {
            apiGetIds();
        }
    }, [startValue]);

    // Temp - copied from PVSearchBar to get minimum viable product working
    const buildQueryString = (newPcID, newSearch) => {
        let queryString = '';

        if (newPcID.length > 0 && newPcID !== '0' && newSearch.length > 0) {
            queryString = `pcID=${newPcID}&search=${newSearch}`;
        } else if (newSearch.length > 0) {
            queryString = `search=${newSearch}`;
        } else if (newPcID.length > 0 && newPcID !== '0') {
            queryString = `pcID=${newPcID}`;
        }

        return queryString;
    };

    // Teach Autosuggest how to calculate suggestions for any given input value.
    const getSuggestions = (newValue) => {
        const inputValue = newValue.trim().toLowerCase();
        const inputLength = inputValue.length;

        return inputLength === 0
            ? []
            : itemIDs.filter((i) => i.toLowerCase().slice(0, inputLength) === inputValue);
    };

    const isExactMatch = (newValue) => {
        const inputValue = newValue.trim().toLowerCase();
        const matches = itemIDs.filter((i) => i.toLowerCase() === inputValue);
        return matches.length === 1;
    };

    // When suggestion is clicked, Autosuggest needs to populate the input
    // based on the clicked suggestion. Teach Autosuggest how to calculate the
    // input value for every given suggestion.
    //const getSuggestionValue = suggestion => suggestion.name;
    const getSuggestionValue = (suggestion) => suggestion;

    // Use your imagination to render suggestions.
    //const renderSuggestion = suggestion => <div>{suggestion.name}</div>;
    const renderSuggestion = (suggestion) => <div>{suggestion}</div>;

    // Autosuggest is a controlled component.
    // This means that you need to provide an input value
    // and an onChange handler that updates this value (see below).
    // Suggestions also need to be provided to the Autosuggest,
    // and they are initially empty because the Autosuggest is closed.

    const handleChange = (newValue) => {
        if (isExactMatch(newValue)) {
            document.getElementById('pv-search-combo').value = 0;
            history.push(`/Shop/Product/${newValue.trim()}`);
        } else {
            const newPcID = document.getElementById('pv-search-combo').selectedOptions[0].value;
            const newQueryString = buildQueryString(newPcID, newValue.trim());
            history.push(`/Products?${newQueryString}`);
        }
    };

    const onChange = (event, { newValue, method }) => {
        setValue(newValue);
        if (method === 'click') {
            handleChange(newValue);
        }
    };

    const onKeyPress = (event) => {
        if (event.key === 'Enter') {
            const newValue = event.target.value;
            handleChange(newValue);
        }
    };

    // Autosuggest will call this function every time you need to update suggestions.
    // You already implemented this logic above, so just use it.
    // eslint-disable-next-line no-shadow
    const onSuggestionsFetchRequested = ({ value }) => {
        setSuggestions(getSuggestions(value));
    };

    // Autosuggest will call this function every time you need to clear suggestions.
    const onSuggestionsClearRequested = () => {
        setSuggestions([]);
    };

    // Autosuggest will pass through all these props to the input.
    const inputProps = {
        placeholder: 'Search',
        value,
        onChange,
        onKeyPress,
        id,
        spellCheck: false,
    };

    // Finally, render it!
    return (
        <Autosuggest
            suggestions={suggestions}
            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
            onSuggestionsClearRequested={onSuggestionsClearRequested}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            inputProps={inputProps}
        />
    );
};

PVSearchAutoSuggest.propTypes = {
    id: PropTypes.string.isRequired,
    startValue: PropTypes.string.isRequired,
    history: ReactRouterPropTypes.history.isRequired,
    itemIDs: PropTypes.arrayOf(PropTypes.string),
    apiGetIds: PropTypes.func.isRequired,
};
PVSearchAutoSuggest.defaultProps = {
    itemIDs: [],
};

const mapStateToProps = (state) => {
    return {
        itemIDs: selectItemIdsList(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        apiGetIds: () => {
            dispatch(getItemIds());
        },
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PVSearchAutoSuggest));
