import * as addressAsync from './actionsAsync';
import { actions as addressActions } from './checkoutAddressesSlice';
import * as addressSelectors from './selectors';

const rdxAddress = {
    actionsAsync: addressAsync,
    selectors: addressSelectors,
    actions: addressActions,
};

export default rdxAddress;
