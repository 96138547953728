import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getTopMenus } from '../../../../redux/menus/selectors';

const PVMenuBar = (props) => {
    const { topMenus } = props;

    function wrapperClick(evt) {
        if (evt.target.closest('#pv-nav-window') ||
            evt.target.closest('#pv-nav-menu-bar') ||
            evt.target.closest('#pv-nav-hamburger')
        ) {
            return false;
        }
        document.getElementById('pv-nav-menu').classList.remove('toggled');
        document.removeEventListener('click', wrapperClick);
        return true;
    }

    function menuToggle() {
        document.getElementById('pv-nav-menu').classList.add('toggled');
        document.addEventListener('click', wrapperClick);
    }

    function moveCanvasWithoutAnimation(wholeMenu, callback) {
        wholeMenu.classList.remove('pv-nav-animate');
        callback();
    }

    function moveCanvasWithAnimation(wholeMenu, callback) {
        wholeMenu.classList.add('pv-nav-animate');
        callback();
    }

    function moveCanvas(navToMenu) {
        const listItems = navToMenu.querySelectorAll('ul div li');
        const itemCount = listItems.length;
        const maxHeight = 579;
        const itemHeight = 40;
        const navBack = 42;
        const divider = 1;
        const navBold = 42;
        const topThreeItems = navBack + divider + navBold;
        const extraPadding = 14;
        let height = 0;
        const isMenuBar = navToMenu.closest('.is-sub-menu') != null;
        const firstItemIsNavBackMainOnly = listItems[0].firstChild.classList.contains(
            'pv-nav-back-main-only'
        );
        const firstItemIsMainMenu = listItems[0].firstChild.innerText.toUpperCase() === 'MAIN MENU';
        const isFirstMenuUnderMenuBar = isMenuBar && firstItemIsNavBackMainOnly;
        const isFirstMenuUnderHamburger =
            !isMenuBar && !firstItemIsNavBackMainOnly && firstItemIsMainMenu;

        if (isFirstMenuUnderMenuBar) {
            height = (itemCount - 3) * itemHeight + extraPadding;
        } else if (isFirstMenuUnderHamburger) {
            height = (itemCount - 1) * itemHeight + navBold + extraPadding;
        } else {
            height = (itemCount - 3) * itemHeight + topThreeItems + extraPadding;
        }

        if (height < maxHeight) {
            document.getElementById('pv-nav-window').style.height = `${height}px`;
        } else {
            document.getElementById('pv-nav-window').style.height = `${maxHeight}px`;
        }

        const navToMenuVerticalOrder = navToMenu.dataset.order;
        const navToMenuLevel = navToMenu.parentNode.parentNode;
        const newLevelTop = `${(navToMenuVerticalOrder - 1) * -1200}px`;
        navToMenuLevel.style.top = newLevelTop;
        menuToggle();
        const navToMenuHorizontalOrder = navToMenuLevel.dataset.order;
        const canvas = document.getElementById('pv-nav-canvas');
        const newCanvasLeft = `${(navToMenuHorizontalOrder - 1) * -350}px`;
        canvas.style.left = newCanvasLeft;
    }

    function handleClick(e) {
        const navToHref = e.currentTarget.getAttribute('href');
        const isMenuNavigation = navToHref.substr(0, 1) === '#';
        const wholeMenu = document.getElementById('pv-nav-menu');
        const menuType = e.currentTarget.dataset.menu;

        let isAnimationDesired = false;
        if (menuType === 'main' || menuType === 'sub') {
            const left = e.currentTarget.offsetLeft;
            if (menuType === 'sub') {
                wholeMenu.style.left = `${left - 28}px`;
                wholeMenu.style.top = '26px';
            } else {
                wholeMenu.style.left = '0px';
                wholeMenu.style.top = '0px';
            }
            isAnimationDesired = false;
        } else {
            isAnimationDesired = true;
        }

        if (menuType === 'main') {
            wholeMenu.classList.remove('is-sub-menu');
            wholeMenu.classList.add('is-main-menu');
        }

        if (menuType === 'sub') {
            wholeMenu.classList.remove('is-main-menu');
            wholeMenu.classList.add('is-sub-menu');
        }

        if (isMenuNavigation) {
            const navToID = navToHref.substr(1);

            if (navToID === 'Close') {
                wholeMenu.style.display = 'none';
            } else {
                //   wholeMenu.style.display = 'block';
                const navToMenu = document.getElementById(navToID);
                navToMenu.scrollTop = 0;

                if (isAnimationDesired) {
                    moveCanvasWithAnimation(wholeMenu, function move() {
                        moveCanvas(navToMenu);
                    });
                } else {
                    moveCanvasWithoutAnimation(wholeMenu, function move() {
                        moveCanvas(navToMenu);
                    });
                }
            }
            e.preventDefault();
        } else {
            wholeMenu.classList.remove('toggled');
            wholeMenu.style.display = 'none';
        }
    }

    const renderPvMenuItem = (items) => {
        ////// console.log('*** renderPvMenuItem *** ' + JSON.stringify(items));
        return (
            <div>
                {items.map((item) => {
                    let iconClass = '';

                    if (item.itemClass === 'pv-nav-forward') {
                        iconClass = 'pv-nav-icon-angle-right';
                    } else if (
                        item.itemClass === 'pv-nav-back' ||
                        item.itemClass === 'pv-nav-back-main-only'
                    ) {
                        iconClass = 'pv-nav-icon-angle-left';
                    }

                    if (item.itemPageType === 'React' && item.itemClass === 'pv-nav-item-link') {
                        return (
                            <li key={item.itemID}>
                                <Link
                                    to={item.itemHref}
                                    className={`${item.itemClass} ${iconClass}`}
                                    draggable="false"
                                    onClick={handleClick}
                                >
                                    {item.itemName}
                                </Link>
                            </li>
                        );
                    }

                    return (
                        <React.Fragment>
                            {item.isOpenLinkInNewTab ? (
                                <li key={item.itemID}>
                                    <a
                                        href={item.itemHref}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className={`${item.itemClass} ${iconClass}`}
                                        draggable="false"
                                        onClick={handleClick}
                                    >
                                        {item.itemName}
                                    </a>
                                </li>
                            ) : (
                                <li key={item.itemID}>
                                    <a
                                        href={item.itemHref}
                                        className={`${item.itemClass} ${iconClass}`}
                                        draggable="false"
                                        onClick={handleClick}
                                    >
                                        {item.itemName}
                                    </a>
                                </li>
                            )}
                        </React.Fragment>
                    );
                })}
            </div>
        );
    };

    const renderPvMenu = (menus) => {
        ////// console.log("*** renderPvMenu *** " + JSON.stringify(menus));
        return (
            <div>
                {menus.map((menu) => {
                    return (
                        <div
                            key={menu.menuID}
                            id={menu.menuID}
                            data-order={menu.menuOrder}
                            data-menu-name={menu.menuName}
                            className={`pv-nav-menu ${menu.menuClass}`}
                            draggable="false"
                        >
                            <ul>{renderPvMenuItem(menu.menuItems)}</ul>
                        </div>
                    );
                })}
            </div>
        );
    };

    const renderPvNavMenuLevel = (levels) => {
        ////// console.log("*** renderPvNavMenuLevel *** " + JSON.stringify(levels));
        return (
            <div id="pv-nav-canvas">
                {levels.map((level) => {
                    return (
                        <div key={level.levelID} id={level.levelID} data-order={level.levelOrder}>
                            {renderPvMenu(level.levelMenus)}
                        </div>
                    );
                })}
            </div>
        );
    };

    const renderPvNavMenuBar = (menuBarItems) => {
        ////// console.log("*** renderPvNavMenuBar *** " + JSON.stringify(menuBarItems));
        return (
            <div id="pv-nav-menu-bar" className="pv-nav-menu-bar">
                {menuBarItems.map((menuBarItem) => {
                    if (menuBarItem.itemClass === 'pv-nav-forward') {
                        return (
                            <a
                                key={menuBarItem.itemID}
                                href={menuBarItem.itemHref}
                                className="pv-nav-menu-bar-item pv-nav-icon-angle-down"
                                data-menu="sub"
                                draggable="false"
                                onClick={handleClick}
                            >
                                {menuBarItem.itemName}
                            </a>
                        );
                    }
                    return null;
                })}
            </div>
        );
    };

    const renderPvNavHamburger = () => {
        return (
            <div id="pv-nav-hamburger" className="navbar-toggler" data-toggle="collapse">
                <a
                    href="#MenuMain"
                    className="pv-nav-three-bar"
                    data-menu="main"
                    draggable="false"
                    onClick={handleClick}
                    aria-label="Main Menu"
                >
                    {null}
                </a>
            </div>
        );
    };

    ////// console.log("*** PVMenuBar *** " + JSON.stringify(topMenus));
    return (
        <div id="pv-nav">
            <nav className="navbar navbar-expand-lg">
                {renderPvNavHamburger()}
                <div className="container">
                    {renderPvNavMenuBar(topMenus[0].levelMenus[0].menuItems)}
                </div>
            </nav>
            <div id="pv-nav-menu">
                <div id="pv-nav-window">{renderPvNavMenuLevel(topMenus)}</div>
            </div>
        </div>
    );
};

PVMenuBar.propTypes = {
    topMenus: PropTypes.oneOfType([PropTypes.any]).isRequired,
};

const mapStateToProps = (state) => {
    return { topMenus: getTopMenus(state) };
};

export default connect(mapStateToProps, null)(PVMenuBar);
