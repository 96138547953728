import { createSelector } from 'reselect';

import helpers from './helpers';
import rdxAddress from '../checkout-addresses';
import rdxSelections from '../checkout-selections';
import rdxShipping from '../checkout-shipping';
import { PVPaymentTypeEnum } from '../../components/PVWebstore/PropTypes/PVPropsPaymentInfo';

const selectContinueToFreightDisabled = createSelector(
    [
        rdxAddress.selectors.selectShippingAddressSubslice,
        rdxAddress.selectors.selectShippingAddressIsCpu,
    ],
    (shippingAddress, isCpu) => {
        const yupValidate = () => {
            const schema = isCpu
                ? helpers.validation.customerPickupSchema
                : helpers.validation.shippingAddressSchema;
            try {
                return helpers.validation.pvValidate(shippingAddress, schema, false, true);
            } catch (_valErrors) {
                return false;
            }
        };

        const schemaPassed = yupValidate();

        if (!schemaPassed) {
            return true; // if it didn't pass, disable the control
        }

        //if it passed yup validation, perform additional checks
        const { Valid, ValidOverride, Edited } = shippingAddress;
        const valid = Valid || false;
        const street = shippingAddress.Street || '';
        const validOverride = ValidOverride || false;
        const edited = Edited || false;
        if (street.length === 0) {
            return true;
        }
        if (edited && (valid || validOverride)) {
            return false;
        }
        return !valid;
    }
);

const selectContinueToPaymentDisabled = createSelector(
    [
        selectContinueToFreightDisabled,
        rdxSelections.selectors.selectShipToIsCpu,
        rdxShipping.selectors.selectWarehouseQuotes,
        rdxShipping.selectors.selectFreightSelections,
    ],
    (disableFreight, booIsCpu, freightQuotes, freightChoices) => {
        const quoteWarehouses = Object.keys(freightQuotes) || [];
        if (disableFreight || quoteWarehouses.length === 0) {
            return true;
        }
        if (booIsCpu) {
            return false;
        }

        const valid = quoteWarehouses.every((quoteWh) => {
            const whChoice = freightChoices[quoteWh] || {};
            const { freightType, fq } = whChoice;
            return freightType && fq && fq.FreightQuoteItemID;
        });
        return !valid;
    }
);

const selectContinueToReviewDisabled = createSelector(
    [
        selectContinueToPaymentDisabled,
        rdxSelections.selectors.selectPaymentSubslice,
        rdxSelections.selectors.selectShipToIsCpu,
    ],
    (continueToPaymentDisabled, paymentInfo, booIsCpu) => {
        if (
            continueToPaymentDisabled ||
            !paymentInfo ||
            paymentInfo.PaymentType === '' ||
            (paymentInfo.PaymentType === PVPaymentTypeEnum.Cash.toString() && !booIsCpu)
        ) {
            return true;
        }
        const schema = helpers.validation.getPaymentInfoSchema(paymentInfo);

        const yupValidate = () => {
            try {
                helpers.validation.pvValidate(paymentInfo, schema, false, true);
                return true;
            } catch (_valErrors) {
                return false;
            }
        };
        const isValid = yupValidate();
        return !isValid;
    }
);

const selectCheckoutStepsSlice = (state) => {
    return state.checkoutSteps || {};
};
const selectCheckoutPanels = createSelector(selectCheckoutStepsSlice, (subSlice) => {
    return subSlice.panels || [];
});
const selectPanelIdInput = (_state, panelId) => {
    return panelId;
};

const selectPanelById = createSelector(
    [selectCheckoutPanels, selectPanelIdInput],
    (panels, input) => {
        const foundPanel = panels.find((p) => p.id.toString() === input.toString());
        return foundPanel || {};
    }
);

const selectPanelIsLoading = createSelector(selectPanelById, (panel) => {
    return panel.showLoader || false;
});
const selectShowNotAllowed = createSelector(selectCheckoutStepsSlice, (subSlice) => {
    return subSlice.showAccountNotAllowed || false;
});

export {
    selectContinueToFreightDisabled,
    selectContinueToPaymentDisabled,
    selectContinueToReviewDisabled,
    selectPanelById,
    selectPanelIsLoading,
    selectShowNotAllowed,
};
