import { createSelector } from 'reselect';
import { selectItemsSlice } from '../items';

const selectProductsSlice = (state) => {
    return state.products || {};
};

const selectFeaturedMainSlice = createSelector(selectProductsSlice, (products) => {
    return products.featured || {};
});
const selectFeaturedInput = (_state, inputObj) => {
    return inputObj;
};
const selectFeaturedByType = createSelector(
    [selectFeaturedMainSlice, selectFeaturedInput],
    (featured, options) => {
        if (!options) {
            return {};
        }
        const { featuredType, itemId, prodDetail } = options;
        const typeObj = featured[featuredType] || {};
        if (itemId) {
            return typeObj[itemId] || {};
        }
        if (prodDetail) {
            return [prodDetail] || false;
        }
        return typeObj || {};
    }
);

const selectLoadCompleteForType = createSelector(selectFeaturedByType, (typeSlice) => {
    const isFetching = typeSlice.isFetching || false;
    const receivedAt = typeSlice.receivedAt || 0;
    return !isFetching && receivedAt > 0;
});
const selectIsLoadingForType = createSelector(selectFeaturedByType, (typeSlice) => {
    const isFetching = typeSlice.isFetching || false;
    return isFetching;
});

const selectFeaturedIdx = createSelector(selectFeaturedByType, (featured) => {
    return featured.list || [];
});
const selectFeaturedItems = createSelector([selectItemsSlice, selectFeaturedIdx], (items, idx) => {
    const featuredItems = idx.map((rankedItem) => {
        const { Rank, ItemID } = rankedItem;
        const item = items[ItemID];
        return { ...item, Rank };
    });
    return featuredItems;
});
//sort by rank then do special collate for react slick carousel 5 x 2 grid
const selectFeaturedItemsCollated = createSelector(selectFeaturedItems, (items) => {
    const positioned = items
        .sort((a, b) => a.Rank - b.Rank)
        .slice(0, 10)
        .map((item, idx) => {
            const collationPos = idx % 5;
            return { ...item, collationPos, idx };
        });
    const sorted = positioned.sort((a, b) => a.collationPos - b.collationPos);
    return sorted;
});
// Temp Start
const selectLoadCompleteForTypeObj = createSelector(selectItemsSlice, (obj) => {
    const { isFetching } = obj;
    const { receivedAt } = obj;
    return { isFetching, receivedAt };
});
// Temp End

const selectors = {
    selectFeaturedByType,
    selectFeaturedItems,
    selectFeaturedItemsCollated,
    selectIsLoadingForType,
    selectLoadCompleteForType,
    selectLoadCompleteForTypeObj,
};
export {
    selectFeaturedByType,
    selectIsLoadingForType,
    selectFeaturedItems,
    selectFeaturedItemsCollated,
    selectLoadCompleteForType,
    selectLoadCompleteForTypeObj,
};
export default selectors;
