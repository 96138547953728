import React from 'react';
import PropTypes from 'prop-types';
import { PVModalDialog } from '../../ContentContainers/PVModals';

function PVDealerIdChangedModal({ onRequestClose, response, isOpen }) {
    const message = (
        <div className="change-dealerid-warning-message">
            <div className="change-dealerid-warning-icon">
                <i className="fa fa-exclamation-triangle" />
            </div>
            <div className="change-dealerid-warning-text">
                Are you sure? Your current shopping cart is valid only for the current Dealer ID.
                Switching Dealer IDs will reset your cart according to the new Dealer ID chosen.
            </div>
        </div>
    );

    const footer = (
        <div className="ychange-dealerid-warning-buttons">
            <button type="button" onClick={() => response(true)} className="pv btn btn-primary">
                OK
            </button>
            <button type="button" onClick={onRequestClose} className="pv btn btn-primary">
                Cancel
            </button>
        </div>
    );

    return (
        <PVModalDialog
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            headerContent="Change Dealer Accounts?"
            bodyContent={message}
            footerContent={footer}
            className="modal-dialog"
            overlayClassName="modal-overlay"
            shouldCloseOnOverlayClick={false}
        />
    );
}

PVDealerIdChangedModal.propTypes = {
    onRequestClose: PropTypes.func.isRequired,
    response: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
};

export default PVDealerIdChangedModal;
