import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { selectRemainingAccToFreeShipping } from '../../redux/cart/selectors';
import { getPriceString } from '../../helpers/textFunctions';

const PVFreeShipping = (props) => {
    const { remainingAccToFreeShipping } = props;

    return (
        <Link to="/Shop/FreeShippingDetails" alt="Free Shipping Details">
            <div className="transportation">
                {remainingAccToFreeShipping !== null && remainingAccToFreeShipping === 0 && (
                   <span className="text-success"> </span> 
                )}
                {remainingAccToFreeShipping !== null && remainingAccToFreeShipping !== 0 && (
                    <span>
                        {/* <Link to="/FreeShippingDetails" alt="Free Shipping Details"> */}
                           {/*Buy {getPriceString(remainingAccToFreeShipping)} more for free shipping*/}
                        {/* </Link> */}
                    </span>
                )}
            </div>
        </Link>
    );
};

PVFreeShipping.propTypes = {
    remainingAccToFreeShipping: PropTypes.number,
};

PVFreeShipping.defaultProps = {
    remainingAccToFreeShipping: null,
};

const mapStateToProps = (state) => {
    return {
        remainingAccToFreeShipping: selectRemainingAccToFreeShipping(state),
    };
};

export default connect(mapStateToProps, null)(PVFreeShipping);
