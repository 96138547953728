import React from 'react';
import { Link } from 'react-router-dom';
//import PropTypes from 'prop-types';

const PVLogo = () /*props*/ => {
    //const { } = props;

    return (
        <strong className="logo">
            <Link to="/">
                <img
                    src="/WebSupport/PV_React/GlobalHeader/PerfectVision-logo-horizontal-color.png"
                    alt="logo"
                />
            </Link>
        </strong>
    );
};

//PVLogo.propTypes = {};

//PVLogo.defaultProps = {};

export default PVLogo;
