const USAStates = [
    { Abbr: 'AK', Name: 'Alaska', DistributorID: 2, Lower48State: false },
    { Abbr: 'AL', Name: 'Alabama', DistributorID: 1, Lower48State: true },
    { Abbr: 'AR', Name: 'Arkansas', DistributorID: 1, Lower48State: true },
    { Abbr: 'AZ', Name: 'Arizona', DistributorID: 2, Lower48State: true },
    { Abbr: 'CA', Name: 'California', DistributorID: 2, Lower48State: true },
    { Abbr: 'CO', Name: 'Colorado', DistributorID: 2, Lower48State: true },
    { Abbr: 'CT', Name: 'Connecticut', DistributorID: 1, Lower48State: true },
    { Abbr: 'DC', Name: 'District of Columbia', DistributorID: 2, Lower48State: true },
    { Abbr: 'DE', Name: 'Delaware', DistributorID: 1, Lower48State: true },
    { Abbr: 'FL', Name: 'Florida', DistributorID: 1, Lower48State: true },
    { Abbr: 'GA', Name: 'Georgia', DistributorID: 1, Lower48State: true },
    { Abbr: 'HI', Name: 'Hawaii', DistributorID: 2, Lower48State: false },
    { Abbr: 'IA', Name: 'Iowa', DistributorID: 2, Lower48State: true },
    { Abbr: 'ID', Name: 'Idaho', DistributorID: 2, Lower48State: true },
    { Abbr: 'IL', Name: 'Illinois', DistributorID: 2, Lower48State: true },
    { Abbr: 'IN', Name: 'Indiana', DistributorID: 1, Lower48State: true },
    { Abbr: 'KS', Name: 'Kansas', DistributorID: 2, Lower48State: true },
    { Abbr: 'KY', Name: 'Kentucky', DistributorID: 1, Lower48State: true },
    { Abbr: 'LA', Name: 'Louisiana', DistributorID: 1, Lower48State: true },
    { Abbr: 'MA', Name: 'Massachusetts', DistributorID: 1, Lower48State: true },
    { Abbr: 'MD', Name: 'Maryland', DistributorID: 1, Lower48State: true },
    { Abbr: 'ME', Name: 'Maine', DistributorID: 1, Lower48State: true },
    { Abbr: 'MI', Name: 'Michigan', DistributorID: 2, Lower48State: true },
    { Abbr: 'MN', Name: 'Minnesota', DistributorID: 2, Lower48State: true },
    { Abbr: 'MO', Name: 'Missouri', DistributorID: 1, Lower48State: true },
    { Abbr: 'MS', Name: 'Mississippi', DistributorID: 1, Lower48State: true },
    { Abbr: 'MT', Name: 'Montana', DistributorID: 2, Lower48State: true },
    { Abbr: 'NC', Name: 'North Carolina', DistributorID: 1, Lower48State: true },
    { Abbr: 'ND', Name: 'North Dakota', DistributorID: 2, Lower48State: true },
    { Abbr: 'NE', Name: 'Nebraska', DistributorID: 2, Lower48State: true },
    { Abbr: 'NH', Name: 'New Hampshire', DistributorID: 1, Lower48State: true },
    { Abbr: 'NJ', Name: 'New Jersey', DistributorID: 1, Lower48State: true },
    { Abbr: 'NM', Name: 'New Mexico', DistributorID: 2, Lower48State: true },
    { Abbr: 'NV', Name: 'Nevada', DistributorID: 2, Lower48State: true },
    { Abbr: 'NY', Name: 'New York', DistributorID: 1, Lower48State: true },
    { Abbr: 'OH', Name: 'Ohio', DistributorID: 1, Lower48State: true },
    { Abbr: 'OK', Name: 'Oklahoma', DistributorID: 2, Lower48State: true },
    { Abbr: 'OR', Name: 'Oregon', DistributorID: 2, Lower48State: true },
    { Abbr: 'PA', Name: 'Pennsylvania', DistributorID: 1, Lower48State: true },
    { Abbr: 'PR', Name: 'Puerto Rico', DistributorID: 1, Lower48State: false },
    { Abbr: 'RI', Name: 'Rhode Island', DistributorID: 1, Lower48State: true },
    { Abbr: 'SC', Name: 'South Carolina', DistributorID: 1, Lower48State: true },
    { Abbr: 'SD', Name: 'South Dakota', DistributorID: 2, Lower48State: true },
    { Abbr: 'TN', Name: 'Tennessee', DistributorID: 1, Lower48State: true },
    { Abbr: 'TX', Name: 'Texas', DistributorID: 2, Lower48State: true },
    { Abbr: 'UT', Name: 'Utah', DistributorID: 2, Lower48State: true },
    { Abbr: 'VA', Name: 'Virginia', DistributorID: 1, Lower48State: true },
    { Abbr: 'VT', Name: 'Vermont', DistributorID: 1, Lower48State: true },
    { Abbr: 'WA', Name: 'Washington', DistributorID: 2, Lower48State: true },
    { Abbr: 'WI', Name: 'Wisconsin', DistributorID: 2, Lower48State: true },
    { Abbr: 'WV', Name: 'West Virginia', DistributorID: 1, Lower48State: true },
    { Abbr: 'WY', Name: 'Wyoming', DistributorID: 2, Lower48State: true },
];

export default USAStates;
