import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import delve from 'dlv';
import { connect } from 'react-redux';
import { changeInvoiceAccount } from '../../../../redux/userProfile/actionsAsync';
import { selectInvoiceAccount } from '../../../../redux/userProfile/selectors';

const PVCustId = ({ InvoiceAccount, apiChangeCustId, setLoading }) => {
    const inputRef = useRef();

    const handleChangeCustId = async (custId) => {
        setLoading(true);
        await apiChangeCustId(custId)
            .then(() => {
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
                // eslint-disable-next-line no-alert
                alert('Invalid CustID. Please enter a valid CustID');
            });
    };

    return (
        <React.Fragment>
            <button
                type="button"
                onClick={() => handleChangeCustId(inputRef.current.value)}
                className="pv btn btn-link pv-header-cust-id-button"
            >
                CustID:&nbsp;
            </button>

            <input
                id="custId"
                type="text"
                className="pv input-sm pv-header-cust-id-input"
                ref={inputRef}
                onKeyDown={(e) => {
                    //    enterKey = 13, tabKey = 9,upArr=38,downArr=40
                    const allowedKeys = ['13', '38', '40'];
                    const custId = e.target.value;
                    const key = delve(e, 'which', '').toString();
                    if (allowedKeys.indexOf(key) < 0) {
                        return;
                    }
                    e.preventDefault();
                    if ((custId || '').trim() !== InvoiceAccount) {
                        handleChangeCustId(e.target.value);
                    }
                }}
                defaultValue={InvoiceAccount}
            />
        </React.Fragment>
    );
};

PVCustId.propTypes = {
    InvoiceAccount: PropTypes.string.isRequired,
    apiChangeCustId: PropTypes.func.isRequired,
    setLoading: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => {
    return {
        InvoiceAccount: selectInvoiceAccount(state),
    };
};
function mapDispatchToProps(dispatch) {
    return {
        apiChangeCustId: (newCustId) => dispatch(changeInvoiceAccount(newCustId)),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(PVCustId);
