import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';
import PropTypes from 'prop-types';

import { getCart } from '../../../../../redux/cart/actionsAsync';
import PVCartHeaderDisplay from './PVCartHeaderDisplay';
import PVCartFlyout from './PVCartFlyout';

const PVMiniCart = ({ refreshCart }) => {
    /* 2019-09-26 nitad 4044 issue 73 -- we receive a cart object back from many api calls, and since this was written, 
    we've restructured our site composition so that it has to reload more often than expected.
     Adding a delay of 6minutes. It should hardly ever make the call from here - basically when navigating into the react site*/
    const location = useLocation();

    useEffect(() => {
        if (location.pathname.indexOf('checkout') < 0 || location.pathname.indexOf('cart') < 0) {
            const sixMinutes = 1000 * 60 * 60;
            refreshCart(sixMinutes);
        }
    }, [location]);

    return (
        <React.Fragment>
            <div className="block-minicart dropdown">
                <PVCartHeaderDisplay />
                <PVCartFlyout />
            </div>

            <a href="/" className="hidden-md search-hidden">
                <span className="pe-7s-search" />
            </a>
        </React.Fragment>
    );
};

PVMiniCart.propTypes = {
    refreshCart: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
    return {
        refreshCart: (delayMs) => dispatch(getCart(delayMs)),
    };
}

export default connect(null, mapDispatchToProps)(PVMiniCart);
