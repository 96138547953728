import { createSelector } from 'reselect';
import { freightTypes } from './api';
import { selectShipToIsReady } from '../checkout-selections/selectors';
import { selectWarehouseGroundEligible, selectWarehouseLtlEligible } from '../cart/selectors';

const isReady = (freightTypeObj) => {
    const obj = freightTypeObj || {};
    const received = obj.receivedAt || 0;
    return obj.isFetching === false && received > 0;
};
const selectFreightTypeInput = (_state, freightType) => {
    return freightType;
};

const selectShippingSlice = (state) => {
    return state.checkoutShipping || {};
};
const selectFreightQuotesByType = createSelector(
    [selectShippingSlice, selectFreightTypeInput],
    (slice, ft) => {
        return slice[ft] || {};
    }
);

const selectLtlFreight = createSelector(selectShippingSlice, (slice) => {
    return slice[freightTypes.LTL] || {};
});
const selectExpeditedFreight = createSelector(selectShippingSlice, (slice) => {
    return slice[freightTypes.EXPEDITED] || {};
});

const selectStandardFreight = createSelector(selectShippingSlice, (slice) => {
    return slice[freightTypes.STANDARD] || {};
});

const selectWarehouseQuotes = createSelector(selectShippingSlice, (slice) => {
    return slice.warehouseQuotes || {};
});
const selectShippingWarehouses = createSelector(selectWarehouseQuotes, (whQuotes) => {
    return Object.keys(whQuotes) || [];
});

const selectStandardIsReady = createSelector(
    [selectStandardFreight, selectShipToIsReady, selectWarehouseGroundEligible],
    (standard, shipToReady, booGround) => {
        const quotesLoaded = isReady(standard);
        const result = shipToReady && (!booGround || quotesLoaded);
        return result;
    }
);
const selectExpeditedIsReady = createSelector(
    [selectExpeditedFreight, selectShipToIsReady, selectWarehouseGroundEligible],
    (expedited, shipToReady, booGround) => {
        const quotesLoaded = isReady(expedited);
        const result = shipToReady && (!booGround || quotesLoaded);
        return result;
    }
);

const selectLtlIsReady = createSelector(
    [selectLtlFreight, selectShipToIsReady, selectWarehouseLtlEligible],
    (ltl, shipToReady, booLtl) => {
        return shipToReady && (!booLtl || isReady(ltl));
    }
);
const selectAnyFreightReady = createSelector(
    [selectLtlFreight, selectExpeditedFreight, selectStandardFreight],
    (ltl, expedited, standard) => {
        return isReady(standard) || isReady(expedited) || isReady(ltl);
    }
);

const selectFreightSelections = createSelector(selectShippingSlice, (slice) => {
    return slice.selection || {};
});
const selectWarehouseIdInput = (_state, whId) => {
    return whId;
};
const selectWarehouseFreightSelection = createSelector(
    [selectFreightSelections, selectWarehouseIdInput],
    (selection, id) => {
        return selection[id] || undefined;
    }
);

const selectors = {
    selectShippingSlice,
    selectAnyFreightReady,
    selectShippingWarehouses,
    selectStandardIsReady,
    selectExpeditedIsReady,
    selectLtlIsReady,
    selectWarehouseFreightSelection,
    selectFreightSelections,
    selectWarehouseQuotes,
    selectFreightQuotesByType,
};
export default selectors;
export {
    selectShippingSlice,
    selectAnyFreightReady,
    selectShippingWarehouses,
    selectStandardIsReady,
    selectExpeditedIsReady,
    selectLtlIsReady,
    selectWarehouseFreightSelection,
    selectFreightSelections,
    selectWarehouseQuotes,
    selectFreightQuotesByType,
};
