import { createSlice } from '@reduxjs/toolkit';
import { propsByActionType, APIACTIONS } from '../../../helpers/apiShared';

const itemsSlice = createSlice({
    name: 'items',
    initialState: {},
    reducers: {
        addStoreItems: (state, action) => {
            const { items } = action.payload;
            return { ...state, ...items };
        },
        getItemSent: (state, action) => {
            const fetchProps = propsByActionType(APIACTIONS.REQUEST);
            const { itemId } = action.payload;
            const existingItem = state[itemId] || {};
            const updatedItem = { ...existingItem, ...fetchProps };
            return { ...state, [itemId]: { ...updatedItem } };
        },
        getItemSuccess: (state, action) => {
            const fetchProps = propsByActionType(APIACTIONS.SUCCESS);
            const { itemId, body } = action.payload;
            const existingItem = state[itemId] || {};
            const bodyPrice = (body || {}).Price;
            let Price = bodyPrice;
            if (
                body.PricingDetails &&
                Array.isArray(body.PricingDetails) &&
                body.PricingDetails.length > 0
            ) {
                Price = body.PricingDetails[0].Price;
            }
            const updatedItem = { ...existingItem, ...body, Price, ...fetchProps };
            return { ...state, [itemId]: { ...updatedItem } };
        },
        getItemFailure: (state, action) => {
            const fetchProps = propsByActionType(APIACTIONS.FAILURE);
            const { itemId, error } = action.payload;
            const existingItem = state[itemId] || {};
            const updatedItem = { ...existingItem, error, ...fetchProps };
            return { ...state, [itemId]: { ...updatedItem } };
        },
        getItemIdsListSent: (state, action) => {
            const fetchProps = propsByActionType(APIACTIONS.REQUEST);

            return { ...state, ids: { ...fetchProps, ...action.payload } };
        },
        getItemIdsListSuccess: (state, action) => {
            const fetchProps = propsByActionType(APIACTIONS.SUCCESS);
            const { list } = action.payload;
            const ids = { ...fetchProps, list };
            return { ...state, ids };
        },
        getItemIdsListFailure: (state, _action) => {
            const fetchProps = propsByActionType(APIACTIONS.FAILURE);
            const { list } = state.ids;
            const ids = { ...fetchProps, list };
            return { ...state, ids };
        },
        invalidateItems: (state, _action) => {
            const { ids } = state;
            return { ids };
        },
    },
});

export const { actions, reducer } = itemsSlice;
export const { addStoreItems, invalidateItems } = actions;
export default reducer;
