import { createSelector } from 'reselect';

const getMenuSlice = (state) => {
    return state.menus || {};
};
const getTopMenus = createSelector(getMenuSlice, (menuSlice) => {
    const { topMenus } = menuSlice;
    return topMenus || [];
});

const selectMenuCategoryInput = (_state, categoryName) => {
    return categoryName;
};
const selectShopMenus = createSelector(getTopMenus, (topMenus) => {
    const menus = topMenus[2] || {};
    return menus.levelMenus || [];
});

const selectShopCategoryMenu = createSelector(
    [selectMenuCategoryInput, selectShopMenus],
    (menuName, shopMenus) => {
        const subCategory =
            shopMenus.find((menu) => {
                return menu.menuName === menuName;
            }) || {};
        const subMenus = subCategory.menuItems || [];
        const filteredSubMenus =
            subMenus.filter((item) => {
                const href = item.itemHref || '';
                return href.indexOf('#') < 0 && href !== '';
            }) || [];
        return filteredSubMenus.map((s) => {
            return { id: s.itemID, label: s.itemName, link: s.itemHref, reactLink: true };
        });
    }
);

const selectPagePathInput = (_state, relativePathInput) => {
    return relativePathInput;
};

const selectPageAccess = createSelector(
    [getMenuSlice, selectPagePathInput],
    (menuSlice, pagePath) => {
        const canAccess = menuSlice.topMenus.some((menuTier) =>
            menuTier.levelMenus.some((level) =>
                level.menuItems.find((item) => {
                    if (item.itemHref && item.itemHref.toLowerCase() === pagePath.toLowerCase()) {
                        return true;
                    }
                    return false;
                })
            )
        );
        return canAccess;
    }
);

export { selectShopCategoryMenu, getTopMenus, selectPageAccess };

export default getTopMenus;
