const prepValidationData = (values) => {
    return Object.keys(values).reduce((obj, key) => {
        const rawVal = values[key] || '';
        const value = rawVal.toString().trim() !== '' ? values[key] : undefined;
        return { ...obj, [key]: value };
    }, {});
};
const getOpts = (abortEarly = false, context = {}) => {
    return { abortEarly, context };
};
const pvValidate = (values, schema, abortEarly = false, sync = false, context = {}) => {
    const validateData = prepValidationData(values);
    const opts = getOpts(abortEarly, context);
    if (sync) {
        return schema.validateSync(validateData);
    }
    return schema.validate(validateData, opts);
};
const pvIsValid = (values, schema, sync) => {
    const validationData = prepValidationData(values);
    if (sync) {
        return schema.isValidSync(validationData);
    }
    return schema.isValid(validationData);
};

/*emulates the Formik validation -- for string fields that are empty and have a required error, it stops with that error and doesn't give length, etc validations*/
export default pvValidate;

export { pvValidate, pvIsValid };
