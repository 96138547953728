import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import delve from 'dlv';
import { shouldFetchForSlice } from '../../../helpers/apiShared';
import { refreshProfile } from '../../../redux/userProfile/actionsAsync';
import { getCartRealTimeAvailability } from '../../../redux/cart/actionsAsync';
import { selectUserProfile } from '../../../redux/userProfile/selectors';
import { parseAlerts, addAlertObjects } from '../../../redux/alerts';
import { actions as stepActions } from '../../../redux/checkout-steps/checkoutStepsSlice';
import { actions as utils } from '../../../redux/siteUtils/siteUtilsSlice';

const PVCheckoutButton = ({
    apiCheckItemAvailability,
    apiUpdateProfile,
    userProfile,
    rdxSetPageLoading,
    rdxSetShowStoppedModal,
}) => {
    const history = useHistory();
    const isMounted = useRef();

    useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
        };
    }, []);

    const conditionallyInitiateRefresh = () => {
        //both the onFocus and the onMouseOver events can fire.
        //A click is a focus,so we want to be forgiving with respect to touch, but also not double dip.
        const booGetProfile = shouldFetchForSlice(userProfile, 2000);
        if (booGetProfile) {
            apiUpdateProfile('cart', 250);
        }
    };

    const handleCheckoutClick = () => {
        rdxSetPageLoading(true);
        apiUpdateProfile('cart', 0)
            .then((result) => {
                const stopped = delve(result, 'payload.IsStopWebOrders', true);
                if (stopped) {
                    rdxSetShowStoppedModal(true);
                    rdxSetPageLoading(false);
                    return Promise.resolve({ stopped });
                }
                return apiCheckItemAvailability();
            })
            .then((result) => {
                const { stopped } = result;
                if (stopped) {
                    return Promise.resolve();
                }
                const hasChangedItems =
                    delve(result, 'payload.QuantityChangedItems', []).length > 0;
                const hasRemovedItems = delve(result, 'payload.RemovedItems', []).length > 0;
                if (hasChangedItems || hasRemovedItems) {
                    rdxSetPageLoading(false);
                } else {
                    rdxSetPageLoading(false);
                    history.push('/Shop/Checkout');
                }
                return Promise.resolve();
            })
            .catch((e) => {
                const uiAlerts = parseAlerts(e);
                addAlertObjects(uiAlerts);
                rdxSetPageLoading(false);
            });
    };
    return (
        <React.Fragment>
            <button
                type="submit"
                className="pv btn btn-primary btn-checkout"
                onMouseOver={() => conditionallyInitiateRefresh}
                onFocus={() => conditionallyInitiateRefresh()}
                onClick={() => handleCheckoutClick()}
            >
                Check Out
            </button>
        </React.Fragment>
    );
};

PVCheckoutButton.propTypes = {
    apiUpdateProfile: PropTypes.func.isRequired,
    apiCheckItemAvailability: PropTypes.func.isRequired,
    rdxSetPageLoading: PropTypes.func.isRequired,
    rdxSetShowStoppedModal: PropTypes.func.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    userProfile: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
        userProfile: selectUserProfile(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        apiUpdateProfile: () => dispatch(refreshProfile('checkoutButton', 0)),
        apiCheckItemAvailability: () => dispatch(getCartRealTimeAvailability()),
        rdxSetPageLoading: (val) => dispatch(utils.setPageLoading({ pageLoading: val })),
        rdxSetShowStoppedModal: (booVal) =>
            dispatch(stepActions.setAccountNotAllowed({ showAccountNotAllowed: booVal })),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PVCheckoutButton);
