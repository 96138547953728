import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { selectPageLoading } from '../../../../redux/siteUtils/selectors';
import PVBaseModal from '../../ContentContainers/PVModals/PVBaseModal';

const PVLoadingModalBlockSite = ({ booPageLoading }) => (
    <React.Fragment>
        {booPageLoading && (
            <PVBaseModal isOpen overlayClassName="modal-overlay darker" className="modal-loading">
                <div className="pv-spinner checkout-loading" />
            </PVBaseModal>
        )}
    </React.Fragment>
);
PVLoadingModalBlockSite.propTypes = {
    booPageLoading: PropTypes.bool.isRequired,
};
const mapDispatchToProps = (state) => {
    return {
        booPageLoading: selectPageLoading(state),
    };
};
export default connect(mapDispatchToProps)(PVLoadingModalBlockSite);
