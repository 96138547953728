import React, { useEffect, useState ,useCallback } from "react";
import cmsApi from "../../../helpers/cmsApi";
import { Link } from 'react-router-dom';

function PVIndustryLinkBar() {
  const [mainGlobalContent, setMainGlobalContent] = useState("");
 
  useEffect(() => {
    const fetchdata = async () => {
      const result = await cmsApi.get("newlandingpage");
      setMainGlobalContent(result.data.items[0].data.mainGlobalContent.iv);    
    };
    fetchdata();
  }, []);
  console.log("mainGlobalContent", mainGlobalContent);
 
  const display = Object.keys(mainGlobalContent).map((i) => {
    return (
      <div className="pv-IndustryLinkSub-col-lg-3 col-md-3 col-sm-6 col-xs-12" key={i}>
                <div className="pv-IndustryLink">
                 <div className=" home-page-text-image">
                    <img src={mainGlobalContent[i].imglocation}   />
                 </div>
                    <div className="home-page-text-header">
                      {mainGlobalContent[i].headerText}
                    </div>
                    <div className="home-page-text-Subheader">
                      {mainGlobalContent[i].subHeaderText}
                    </div>
                  <div className="home-page-text-Subheaderlists" >
                    <ul>
                       <li>{mainGlobalContent[i].linkText1}</li>
                       <li>{mainGlobalContent[i].linkText2}</li>
                       <li>{mainGlobalContent[i].linkText3 }</li>
                       {!!mainGlobalContent[i].linkText4 && <li>{mainGlobalContent[i].linkText4}</li>}
                    </ul>
                </div>
                    
                    <div className="pv-new-page-button">    
                    <a href={mainGlobalContent[i].ButtonPageLink} target="_self">
                            <button className="pv btn btn-primary pv-new-page-button-style" type="button">
                                {mainGlobalContent[i].ButtonText}
                            </button>
                        </a>              
                   </div>  
               
                </div>
               
        </div>
    );
  });

 
  return (
  <React.Fragment>
    <div>
      <div className="row pv-industry-link-bar">{display}</div>
    </div>
  </React.Fragment>
  );
}
export default PVIndustryLinkBar;
