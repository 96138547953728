import { createSlice } from '@reduxjs/toolkit';
import produce from 'immer';
import * as cloneDeep from 'clone-deep';

import { propsByActionType, APIACTIONS } from '../../../helpers/apiShared';

const getPosition = (pageSize, pageId) => {
    return pageSize * pageId;
};

const getNewState = (fetchProps, action, state) => {
    const {
        ID,
        Name,
        ParentName,
        WebDescription,
        BannerImage,
        TotalProducts,
        categoryName,
        subcategory,
        list,
        numProducts,
        page,
        error,
    } = action.payload;
    const payload = {
        categoryName,
        subcategory,
        Name,
        ParentName,
        WebDescription,
        ID,
        BannerImage,
        TotalProducts,
        ...fetchProps,
        error,
    };

    const newState = produce(state, (draftInitial) => {
        const draft = cloneDeep(draftInitial);
        draft[categoryName] = draft[categoryName] || {};
        const subcat = draft[categoryName][subcategory] || {};
        draft[categoryName][subcategory] = { ...subcat, ...payload };
        draft[categoryName][subcategory].list = draft[categoryName][subcategory].list || [];
        draft[categoryName][subcategory].list.splice(
            getPosition(numProducts, page),
            numProducts,
            ...list
        );
        draft.invalid = false;
        return draft;
    });

    return newState;
};

const categorySlice = createSlice({
    name: 'category',
    initialState: {},
    reducers: {
        setCategories: (_state, action) => {
            const { payload } = action;
            return { ...payload };
        },
        getSubcategoryProductsSent: (state, action) => {
            const fetchProps = propsByActionType(APIACTIONS.REQUEST);
            const { categoryName, subcategory } = action.payload;
            const newState = produce(state, (draftInitial) => {
                const draft = cloneDeep(draftInitial);
                draft[categoryName] = draft[categoryName] || {};
                const subcat = draft[categoryName][subcategory] || {};
                draft[categoryName][subcategory] = {
                    ...subcat,
                    ...fetchProps,
                    categoryName,
                    subcategory,
                };
                draft.invalid = false;

                return draft;
            });

            return newState;
        },
        initSubcategoryArray: (state, action) => {
            const { categoryName, subcategory, TotalProducts } = action.payload;
            const arr = Array(TotalProducts)
                .fill()
                .map((_x) => '');
            const newState = produce(state, (draftInitial) => {
                const draft = cloneDeep(draftInitial);
                const subcat = { [subcategory]: { list: arr } };
                const cat = draft[categoryName] || {};
                draft[categoryName] = { ...cat, ...subcat, categoryName, subcategory };
                draft.invalid = false;
            });

            return newState;
        },
        getSubcategoryProductsSuccess: (state, action) => {
            const fetchProps = propsByActionType(APIACTIONS.SUCCESS);
            const newState = getNewState(fetchProps, action, state);

            return newState;
        },
        getSubcategoryProductsFailure: (state, action) => {
            const fetchProps = propsByActionType(APIACTIONS.FAILURE);
            const newState = getNewState(fetchProps, action, state);

            return newState;
        },
        invalidateSubcategoryProducts: (state, _action) => {
            const newState = produce(state, (initialDraft) => {
                const draft1 = cloneDeep(initialDraft);
                const draft = Object.keys(draft1).reduce((obj, key) => {
                    const category = draft1[key];
                    const { categoryName, Name, DisplayName, Subcategories } = category;

                    const subCatObjects = Subcategories
                        ? Subcategories.reduce((newObj, sub) => {
                              return {
                                  ...newObj,
                                  [sub.Name]: {
                                      invalid: true,
                                      parent: Name,
                                      ParentName: DisplayName,
                                      Name: sub.DisplayName,
                                  },
                              };
                          }, {})
                        : {};

                    return {
                        ...obj,
                        [categoryName]: {
                            categoryName,
                            Name,
                            DisplayName,
                            Subcategories,
                            invalid: true,
                            ...subCatObjects,
                        },
                        invalid: true,
                    };
                }, {});
                return draft;
            });
            return newState;
        },
        getCategorySubcategoriesSent: (state, action) => {
            const fetchProps = propsByActionType(APIACTIONS.REQUEST);
            const { categoryName } = action.payload;
            const newState = produce(state, (draftInitial) => {
                const draft = cloneDeep(draftInitial);
                draft[categoryName] = { ...(draft[categoryName] || {}), ...fetchProps };
                draft.invalid = false;
                return draft;
            });

            return newState;
        },
        getCategorySubcategoriesSuccess: (state, action) => {
            const fetchProps = propsByActionType(APIACTIONS.SUCCESS);
            const newState = produce(state, (draftInitial) => {
                const draft = cloneDeep(draftInitial);
                const { payload } = action;
                const { categoryName } = payload;
                draft[categoryName] = { ...(draft[categoryName] || {}), ...fetchProps, ...payload };
                draft.invalid = false;
                return draft;
            });
            return newState;
        },
        getCategorySubcategoriesFailure: (state, action) => {
            const fetchProps = propsByActionType(APIACTIONS.FAILURE);
            const newState = produce(state, (draftInitial) => {
                const draft = cloneDeep(draftInitial);
                const { categoryName, error } = action.payload;
                draft[categoryName] = { ...(draft[categoryName] || {}), ...fetchProps, error };
                draft.invalid = false;
                return draft;
            });

            return newState;
        },
    },
});

export const { actions, reducer } = categorySlice;
export default reducer;
