import { combineReducers } from 'redux';
import { reducer as featured } from './featured';
import { reducer as items } from './items';
import { reducer as category } from './category';

export const reducer = combineReducers({
    items,
    featured,
    category,
});

export default reducer;
