import React, { useState, useEffect } from 'react';
import PVModalDialog from '../../ContentContainers/PVModals/PVModalDialog';

const PVBrowserModal = () => {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf('MSIE ');
    const trident = ua.indexOf('Trident/');
    const isIE = msie >= 0 || trident >= 0;
    const [showBrowserModal, setBrowserModal] = useState(false);

    function setCookie() {
        setBrowserModal(false);
        const date = new Date();
        date.setTime(date.getTime() + 24 * 60 * 60 * 1000);
        const expires = `; expires=${date.toGMTString()}`;
        const value = date.getTime();
        document.cookie = `browserFlag=${value}${expires}; path=/`;
    }

    const header = (
        <div className="modal-browser-support-logo">
            <img alt="PerfectVision" src="/WebSupport/PV_React/GlobalHeader/pv-logo-round.png" />
        </div>
    );
    const closeBtn = (
        <button type="button" onClick={() => setCookie()} className="pv btn btn-primary">
            X
        </button>
    );

    function getCookie(cname) {
        const name = `${cname}=`;
        const decodedCookie = decodeURIComponent(document.cookie);
        const ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i += 1) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return false;
            }
        }
        return true;
    }

    useEffect(() => {
        setBrowserModal(getCookie('browserFlag') && isIE);
    }, [showBrowserModal]);

    const body = (
        <div className="modal-browser-support-body">
            {header}
            {closeBtn}
            <strong>Please upgrade your browser to use perfect-vision.com</strong>
            <p>
                We built perfect-vision.com using the latest techniques and technologies. This makes
                perfect-vision.com faster and easier to use. Unfortunately, your browser
                doesn&apos;t support those technologies.
            </p>
            <p>Download one of these great browsers and you&apos;ll be on your way: </p>
            <div className="row">
                <div className="col-lg-4">
                    <a href="https://www.google.com/chrome/">
                        <img alt="chrome" src="/WebSupport/PV_React/GlobalHeader/chrome-logo.png" />
                        <p>Google Chrome</p>
                    </a>
                </div>
                <div className="col-lg-4">
                    <a href="https://www.microsoft.com/en-us/windows/microsoft-edge">
                        <img alt="edge" src="/WebSupport/PV_React/GlobalHeader/edge-logo.png" />
                        <p>Microsoft Edge</p>
                    </a>
                </div>
                <div className="col-lg-4">
                    <a href="https://www.mozilla.org/en-US/firefox/new/">
                        <img
                            alt="firefox"
                            src="/WebSupport/PV_React/GlobalHeader/firefox-logo.png"
                        />
                        <p>Mozilla Firefox</p>
                    </a>
                </div>
            </div>
        </div>
    );
    return (
        <React.Fragment>
            {showBrowserModal && (
                <PVModalDialog
                    className="modal-dialog modal-browser-support"
                    overlayClassName="modal-overlay"
                    bodyContent={body}
                    isOpen
                />
            )}
        </React.Fragment>
    );
};

export default PVBrowserModal;
