import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import PVLinkButton from './PVLinkButton';
import data from './errorContent';

const PVErrorPage = () => {
    const { code } = useParams();
    const history = useHistory();
    if (!code) {
        history.replace('/Error/404');
    }

    const content = data[code] || data['404'];
    const {
        showLogo,
        bgClass,
        headlineTop,
        paragraphs,
        headlineBottom,
        linkButton,
        components,
    } = content;
    return (
        <div className="pv-page-error">
            <div className="container">
                <div className="row">
                    <div className={`col-sm-5 hidden-xs ${bgClass}`} />
                    <div className="col-xs-12 col-sm-7">
                        {showLogo && (
                            <div className="pv-logo">
                                <img
                                    src="/WebSupport/PV_React/GlobalHeader/PerfectVision-logo-horizontal-color.png"
                                    alt="PerfectVision Logo"
                                />
                            </div>
                        )}
                        <div className="error-page-text">
                            <h2>{headlineTop}</h2>
                            {paragraphs && paragraphs.map((p) => <p key={p.key}>{p.text}</p>)}
                            {components &&
                                components.map((c) => (
                                    <React.Fragment key={0}>{c.component}</React.Fragment>
                                ))}
                            {headlineBottom && <h2>{headlineBottom}</h2>}
                            {linkButton && <PVLinkButton {...linkButton} />}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PVErrorPage;
