import axios from 'axios';
import { CMSBearerToken } from './siteConfigConstants';


const cmsControllerUri = 'https://cms.perfect-vision.com/api/content/perfect-vision/';

//export async function fetchCMSData(endpoint) {
//    const fetchfetchCMSDataUri = `${cmsControllerUri}${endpoint}`;
//    return await fetch(fetchfetchCMSDataUri, {
//        headers: new Headers({
//            'Authorization': CMSBearerToken,
//            'Content-Type': 'application/x-www-form-urlencoded'
//        }),
//        method: 'GET',
//        cache: 'no-cache',
//    }).then((response) => response.json())
//        .then((responseJson) => {
//            console.log(responseJson) 
//            return responseJson;
//        })
//        .catch((error) => {
//            console.error(error);
//        });
//};


export default axios.create({
    baseURL: cmsControllerUri,
    headers: {
        Authorization: CMSBearerToken
    }
});
