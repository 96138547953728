import { createSlice } from '@reduxjs/toolkit';

import { propsByActionType, APIACTIONS } from '../../helpers/apiShared';

const orderedSlice = createSlice({
    name: 'ordered',
    initialState: {},
    reducers: {
        getOrderSent: (state, _action) => {
            const fetchProps = propsByActionType(APIACTIONS.REQUEST);
            return { ...state, ...fetchProps, Order: [] };
        },
        getOrderSuccess: (state, action) => {
            const fetchProps = propsByActionType(APIACTIONS.SUCCESS);
            const { list } = action.payload;
            return { ...state, ...fetchProps, list };
        },
        getOrderFailure: (state, action) => {
            const fetchProps = propsByActionType(APIACTIONS.FAILURE);
            const { error } = action.payload;
            return { ...state, ...fetchProps, error };
        },
        invalidateOrders: (_state, _action) => {
            return {};
        },
    },
});

export const { actions, reducer } = orderedSlice;
export default reducer;
