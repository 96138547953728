import { createSelector } from 'reselect';
import delve from 'dlv';

const selectUserProfile = (state) => {
    return state.userProfile || {};
};

const selectInvoiceAccount = createSelector(selectUserProfile, (profile) => {
    return profile.InvoiceAccount || '';
});
const selectIsStopWebOrders = createSelector(selectUserProfile, (profile) => {
    return profile.IsStopWebOrders || false;
});
const selectActiveDealerIdForAccount = createSelector(selectUserProfile, (profile) => {
    return (profile.DealerId || '').toString();
});

const selectActiveDealerKey = createSelector(selectUserProfile, (profile) => {
    const key = delve(profile, 'ActiveDealerKey', 0);
    return key.toString();
});

const selectDealerListData = createSelector(selectUserProfile, (profile) => {
    return profile.DealerList || [];
});

const selectKey = (_state, key) => {
    return key;
};
const selectDealerInfoByKey = createSelector([selectDealerListData, selectKey], (list, key) => {
    const result = list.find((x) => x.ID.toString() === key.toString());
    return result;
});
const selectActiveDealerInfo = createSelector(
    [selectDealerListData, selectActiveDealerKey],
    (dealerList, key) => {
        const result = dealerList.find((x) => x.ID.toString() === key.toString());
        return result;
    }
);

const selectAccessoryOnlyBit = createSelector(selectUserProfile, (profile) => {
    return profile.IsAccessoryOnly;
});

const selectDisplayDealerList = createSelector(selectDealerListData, (dealerList) =>
    dealerList.map((dealer) => {
        let display = dealer.DealerActID;
        if (dealer.ActTypeDesc) {
            display += ` (${dealer.ActTypeDesc})`;
        }
        return {
            ID: dealer.ID,
            DealerActID: dealer.DealerActID,
            ActType: dealer.ActType,
            Display: display,
        };
    })
);
const selectLoginId = createSelector(selectUserProfile, (profile) => {
    return profile.LoginId || '';
});
const selectSessionId = createSelector(selectUserProfile, (profile) => {
    return profile.SessionId || '';
});
const selectLoginStatus = createSelector(selectUserProfile, (profile) => {
    return profile.IsLoggedIn || false;
});
const selectSiteId = createSelector(selectUserProfile, (profile) => {
    return profile.SiteId || 10;
});
const selectFreeShippingAcc = createSelector(selectUserProfile, (profile) => {
    return profile.FreeShippingAcc || 0;
});

const selectUserIsRetail = createSelector(selectUserProfile, (profile) => {
    return profile.IsRetail || false;
});

const selectUserAccountId = createSelector(selectUserProfile, (profile) => {
    return profile.AccountId || 0;
});

export {
    selectSessionId,
    selectUserProfile,
    selectUserIsRetail,
    selectIsStopWebOrders,
    selectActiveDealerIdForAccount,
    selectActiveDealerInfo,
    selectDealerListData,
    selectDisplayDealerList,
    selectSiteId,
    selectLoginStatus,
    selectLoginId,
    selectInvoiceAccount,
    selectAccessoryOnlyBit,
    selectFreeShippingAcc,
    selectActiveDealerKey,
    selectDealerInfoByKey,
    selectUserAccountId,
};
