import { WebServiceHosts, AuthHeader } from '../../../helpers/siteConfigConstants';

const productControllerUri = `${WebServiceHosts.WebStore}/api/products`;
const detailUri = `${productControllerUri}/detail`;

export const fetchItemIds = () => {
    const itemIdsUri = `${productControllerUri}/itemID`;
    return fetch(itemIdsUri, {
        headers: {
            Authorization: AuthHeader,
        },
        method: 'GET',
        cache: 'no-cache',
    });
};
const fetchDetail = (itemId) => {
    const fullUri = `${detailUri}/${itemId}`;
    return fetch(fullUri, {
        headers: {
            Authorization: AuthHeader,
        },
        method: 'GET',
        cache: 'no-cache',
    });
};

export default fetchDetail;
