import React, { useRef, useState, useEffect } from 'react';
//import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import PVSelectDealer from './PVSelectDealer';

import PVCustId from './PVCustId';
import { LoginLink, LogoutLink } from '../../../../helpers/siteConfigConstants';
import { selectUserProfile } from '../../../../redux/userProfile/selectors';
import PVDotLoader from '../../PVDotLoader';

const PVHeaderLogin = (props) => {
    const {
        LoginId,
        IsLoggedIn,
        FirstName,
        LastName,
        IsInternal,
        InvoiceAccount,
        showOrderHistory,
    } = props;
    const isMounted = useRef();
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
        };
    }, []);
    const setLoading = (val) => {
        if (isMounted.current) {
            setIsLoading(val);
        }
    };

    const MyProfile = (
        <React.Fragment>
            <div className="pv-header-my-profile">
                <a href="/MyProfile/UserManagement/UserManagement.aspx">My Profile</a>
            </div>
            {showOrderHistory ? (<div className="pv-header-divider" />) : null}
        </React.Fragment>
    );

    const Careers = (
        <React.Fragment>
            <div className="pv-header-careers">
                <a href="/Company/Careers.aspx">Careers</a>
            </div>
            {IsLoggedIn ? (<div className="pv-header-divider" />) : null}
        </React.Fragment >
    );

    const OrderHistory = (
        <div className="pv-header-order-history">
            <a href="/Shop/OrderHistory">Order History</a>
        </div>
    );

    const signedOut = (
        <div className="header-top nav-mind">
            <div className="container">
                <div className="row">
                    <div className="col-xs-12">
                        {/* Sign in | Careers */}
                        <div className="pv-header-right">
                            <div className="pv-header-sign-in">
                                <a href={LoginLink}>
                                    <i className="fa fa-user" aria-hidden="true" />
                                    Sign in
                                </a>
                            </div>
                            <div className="pv-header-divider" />
                            {Careers}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
    const signedIn = (
        <div className="header-top nav-mind">
            <div className="container">
                <div className="row">

                    <div className="col-xs-12">

                        <div className="pv-header-left">
                            {/* Username */}
                            <div className="pv-header-user-name hidden-xs">
                                <a
                                    className="pv-header-user-name-link"
                                    href="/MyProfile/UserManagement/UserList.aspx"
                                >
                                    Hello, {FirstName} {LastName}
                                </a>
                            </div>
                            <div className="pv-header-divider hidden-xs" />

                            {/* DealerID */}
                            {isLoading ? ('') : (<PVSelectDealer />)}

                            {/* CustID or dot loader */}
                            <div className="pv-header-cust-id">
                                {isLoading ? (
                                    <div id="pvDotLoader">
                                        <PVDotLoader
                                            loading={isLoading}
                                            size={10}
                                            spacing={12}
                                            wrapperStyle={{ marginLeft: '100px', marginRight: '100px' }}
                                        />
                                    </div>
                                ) : (
                                    <React.Fragment>
                                        {IsInternal ? (
                                            <PVCustId setLoading={setLoading} />
                                        ) : (
                                            <div className="pv-header-cust-id-static">
                                                CustId: {InvoiceAccount}
                                            </div>
                                        )}
                                    </React.Fragment>
                                )}
                            </div>
                            <div className="pv-header-divider hidden-xs hidden-sm" />

                            {/* Sign Out */}
                            <div className="pv-header-sign-out hidden-xs hidden-sm">
                                <a href={LogoutLink}>
                                    Sign Out: {LoginId}
                                </a>
                            </div>
                        </div>

                        {/* Careers | My Profile | Order History */}
                        <div className="pv-header-right hidden-xs hidden-sm hidden-md">
                            {!IsLoggedIn ? Careers : null}
                            {IsLoggedIn ? MyProfile : null}
                            {showOrderHistory ? OrderHistory : null}
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
    const render = IsLoggedIn ? signedIn : signedOut;

    return <React.Fragment>{render}</React.Fragment>;
};

PVHeaderLogin.propTypes = {
    LoginId: PropTypes.string,
    IsLoggedIn: PropTypes.bool,
    FirstName: PropTypes.string,
    LastName: PropTypes.string,
    IsInternal: PropTypes.bool,
    InvoiceAccount: PropTypes.string.isRequired,
    showOrderHistory: PropTypes.bool,
};
PVHeaderLogin.defaultProps = {
    LoginId: '',
    IsLoggedIn: false,
    FirstName: '',
    LastName: '',
    IsInternal: false,
    showOrderHistory: true,
};
const mapStateToProps = (state) => {
    const userProfile = selectUserProfile(state);
    return userProfile;
};

export default connect(mapStateToProps, null)(PVHeaderLogin);
