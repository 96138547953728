import { createSlice } from '@reduxjs/toolkit';
import { propsByActionType, APIACTIONS } from '../../helpers/apiShared';

const cartSlice = createSlice({
    name: 'cart',
    initialState: {},
    reducers: {
        getCartSent: (state, _action) => {
            // const { payload } = action; //we don't need a payload but the replay in dev tools doesn't reflect anything unless we have a payload attached
            const fetchProps = propsByActionType(APIACTIONS.REQUEST);
            return { ...state, ...fetchProps };
        },
        getCartSuccess: (state, action) => {
            const cartResponse = action.payload;
            const fetchProps = propsByActionType(APIACTIONS.SUCCESS);
            return { ...state, ...fetchProps, ...cartResponse };
        },
        getCartFailure: (state, action) => {
            const error = action.payload;
            const fetchProps = propsByActionType(APIACTIONS.FAILURE);
            return { ...state, ...fetchProps, error };
        },
        invalidateCartAvailability: (state) => {
            const fetchProps = propsByActionType(APIACTIONS.LOCALCLEAR);
            const LowQuantityItems = { ...fetchProps };
            return { ...state, LowQuantityItems };
        },
        getCartAvailabilitySent: (state) => {
            const fetchProps = propsByActionType(APIACTIONS.REQUEST);
            const { LowQuantityItems } = state;
            return {
                ...state,
                ...fetchProps,
                LowQuantityItems: { ...fetchProps, ...LowQuantityItems },
            };
        },
        getCartAvailabilitySuccess: (state, action) => {
            const cartResponse = action.payload;
            const fetchProps = propsByActionType(APIACTIONS.SUCCESS);
            const { LowQuantityItems } = state;
            const { RemovedItems, QuantityChangedItems, ...cart } = cartResponse;
            const newCart = {
                ...cart,
                LowQuantityItems: { RemovedItems, QuantityChangedItems, ...fetchProps },
            };

            return {
                ...state,
                ...fetchProps,
                LowQuantityItems,
                ...newCart,
            };
        },
        getCartAvailabilityFailure: (state, action) => {
            const error = action.payload;
            const fetchProps = propsByActionType(APIACTIONS.FAILURE);

            return { ...state, ...fetchProps, error, LowQuantityItems: { ...fetchProps } };
        },
        updateCartFromFreightSuccess: (state, action) => {
            const fetchProps = propsByActionType(APIACTIONS.SUCCESS);
            const cartResponse = action.payload;
            return { ...state, ...fetchProps, ...cartResponse };
        },
        addCartItemSent: (state, action) => {
            // const { payload } = action; //we don't need a payload but the replay in dev tools doesn't reflect anything unless we have a payload attached
            const fetchProps = propsByActionType(APIACTIONS.REQUEST);
            return { ...state, ...action.payload, ...fetchProps };
        },
        addCartItemSuccess: (state, action) => {
            const cartResponse = action.payload;
            const fetchProps = propsByActionType(APIACTIONS.SUCCESS);
            return { ...state, ...fetchProps, ...cartResponse };
        },
        addCartItemFailure: (state, action) => {
            const error = action.payload;
            const fetchProps = propsByActionType(APIACTIONS.FAILURE);
            return { ...state, ...fetchProps, error };
        },
        addMultipleSent: (state, _action) => {
            // const { payload } = action; //we don't need a payload but the replay in dev tools doesn't reflect anything unless we have a payload attached
            const fetchProps = propsByActionType(APIACTIONS.REQUEST);
            return { ...state, ...fetchProps };
        },
        canAddMultipleSent: (state, _action) => {
            // const { payload } = action; //we don't need a payload but the replay in dev tools doesn't reflect anything unless we have a payload attached
            const fetchProps = propsByActionType(APIACTIONS.REQUEST);
            return { ...state, ...fetchProps };
        },
        addMultipleSuccess: (state, action) => {
            const cartResponse = action.payload;
            const fetchProps = propsByActionType(APIACTIONS.SUCCESS);
            return { ...state, ...fetchProps, ...cartResponse };
        },
        addMultipleFailure: (state, action) => {
            const error = action.payload;
            const fetchProps = propsByActionType(APIACTIONS.FAILURE);
            return { ...state, ...fetchProps, error };
        },
        deleteCartItemSent: (state, action) => {
            const fetchProps = propsByActionType(APIACTIONS.SUCCESS);
            return { ...state, ...action.payload, ...fetchProps };
        },
        deleteCartItemSuccess: (state, action) => {
            const fetchProps = propsByActionType(APIACTIONS.SUCCESS);
            const cartResponse = action.payload;
            return { ...state, ...fetchProps, ...cartResponse };
        },
        deleteCartItemFailure: (state, action) => {
            const fetchProps = propsByActionType(APIACTIONS.FAILURE);
            const error = action.payload;
            return { ...state, ...fetchProps, error };
        },
        updateCartItemSent: (state, action) => {
            const fetchProps = propsByActionType(APIACTIONS.SUCCESS);
            return { ...state, ...action.payload, ...fetchProps };
        },
        updateCartItemSuccess: (state, action) => {
            const fetchProps = propsByActionType(APIACTIONS.SUCCESS);
            const cartResponse = action.payload;
            return { ...state, ...fetchProps, ...cartResponse };
        },
        updateCartItemFailure: (state, action) => {
            const fetchProps = propsByActionType(APIACTIONS.FAILURE);
            const error = action.payload;
            return { ...state, ...fetchProps, error };
        },
        updateClearCartSent: (state, action) => {
            const fetchProps = propsByActionType(APIACTIONS.REQUEST);
            return { ...state, ...action.payload, ...fetchProps };
        },
        updateClearCartSuccess: (state, action) => {
            const fetchProps = propsByActionType(APIACTIONS.SUCCESS);
            const cartResponse = action.payload;
            return { ...state, ...fetchProps, ...cartResponse };
        },
        updateClearCartFailure: (state, action) => {
            const fetchProps = propsByActionType(APIACTIONS.FAILURE);
            const error = action.payload;
            return { ...state, ...fetchProps, error };
        },
        deleteWarehouseCartSent: (state, action) => {
            const fetchProps = propsByActionType(APIACTIONS.REQUEST);
            const { payload } = action;
            return { ...state, ...fetchProps, ...payload };
        },
        deleteWarehouseCartSuccess: (state, action) => {
            const fetchProps = propsByActionType(APIACTIONS.SUCCESS);
            const { payload } = action;
            return { ...state, ...fetchProps, ...payload };
        },
        deleteWarehouseCartFailure: (state, action) => {
            const fetchProps = propsByActionType(APIACTIONS.FAILURE);
            const { payload } = action;
            return { ...state, ...fetchProps, ...payload };
        },
        setLowQuantityItems: (state, action) => {
            const fetchProps = propsByActionType(APIACTIONS.LOCALCHANGE);
            const { QuantityChangedItems, RemovedItems } = action.payload;
            const LowQuantityItems = { ...fetchProps, RemovedItems, QuantityChangedItems };
            return { ...state, LowQuantityItems };
        },
        invalidateCart: (_state, _action) => {
            const fetchProps = propsByActionType(APIACTIONS.LOCALCHANGE);
            const newObj = {};
            return { newObj, ...fetchProps };
        },
    },
});

export const { actions, reducer } = cartSlice;
export default reducer;
