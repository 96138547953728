const defaultKeyName = 'objId';
const createKeyedObj = (objIn, keyParam, newParamName = defaultKeyName) => {
    if (objIn && keyParam) {
        return Object.values(objIn).reduce((obj, row) => {
            const idxVal = row[keyParam];
            if (idxVal) {
                return { ...obj, [idxVal]: { ...row, [newParamName]: idxVal } };
            }
            return { ...obj };
        }, {});
    }
    return {};
};

const createSortedIndex = (objIn, sortParam, keyParam = defaultKeyName, ascending = true) => {
    if (objIn && sortParam) {
        const sortIndex = Object.values(objIn).reduce((obj, row) => {
            const sortVal = row[sortParam] || 'zzzzzzzzzzz';
            return { ...obj, [sortVal]: row[keyParam] };
        }, {});

        const sorted = ascending
            ? Object.keys(sortIndex).sort()
            : Object.keys(sortIndex).sort().reverse();

        return sorted.reduce((obj, row) => {
            const val = sortIndex[row];
            return { ...obj, [row]: val };
        }, {});
    }
    return {};
};
const getIndexedValues = (keyedObj, sortedIndex) => {
    return Object.values(sortedIndex).reduce((obj, indexVal) => {
        return { ...obj, [indexVal]: keyedObj[indexVal] };
    }, {});
};

export { createKeyedObj, createSortedIndex, getIndexedValues };
export default createKeyedObj;
