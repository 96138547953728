import './polyfills';
// import { setConfig, cold } from 'react-hot-loader';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import GoogleAnalytics from 'react-router-ga';
import ScrollToTop from './components/Shared/ScrollToTop';

import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Content/krystal/css/pv-style.css';
import '../Content/krystal/css/font-awesome.min.css';
import '../Content/krystal/css/googleFonts.css';
import '../Content/PVMenuBar.css';
import '../Content/legacycss/repmap.css';
import App from './App';

import store from './redux/store';

// setConfig({
//     logLevel: 'error',
//     onComponentRegister: (type, name, file) => file.indexOf('node_modules') > 0 && cold(type),
//     onComponentCreate: (type, name) => name.indexOf('styled') > 0 && cold(type),
// });

const rootElement = document.getElementById('ClientApp');

ReactDOM.render(
    <BrowserRouter>
        <GoogleAnalytics id="UA-4415890-9">
            <Provider store={store}>
                <App />
                <ScrollToTop />
            </Provider>
        </GoogleAnalytics>
    </BrowserRouter>,
    rootElement
);
