import { createSelector } from 'reselect';

const selectPaymentSlice = (state) => {
    return state.checkoutPayment || {};
};

const selectPaymentOptions = createSelector(selectPaymentSlice, (slice) => {
    return slice.options || {};
});
const selectPaymentOptionsReady = createSelector(selectPaymentOptions, (options) => {
    const isFetching = options.isFetching || false;
    const receivedAt = options.receivedAt || 0;

    const isReady = isFetching === false && receivedAt > 0;
    return isReady;
});
const selectPaymentOptionsUseTerms = createSelector(selectPaymentOptions, (options) => {
    return options.Terms || false;
});
const selectPaymentOptionsCCOnFile = createSelector(selectPaymentOptions, (options) => {
    return options.CCOnFile || [];
});
const selectPaymentOptionsHasAchPin = createSelector(selectPaymentOptions, (options) => {
    return options.ACHHasPin || false;
});
const selectPaymentOptionsAch = createSelector(selectPaymentOptions, (options) => {
    return options.ACH || [];
});
const selectPaypalSubslice = createSelector(selectPaymentSlice, (slice) => {
    return slice.paypal || {};
});
const selectPaypalInfoLoaded = createSelector(selectPaypalSubslice, (paypal) => {
    const { isFetching, receivedAt } = paypal;
    return isFetching === false && (receivedAt || 0) > 0;
});
const selectPaymentRehydrated = createSelector(selectPaymentSlice, (payment) => {
    const { rehydrated } = payment;
    return rehydrated === true;
});

const selectors = {
    selectPaymentRehydrated,
    selectPaymentOptions,
    selectPaymentSlice,
    selectPaymentOptionsReady,
    selectPaymentOptionsUseTerms,
    selectPaymentOptionsCCOnFile,
    selectPaymentOptionsAch,
    selectPaypalSubslice,
    selectPaypalInfoLoaded,
};
export default selectors;
export {
    selectPaymentRehydrated,
    selectPaymentOptions,
    selectPaymentSlice,
    selectPaymentOptionsReady,
    selectPaymentOptionsUseTerms,
    selectPaymentOptionsHasAchPin,
    selectPaymentOptionsCCOnFile,
    selectPaymentOptionsAch,
    selectPaypalSubslice,
    selectPaypalInfoLoaded,
};
