import { ABORTREASONS } from './apiShared';

const initialFetchProperties = {
    isFetching: false,
    success: true,
    receivedAt: Date.now(),
    abortReason: ABORTREASONS.NO_ABORT,
};

const extractInitStateObject = (payload) => {
    const { AccountInfo, TopMenus, TopCategories } = payload;

    let initObj = {
        menus: { ...initialFetchProperties, ...TopMenus },
        userProfile: { ...initialFetchProperties, ...AccountInfo },
    };
    if (TopCategories) {
        const category = TopCategories.reduce((obj, curr) => {
            const { Id, DisplayName, Category, BannerImage } = curr;
            const newProp = {
                [Category]: {
                    categoryName: Category,
                    Name: category,
                    DisplayName,
                    invalid: false,
                    Id,
                    BannerImage,
                    ...initialFetchProperties,
                },
            };
            return { ...obj, ...newProp };
        }, {});
        initObj = { ...initObj, products: { category } };
    }

    return initObj;
};

export default extractInitStateObject;
export { initialFetchProperties };
