import PropTypes from 'prop-types';
import delve from 'dlv';
import { actions } from './alertSlice';
import store from '../store';

const AlertType = {
    VALIDATION: 'VALIDATION',
    PAGE: 'PAGE',
    GENERIC: 'GENERIC',
};

const DisplayType = {
    INFO: 'INFO',
    WARNING: 'WARNING',
    DANGER: 'DANGER',
    SUCCESS: 'SUCCESS',
};

class AlertObj extends Object {
    constructor(id, displayType, message, alertType, dismissable, expiresMs) {
        super();
        this.id = id;
        this.dateAdded = Date.now();
        this.displayType = displayType;
        this.message = message;
        this.dismissable = dismissable;
        this.expiresMs = expiresMs;
        this.alertType = alertType;
    }
}
AlertObj.propTypes = {
    dismissable: PropTypes.bool.isRequired,
    displayType: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    expiresMs: PropTypes.number.isRequired,
};

const addAlertFromError = (alert) => {
    const newAlert = {
        dateAdded: alert.dateAdded,
        displayType: alert.displayType,
        message: alert.message,
        dismissable: alert.dismissable || false,
        expiresMs: alert.expiresMs,
        alertType: alert.alertType || AlertType.GENERIC,
        id: alert.id,
    };
    return store.dispatch(actions.alertAdded(newAlert));
};

const removeAllAlerts = () => {
    return store.dispatch(actions.removeAllAlerts('cleared'));
};
const removeById = (id) => {
    return store.dispatch(actions.removeById(id));
};

const removeAlertByType = (alertType) => {
    return store.dispatch(actions.removeAlertByType(alertType.toString()));
};

const addAlertObjects = (alertArray) => {
    if (Array.isArray(alertArray)) {
        alertArray.forEach((alert) => {
            const {
                id,
                dateAdded,
                displayType,
                message,
                dismissable,
                expiresMs,
                alertType,
            } = alert;
            store.dispatch(
                actions.alertAdded({
                    id,
                    dateAdded,
                    displayType,
                    message,
                    dismissable,
                    expiresMs,
                    alertType,
                })
            );
        });
    }
};

const parseAlerts = (incoming) => {
    const arr = [];
    const firstOneWithPayload = Array.isArray(incoming) ? incoming.find((x) => x.payload) : null;
    let e = firstOneWithPayload || incoming.Errors || incoming || incoming.payload;
    const payloadErrors =
        delve(e, 'payload.error') ||
        delve(e, 'payload.errors') ||
        delve(e, 'payload.Error') ||
        delve(e, 'payload.Errors');
    if (e && e.payload && Array.isArray(e.payload)) {
        e = e.payload;
    } else if (payloadErrors) {
        e = payloadErrors;
    }

    if (!Array.isArray(e) && e.Name === 'ValidationError' && e.errors) {
        const validation = e;
        validation.errors.forEach((errorString, idx) => {
            const validationAlert = new AlertObj(
                `addressValidation-${idx}`,
                DisplayType.DANGER,
                errorString,
                AlertType.VALIDATION,
                true
            );
            arr.push(validationAlert);
        });
    } else if (!Array.isArray(e) && e.Name !== 'ValidationError') {
        let errors;
        if (e.Errors && Array.isArray(e.Errors)) {
            errors = e.Errors;
        } else if (!Array.isArray(e)) {
            errors = [e];
        }
        const alerts = errors.map((err) => {
            let msg = err;
            if (e.Description || err.message) {
                msg = err.Description || err.message;
            }
            msg = typeof msg === 'string' ? msg : 'An unknown error occurred.';
            return new AlertObj(
                `alert-${Date.now().toString()}`,
                DisplayType.DANGER,
                msg,
                AlertType.PAGE,
                true
            );
        });
        alerts.forEach((errAlert) => {
            arr.push(errAlert);
        });
    } else if (Array.isArray(e)) {
        e.forEach((err) => {
            const description = delve(err, 'Description', '');
            const actionDescription = delve(err, 'ActionDescription', '');
            const msg =
                (description.length > actionDescription.length ? description : actionDescription) ||
                err.message ||
                err;

            if (msg) {
                const a = new AlertObj(
                    `alert-${new Date()}`,
                    DisplayType.DANGER,
                    msg,
                    AlertType.PAGE,
                    true
                );
                arr.push(a);
            }
        });
    }
    return arr;
};

export {
    AlertObj,
    AlertType,
    DisplayType,
    addAlertFromError,
    addAlertObjects,
    removeAllAlerts,
    removeById,
    removeAlertByType,
    parseAlerts,
};
/*USAGE NOTES
You don't need to connect your page to the redux store or mapDispatch to props, it's taken care of from here.

import { addAlertFromError, DisplayType, AlertType, AlertObj } from '../../../redux/alerts';
    const validationAlert = new AlertObj(
        'validationTest',
        DisplayType.DANGER,
        'example validation error!',
        AlertType.VALIDATION,
        false,
        0
    );
addAlertFromError(validationAlert);

*/
