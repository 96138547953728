import { createSelector } from 'reselect';

const selectCheckoutSelectionsSlice = (state) => {
    return state.checkoutSelections || {};
};

const selectShipToSubslice = createSelector(selectCheckoutSelectionsSlice, (slice) => {
    return slice.shipTo || {};
});
const selectSendingShipToInProgress = createSelector(selectShipToSubslice, (shipToSlice) => {
    return shipToSlice.isFetching || false;
});
const selectFreightSubslice = createSelector(selectCheckoutSelectionsSlice, (slice) => {
    return slice.freight || {};
});

const selectSendFreightSuccess = createSelector(selectFreightSubslice, (freightSlice) => {
    const isFetching = freightSlice.isFetching || false;
    const receivedAt = freightSlice.receivedAt || 0;
    const success = freightSlice.success || false;

    return !isFetching && receivedAt > 0 && success;
});

const selectShipToAddress = createSelector(selectShipToSubslice, (slice) => {
    return slice.address || {};
});

const selectShipToIsCpu = createSelector(selectShipToAddress, (address) => {
    return address.IsCustomerPickUp === true;
});

const selectShipToIsReady = createSelector(selectShipToSubslice, (slice) => {
    const isFetching = slice.isFetching || false;
    const receivedAt = slice.receivedAt || 0;
    return isFetching === false && receivedAt > 0;
});
const selectPaymentSubslice = createSelector(selectCheckoutSelectionsSlice, (slice) => {
    return slice.payment || {};
});
const selectPaymentACH = createSelector(selectPaymentSubslice, (paymentInfo) => {
    return paymentInfo.ACH;
});
const selectLocalValidationOk = createSelector(selectPaymentSubslice, (payment) => {
    const { localValidation } = payment;
    return localValidation === true;
});
const selectPaymentResults = createSelector(selectPaymentSubslice, (payment) => {
    return payment.results || [];
});
const selectPaymentIsProcessing = createSelector(selectPaymentSubslice, (payment) => {
    return payment.isFetching === true;
});

const selectors = {
    selectLocalValidationOk,
    selectCheckoutSelectionsSlice,
    selectShipToSubslice,
    selectShipToAddress,
    selectShipToIsCpu,
    selectShipToIsReady,
    selectFreightSubslice,
    selectPaymentSubslice,
    selectPaymentACH,
    selectPaymentResults,
    selectPaymentIsProcessing,
    selectSendFreightSuccess,
    selectSendingShipToInProgress,
};
export default selectors;
export {
    selectLocalValidationOk,
    selectCheckoutSelectionsSlice,
    selectShipToSubslice,
    selectShipToAddress,
    selectShipToIsCpu,
    selectShipToIsReady,
    selectFreightSubslice,
    selectPaymentSubslice,
    selectPaymentACH,
    selectPaymentResults,
    selectPaymentIsProcessing,
    selectSendFreightSuccess,
    selectSendingShipToInProgress,
};
