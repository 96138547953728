import React, { useState } from 'react';
import PVModalDialog from './PVModalDialog';

const PVWarningModal = (props) => {
    const [modalIsOpen, setModalIsOpen] = useState(true);
    const closeModal = () => {
        setModalIsOpen(false);
    };

    const defaultProps = {
        isOpen: modalIsOpen,
        onRequestClose: closeModal,
        className: 'modal-dialog',
        overlayClassName: 'modal-overlay',
        shouldCloseOnOverlayClick: false,
        closeTimeoutMS: 500,
    };
    const modalProps = { ...defaultProps, ...props };

    return <PVModalDialog {...modalProps} />;
};

export default PVWarningModal;
