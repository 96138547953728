import React from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { withRouter } from 'react-router-dom';

const PVLinkButton = (props) => {
    const { linkTo, text, isEnabled, btnClass, history, handleHover } = props;

    function handleClick() {
        if (linkTo.substring(0, 4).toLowerCase() === 'http') {
            window.location.replace(linkTo);
        } else {
            history.push(linkTo);
        }
    }

    return (
        <button
            type="button"
            className={btnClass}
            onClick={handleClick}
            enabled={`${isEnabled.toString()}`}
            onMouseOver={handleHover}
            onFocus={handleHover}
        >
            {text}
        </button>
    );
};

PVLinkButton.propTypes = {
    linkTo: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    isEnabled: PropTypes.bool,
    btnClass: PropTypes.string,
    history: ReactRouterPropTypes.history.isRequired,
    handleHover: PropTypes.func,
};

PVLinkButton.defaultProps = {
    isEnabled: true,
    btnClass: '',
    handleHover: () => {},
};

export default withRouter(PVLinkButton);
