/* eslint-disable import/prefer-default-export */

import { createSelector } from 'reselect';

const selectSlice = (state) => {
    return state.checkoutAddresses || {};
};
const selectLastAddressSubslice = createSelector(selectSlice, (slice) => {
    return slice.lastUsedAddress || {};
});

const selectLastAddress = createSelector(selectLastAddressSubslice, (subSlice) => {
    return subSlice.address || {};
});
const selectLastAddressisFetching = createSelector(selectLastAddressSubslice, (slice) => {
    return slice.isFetching || false;
});

const selectLastAddressReceivedAt = createSelector(selectLastAddressSubslice, (slice) => {
    return slice.receivedAt || 0;
});

const selectLastAddressLoaded = createSelector(
    [selectLastAddressisFetching, selectLastAddressReceivedAt],
    (fetching, received) => {
        return received > 0 && !fetching;
    }
);

const selectSavedSubslice = createSelector(selectSlice, (slice) => {
    return slice.savedList || {};
});

const selectSavedLoaded = createSelector(selectSavedSubslice, (subSlice) => {
    return subSlice.isFetching === false && subSlice.receivedAt > 0;
});
const selectSavedList = createSelector(selectSavedSubslice, (subslice) => {
    return subslice.addresses || [];
});
const selectSelectedAddressId = createSelector(selectSlice, (addressSlice) => {
    return addressSlice.selectedAddressId || '0';
});

const selectAddresBySelectedId = createSelector(
    [selectSavedList, selectSelectedAddressId],
    (savedList, selectedId) => {
        return savedList.find((address) => {
            return address.ID.toString() === selectedId.toString();
        });
    }
);
const selectSavedListReady = createSelector(selectSavedSubslice, (subslice) => {
    return !subslice.isFetching && subslice.success && subslice.receivedAt > 0;
});
const selectZipSubslice = createSelector(selectSlice, (slice) => {
    return slice.zip || {};
});
const selectZipInput = (_state, zip) => {
    return zip;
};
const selectZipInfo = createSelector([selectZipSubslice, selectZipInput], (subslice, inputZip) => {
    return subslice[inputZip];
});
const selectShippingAddressSubslice = createSelector(selectSlice, (addressSlice) => {
    return (addressSlice || {}).shippingAddress || {};
});
const selectShippingAddressIsCpu = createSelector(
    selectShippingAddressSubslice,
    (shippingAddress) => {
        return shippingAddress.CustomerPickup || shippingAddress.IsCustomerPickUp || false;
    }
);
const selectPickupLocationsSubslice = createSelector(selectSlice, (slice) => {
    return slice.pickupLocations || {};
});

const selectPickupLocationsList = createSelector(selectPickupLocationsSubslice, (subslice) => {
    return subslice.list || [];
});

export {
    selectLastAddress,
    selectSavedList,
    selectSavedLoaded,
    selectLastAddressSubslice,
    selectLastAddressLoaded,
    selectSavedSubslice,
    selectSlice,
    selectSelectedAddressId,
    selectAddresBySelectedId,
    selectSavedListReady,
    selectZipSubslice,
    selectZipInfo,
    selectShippingAddressSubslice,
    selectShippingAddressIsCpu,
    selectPickupLocationsSubslice,
    selectPickupLocationsList,
};
