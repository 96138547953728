export const textDelimeterEnum = {
    NEWLINE: '\n',
    COMMA: ',',
    SEMICOLON: ';',
    PIPE: '|',
};

export const convertDelimeters = (inString, destDelimiter, trimItems = false) => {
    if (!destDelimiter) {
        return inString;
    }
    if (!inString) {
        return '';
    }
    const delimiterList = Object.values(textDelimeterEnum).filter((x) => x !== destDelimiter);
    let normalizedString = inString.replace(/\r/gm, '');

    const regEx = new RegExp(`[${delimiterList.join('')}]`, ['g']);
    normalizedString = normalizedString.replace(regEx, destDelimiter);
    if (trimItems) {
        return normalizedString
            .split(destDelimiter)
            .map((s) => s.trim())
            .join(destDelimiter);
    }
    return normalizedString;
};

const ConvertDelimeters = {
    convertDelimeters,
    textDelimeterEnum,
};
export default ConvertDelimeters;
