import PropTypes from 'prop-types';

export const PVPropsCreditCard = PropTypes.shape({
    CreditCardID: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    CreditCardNumber: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    DDLText: PropTypes.string,
    CreditCardNum: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    ExpirationMonth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    ExpirationYear: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    ExpirationDate: PropTypes.string,
    NameOnCard: PropTypes.string,
    CreditCardType: PropTypes.string,
    SecurityCode: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    BillToStreet: PropTypes.string,
    BillToZip: PropTypes.string,
    BillToCity: PropTypes.string,
    BillToState: PropTypes.string,
});

export default PVPropsCreditCard;
