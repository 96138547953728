import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import PVMainDom from './LandingMainContent/PVMainDom';
import PVMainWire from './LandingMainContent/PVMainWIRE';
import PVMainIntl from './LandingMainContent/PVMainINTL';
import PVMainGlobal from './LandingMainContent/PVMainGlobal';
import { selectSubProperty, selectSiteName } from '../../redux/content/selectors';
import { getLandingJson } from '../../redux/content/actionsAsync';

const PVHome = ({ siteName, staticContent, getPageInfo }) => {
    const isMounted = useRef();
    const [content, setContent] = useState({
        component: null,
        siteName,
        staticContent,
    });
    useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
        };
    }, []);

    const awaitCarouselJson = async () => {
        const newContent = await getPageInfo(siteName);
        return newContent;
    };
    useEffect(() => {
        let component;

        switch ((siteName || '').toString()) {
            case 'dom':
                component = PVMainDom;
                break;
            case 'wire':
                component = PVMainWire;
                break;
            case 'intl':
                component = PVMainIntl;
                break;
            default:
                component = PVMainGlobal;
                break;
        }

        if (!staticContent || !staticContent.mainSlideContent || siteName !== content.siteName) {
            awaitCarouselJson();
        }

        if (isMounted.current) {
            const updatedState = {
                component,
                siteName,
            };
            setContent(updatedState);
        }
    }, [siteName, staticContent]);

    return (
        <React.Fragment>
            {content.component && staticContent && (
                <content.component staticContent={staticContent} />
            )}
        </React.Fragment>
    );
};
PVHome.propTypes = {
    siteName: PropTypes.string.isRequired,
    staticContent: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.any]),
    getPageInfo: PropTypes.func.isRequired,
};
PVHome.defaultProps = {
    staticContent: null,
};
const mapStateToProps = (state) => {
    const selectOptions = {
        subslice: 'landings',
        property: selectSiteName(state),
    };
    return {
        siteName: selectSiteName(state),
        staticContent: selectSubProperty(state, selectOptions),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getPageInfo: (siteName) => dispatch(getLandingJson(siteName)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PVHome);
