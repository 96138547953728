import PropTypes from 'prop-types';

export const PVPropsACH = PropTypes.shape({
    PTNAccountNumSecured: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    RecID: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    PIN: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    CheckNumber: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
});

export default PVPropsACH;
