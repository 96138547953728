import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as selectors from '../../../../../redux/cart/selectors';
import { getPriceString } from '../../../../../helpers/textFunctions';
import PVCartFlyoutSlider from './PVCartFlyoutSlider';
import PVFreeShipping from '../../../../PVWebstore/PVFreeShipping';
import PVCheckoutButton from '../../../../PVWebstore/PVCart/PVCheckoutButton';
import '../../../../PVWebstore/PVCheckout/PVCheckout.css';

const PVCartFlyout = ({ qty, totalPrice, freeShipping }) => {
    return (
        <div className="parent-megamenu hidden-xs">
            <div className="cart-dropdown">
                <div />
                <div className="cart-dropdown-left">
                    <div className="cart-dropdown-left-container">
                        <PVCartFlyoutSlider />
                    </div>
                </div>
                <div className="cart-dropdown-right">
                    <div className="cart-dropdown-right-container">
                        <div className="cart-items-count">
                            <h4>
                                <strong>
                                    <span>{qty}</span> Items In Your Cart
                                </strong>
                            </h4>
                        </div>
                        <div className="cart-items-total">
                            <h5>
                                <strong>
                                    Subtotal:
                                    <span className="pull-right">{getPriceString(totalPrice)}</span>
                                </strong>
                            </h5>
                        </div>
                        <div className="cart-dropdown-italic">
                            <small>
                                <em>Tax and shipping applied at checkout.</em>
                            </small>
                        </div>
                        <div className={`${freeShipping ? 'text-success' : 'text-danger'}`}>
                            <strong>
                                <em>
                                    <small>
                                        <PVFreeShipping />
                                    </small>
                                </em>
                            </strong>
                        </div>
                        <Link className="pv btn btn-primary cart-flyout" to="/Shop/Cart">
                            View Cart
                        </Link>
                        <PVCheckoutButton />
                    </div>
                </div>
            </div>
        </div>
    );
};

PVCartFlyout.propTypes = {
    qty: PropTypes.number,
    totalPrice: PropTypes.number,
    freeShipping: PropTypes.bool.isRequired,
};
PVCartFlyout.defaultProps = {
    qty: 0,
    totalPrice: 0.0,
};

const mapStateToProps = (state) => {
    return {
        qty: selectors.selectCartQty(state),
        totalPrice: selectors.selectCartTotalPrice(state),
        freeShipping: selectors.selectFreeShippingQualified(state),
    };
};

export default connect(mapStateToProps, null)(PVCartFlyout);
