/* eslint-disable react/forbid-prop-types */
import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const PVDotLoader = ({ loading, size, duration, spacing, wrapperStyle }) => {
    const isMounted = useRef();
    const [dotStyle, setDotStyle] = useState({});
    const [middleDotStyle, setMiddleDotStyle] = useState({});
    const [endDotStyle, setEndDotStyle] = useState({});
    useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
        };
    }, []);

    useEffect(() => {
        let overrideStyle = {};
        let middleStyle = {};
        let endStyle = {};

        if (duration > -1) {
            const durationStyle = {
                animation: `pv-dot-loader-animation ${duration}s linear alternate infinite`,
            };

            middleStyle = { animationDelay: `${duration / 2}s` };
            endStyle = { animationDelay: `${duration}s` };
            overrideStyle = {
                ...overrideStyle,
                ...durationStyle,
            };
        }
        if (size > -1) {
            const sizeHalf = size / 2;
            const sizeStyle = {
                height: `${size}px`,
                width: `${size}px`,
                borderRadius: `${sizeHalf}px`,
            };
            overrideStyle = { ...overrideStyle, ...sizeStyle };
        }
        if (spacing > -1) {
            const marginStyle = {
                marginLeft: `${spacing}px`,
            };
            middleStyle = { ...middleStyle, ...marginStyle };
            endStyle = { ...endStyle, ...marginStyle };
        }

        setDotStyle(overrideStyle);
        setMiddleDotStyle({ ...overrideStyle, ...middleStyle });
        setEndDotStyle({ ...overrideStyle, ...endStyle });
    }, []);

    return (
        <React.Fragment>
            {loading && (
                <div className="pv-dot-loaderWrap" style={wrapperStyle}>
                    <div className="pv-dot-loader pv-dot-loader_1" style={dotStyle} />
                    <div className="pv-dot-loader pv-dot-loader_2" style={middleDotStyle} />
                    <div className="pv-dot-loader pv-dot-loader_3" style={endDotStyle} />
                </div>
            )}
        </React.Fragment>
    );
};

PVDotLoader.propTypes = {
    loading: PropTypes.bool,
    duration: PropTypes.number,
    size: PropTypes.number,
    spacing: PropTypes.number,
    // eslint-disable-next-line react/no-unused-prop-types
    wrapperStyle: PropTypes.object,
};
PVDotLoader.defaultProps = {
    loading: false,
    duration: -1,
    size: -1,
    spacing: -1,
    wrapperStyle: {},
};
export default PVDotLoader;
