const validateParameters = (fieldName, form, invalidClassName, validateOnTouch) => {
    if (!fieldName || typeof fieldName !== 'string') {
        throw new Error('A fieldName string is required');
    } else if (!form || typeof form !== 'object') {
        throw new Error('formik form object is required');
    } else if (typeof invalidClassName !== 'string') {
        throw new Error('invalid className property should be a string');
    } else if (typeof validateOnTouch !== 'boolean') {
        throw new Error('validateOnTouch property can only be true or false');
    }
};
const getInvalidFieldClass = (
    fieldName,
    form,
    invalidClassName = 'pv is-invalid',
    validateOnTouch = false
) => {
    const { errors, touched, submitCount } = form;

    const readyToValidate = (validateOnTouch && touched[fieldName]) || submitCount > 0;

    const className = readyToValidate && errors[fieldName] ? invalidClassName : '';

    return className;
};
const getFieldStatusClass = (
    fieldName,
    form,
    validClassName = 'field-ok',
    invalidClassName = 'pv is-invalid',
    validateOnTouch = false
) => {
    validateParameters(fieldName, form, invalidClassName, validateOnTouch);
    if (typeof validClassName !== 'string') {
        throw new Error('The property validClassName should be a string');
    }

    const { errors, touched, submitCount } = form;

    const readyToValidate = (validateOnTouch && touched[fieldName]) || submitCount > 0;

    if (readyToValidate) {
        return errors[fieldName] ? invalidClassName : validClassName;
    }
    return '';
};

const func = { getFieldStatusClass, getInvalidFieldClass };
export default func;
export { getFieldStatusClass, getInvalidFieldClass };
