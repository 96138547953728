import { actions } from './itemsSlice';
import fetchDetail, { fetchItemIds } from './api';
import { shouldFetchForSlice } from '../../../helpers/apiShared';
import { selectItemId } from './selectors';

export const getItem = (itemId) => {
    return async (dispatch, getState) => {
        const initialState = selectItemId(getState(), itemId);
        if (shouldFetchForSlice(initialState, 500)) {
            dispatch(actions.getItemSent({ itemId }));
            let response;
            const json = await fetchDetail(itemId)
                .then((resp) => {
                    response = resp;
                    return resp.json();
                })
                .catch((err) => {
                    response = err;
                    return err.json();
                });
            if (response.ok && json.ItemID) {
                const item = { itemId, body: { ...json } };
                dispatch(actions.getItemSuccess(item));
                return Promise.resolve(json);
            }
            const error = { itemId, error: { ...json } };
            dispatch(actions.getItemFailure(error));
            return Promise.reject(error);
        }
        return Promise.resolve();
    };
};

export const getItemWithApiFallback = (itemId, requireDetails = true) => {
    return async (dispatch, getState) => {
        let rdxItem = selectItemId(getState(), itemId);
        if (!rdxItem.ItemID || (requireDetails && !rdxItem.Dimentions)) {
            rdxItem = await dispatch(getItem(itemId)).then((itemObj) => itemObj);
        }
        if (rdxItem.ItemID) {
            return Promise.resolve(rdxItem);
        }
        return Promise.reject();
    };
};

export const getItemIds = () => {
    return async (dispatch) => {
        dispatch(actions.getItemIdsListSent({ request: 'sent' }));
        const response = await fetchItemIds();
        const json = await response.json();
        if (response.ok) {
            const list = [...json];
            return dispatch(actions.getItemIdsListSuccess({ list }));
        }
        const errors = json;
        return dispatch(actions.getItemIdsListFailure({ errors }));
    };
};

export default getItem;
