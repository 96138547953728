import cloneDeep from 'clone-deep';
import {
    fetchFreightQuote,
    freightTypes,
    shouldFetchFreight,
    putResetFreight,
    fetchInitialQuotes,
} from './api';
import { actions } from './checkoutShippingSlice';
import { selectFreightQuotesByType, selectShippingSlice, selectWarehouseQuotes } from './selectors';
import { selectors as cartSelectors } from '../cart/selectors';
import { selectShipToSubslice } from '../checkout-selections/selectors';

const getInitialFreightQuotes = (isRetry = false) => {
    return async (dispatch, getState) => {
        const fullState = getState();
        const shoppingCarts = cartSelectors.selectAllCarts(fullState);
        const getGround = shoppingCarts.some((c) => c.GroundShipping);
        const getLtl = shoppingCarts.some((c) => c.LTLShipping);
        const warehouseQuotes = shoppingCarts.reduce((acc, curr) => {
            const { WarehouseID, GroundShipping, LTLShipping } = curr;
            acc[WarehouseID] = {
                standard: { enabled: GroundShipping, requested: GroundShipping, list: [] },
                expedited: { enabled: GroundShipping, requested: false, list: [] },
                ltl: { enabled: LTLShipping, requested: LTLShipping, list: [] },
            };
            return acc;
        }, {});
        dispatch(actions.setWarehouseQuotes({ warehouseQuotes }));

        let response;
        let promises = [];
        const json = await fetchInitialQuotes(getGround, getLtl)
            .then((res) => {
                response = res;
                return res.json();
            })
            .catch((err) => {
                const obj = typeof err === 'string' ? { error: err.toString() } : err;
                response = obj;
                return obj;
            });
        const ltlObj = cloneDeep(json);
        const standardObj = cloneDeep(json);
        let ltlPayload = { freightType: freightTypes.LTL };
        let groundPayload = { freightType: freightTypes.STANDARD };
        if (response.ok) {
            if (getLtl && json.FreightQuotes) {
                ltlObj.FreightQuotes = ltlObj.FreightQuotes.map((fq) => {
                    const wh = cloneDeep(fq);
                    wh.FreightQuoteItems = wh.FreightQuoteItems.filter((fqi) =>
                        fqi.Carrier.startsWith('LTL')
                    );
                    return wh;
                }, []);
                ltlPayload = { ...ltlPayload, freightPayload: ltlObj };
                promises = [
                    ...promises,
                    Promise.resolve(dispatch(actions.updateWarehouseQuoteLists(ltlPayload))),
                    Promise.resolve(dispatch(actions.getFreightQuoteSuccess(ltlPayload))),
                ];
            }

            if (getGround) {
                standardObj.FreightQuotes = standardObj.FreightQuotes.map((fq) => {
                    const wh = cloneDeep(fq);
                    wh.FreightQuoteItems = wh.FreightQuoteItems.filter(
                        (fqi) => !fqi.Carrier.startsWith('LTL')
                    );
                    return wh;
                }, []);
                groundPayload = {
                    ...groundPayload,
                    freightPayload: standardObj,
                };
                promises = [
                    ...promises,
                    Promise.resolve(dispatch(actions.updateWarehouseQuoteLists(groundPayload))),
                    Promise.resolve(dispatch(actions.getFreightQuoteSuccess(groundPayload))),
                ];
            }
            return Promise.allSettled(promises);
        }

        if (!isRetry) {
            //4044 issue 262, 268 (I think)
            return dispatch(getInitialFreightQuotes(true));
        }

        promises = [];
        if (getLtl) {
            promises = [
                Promise.reject(dispatch(actions.getFreightQuoteFailure({ ...ltlPayload, json }))),
            ];
        }
        promises = getGround
            ? [
                  ...promises,
                  Promise.reject(
                      dispatch(actions.getFreightQuoteFailure({ ...groundPayload, json }))
                  ),
              ]
            : promises;

        return Promise.allSettled(promises);
    };
};

const getFreightQuote = (freightType = freightTypes.STANDARD, signal = null, isRetry = false) => {
    return async (dispatch, getState) => {
        const state = getState();
        const freightSlice = selectFreightQuotesByType(state, freightType);
        const currentWarehouseQuotes = selectWarehouseQuotes(state);
        const cartSlice = cartSelectors.selectCartSlice(state);
        const booFetchFreight = shouldFetchFreight(freightSlice);
        if (!booFetchFreight) {
            setTimeout(() => getFreightQuote(freightType, signal, isRetry), 100);
        }
        const shipToSlice = selectShipToSubslice(state);
        const cartNewerThanQuote = (cartSlice.receivedAt || 0) > (freightSlice.receivedAt || 0);
        const shipToNewerThanQuote = (shipToSlice.receivedAt || 0) > (freightSlice.receivedAt || 0);
        if (booFetchFreight || cartNewerThanQuote || shipToNewerThanQuote) {
            const warehouseQuotes = cartSlice.ShoppingCarts.reduce((acc, curr) => {
                const { WarehouseID } = curr;
                const currWh = currentWarehouseQuotes[WarehouseID] || {};
                const currWhFt = currWh[freightType] || {};

                acc[WarehouseID] = { ...currWh, [freightType]: { ...currWhFt, requested: true } };
                return acc;
            }, {});
            dispatch(
                actions.getFreightQuoteSent({ freightType, freightPayload: { ...warehouseQuotes } })
            );

            let response;
            const json = await fetchFreightQuote(freightType, signal)
                .then((res) => {
                    response = res;
                    return res.json();
                })
                .catch((err) => {
                    const obj = typeof err === 'string' ? { error: err.toString() } : err;
                    response = obj;
                    return obj;
                });
            const payload = { freightType, freightPayload: json };
            if (response.ok) {
                dispatch(actions.updateWarehouseQuoteLists(payload));
                return Promise.resolve(dispatch(actions.getFreightQuoteSuccess(payload)));
            }

            if (!isRetry) {
                dispatch(actions.getFreightQuoteFailure(payload));
                getFreightQuote(freightType, signal, true);
            }
            return Promise.reject(dispatch(actions.getFreightQuoteFailure(payload)));
        }
        return Promise.resolve();
    };
};

const resetFreight = () => {
    return async (dispatch, getState) => {
        if (shouldFetchFreight(selectShippingSlice(getState()))) {
            dispatch(actions.resetFreightSent({ reset: 'requested' }));
            let response;
            const json = await putResetFreight()
                .then((resp) => {
                    response = resp;
                    return resp.json();
                })
                .catch((error) => {
                    response = error;
                    return error.json();
                });
            if (response.ok) {
                return Promise.resolve(
                    dispatch(actions.resetFreightSuccess({ reset: true, ...json }))
                );
            }

            return Promise.reject(dispatch(actions.resetFreightFailure(json)));
        }
        return Promise.resolve();
    };
};

const setFreightOptionsLocal = (warehouseObject) => {
    return async (dispatch) => {
        await dispatch(actions.setFreightOptions(warehouseObject));
    };
};
const async = {
    getFreightQuote,
    getInitialFreightQuotes,
    setFreightOptionsLocal,
    resetFreight,
};
export default async;
export {
    getFreightQuote,
    freightTypes,
    setFreightOptionsLocal,
    resetFreight,
    getInitialFreightQuotes,
};
