const getToday = () => {
    const today = new Date();
    today.setHours(0);
    today.setMinutes(0);
    today.setSeconds(0);
    today.setMilliseconds(0);
    return today;
};
const getMonths = (locale = 'en') => {
    const today = getToday();
    return new Array(12)
        .fill()
        .map((_x, idx) => {
            return idx;
        })
        .map((m) => {
            const n = m + 1;

            const baseDate = new Date(today.getFullYear(), m, 1); //use first of month ... or else shenanigans

            const monthName = baseDate.toLocaleDateString(locale, {
                month: 'long',
            });
            const monthAbbr = baseDate.toLocaleDateString(locale, {
                month: 'short',
            });
            const monthNumberPadded = baseDate.toLocaleDateString(locale, {
                month: '2-digit',
            });
            const monthNarrow = baseDate.toLocaleDateString(locale, {
                month: 'narrow',
            });

            return {
                monthNumber: n,
                monthNumberPadded,
                monthName,
                monthAbbr,
                monthNarrow,
                baseDate,
            };
        });
};

const getThisMonth = () => {
    const today = getToday();
    const thisMonth = today.getMonth() + 1;
    return thisMonth;
};
const getThisYear = () => {
    const today = getToday();
    const thisYear = today.getFullYear();
    return thisYear;
};

export default getMonths;

export { getThisMonth, getThisYear, getToday };
