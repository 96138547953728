import { createSlice } from '@reduxjs/toolkit';
import { propsByActionType, APIACTIONS } from '../../helpers/apiShared';

const userProfileSlice = createSlice({
    name: 'userProfile',
    initialState: {},
    reducers: {
        changeCustIdSent: (state, action) => {
            const InvoiceAccount = action.payload;
            const fetchProps = propsByActionType(APIACTIONS.REQUEST);
            return { ...state, ...fetchProps, InvoiceAccount };
        },
        changeCustIdSuccess: (_state, action) => {
            const fetchProps = propsByActionType(APIACTIONS.SUCCESS);
            const newProfile = action.payload;
            return { ...newProfile, ...fetchProps };
        },
        changeCustIdFailure: (state, action) => {
            const fetchProps = propsByActionType(APIACTIONS.FAILURE);
            const InvoiceAccount = action.payload;
            return { ...state, ...fetchProps, InvoiceAccount };
        },
        setDealerList: (state, action) => {
            const DealerList = action.payload;
            return { ...state, DealerList };
        },
        changeDealerIdSent: (state, action) => {
            const fetchProps = propsByActionType(APIACTIONS.REQUEST);
            const ActiveDealerKey = action.payload;
            return { ...state, ...fetchProps, request: ActiveDealerKey };
        },
        changeDealerIdSuccess: (state, action) => {
            const fetchProps = propsByActionType(APIACTIONS.SUCCESS);

            return { ...state, ...fetchProps, ...action.payload };
        },
        changeDealerIdFailure: (state, action) => {
            const fetchProps = propsByActionType(APIACTIONS.FAILURE);
            const DealerId = action.payload;
            return { ...state, ...fetchProps, requestFailedFor: DealerId };
        },
        refreshProfileSent: (state, action) => {
            const fetchProps = propsByActionType(APIACTIONS.REQUEST);
            const payload = action.payload || {};
            return { ...state, ...fetchProps, ...payload };
        },
        refreshProfileSuccess: (state, action) => {
            const fetchProps = propsByActionType(APIACTIONS.SUCCESS);
            const { payload } = action;
            return { ...state, ...fetchProps, ...payload };
        },
        refreshProfileMenuSent: (state, action) => {
            const fetchProps = propsByActionType(APIACTIONS.REQUEST);
            const payload = action.payload || {};
            return { ...state, ...fetchProps, ...payload };
        },
        refreshProfileMenuSuccess: (state, action) => {
            const fetchProps = propsByActionType(APIACTIONS.SUCCESS);
            const { payload } = action;
            return { ...state, ...fetchProps, ...payload };
        },
        refreshProfileFailure: (state, action) => {
            const fetchProps = propsByActionType(APIACTIONS.FAILURE);
            const { payload } = action;
            return { ...state, ...fetchProps, ...payload };
        },
        resetProfile: (state, action) => {
            const fetchProps = propsByActionType(APIACTIONS.LOCALCHANGE);
            const { payload } = action;
            return { ...state, ...fetchProps, ...payload };
        },
    },
});
export const { actions, reducer } = userProfileSlice;
export const {
    changeCustIdSent,
    changeCustIdSuccess,
    changeCustIdFailure,
    changeDealerIdSuccess,
    changeDealerIdSent,
    changeDealerIdFailure,
} = actions;
export default reducer;
