import { createSlice } from '@reduxjs/toolkit';

const siteUtilsSlice = createSlice({
    name: 'siteUtils',
    initialState: {
        pageLoading: false,
    },
    reducers: {
        setPageLoading: (state, action) => {
            const { pageLoading } = action.payload;
            return { ...state, pageLoading };
        },
    },
});

export const { reducer, actions } = siteUtilsSlice;
export default reducer;
