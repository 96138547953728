import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PVSlider from '../../Shared/Carousel/PVSlider';
import PVIndustryLinkBar from '../../Shared/Content/PVIndustryLinkBar';
//import PVPagePost from '../../Shared/ContentContainers/PVPagePost';
//import PVProductFamilyBar from '../../Shared/Content/PVProductFamilyBar';
import cmsApi from '../../../helpers/cmsApi';


class PVMainGlobal extends React.Component {
        state = { mainSlideContent: [], mainBody: [] };

        componentDidMount() {
            const response = cmsApi.get('landingpage-global', {
            }).then(response => {
                this.setState({
                    mainSlideContent: response.data.items[0].data.mainSlideContent.iv,
                    mainBody: response.data.items[0].data.body.iv,
					BannerHeading: response.data.items[0].data.BannerHeading.iv,
					BannerParagraph: response.data.items[0].data.BannerParagraph.iv,
                });
            })

        }
        render() {
            return (
							<React.Fragment>
								<div className="block-section-1">
									<div className="main-slide slide-opt-1">
										<PVSlider slideAmt={1}
												  slideType={1}
												  slideStyle="nav-style1"
												  slideContent={this.state.mainSlideContent}
												  slideClass="pv-GlobalHeader"
												  showSlideNav/>
									</div>
								</div>
								<div className="pv-black-divider"/>
								<div className="pv-new-page-content">

									<div className="container">
										<div className="home-page-text">
											<span className="home-page-text-center">{this.state.BannerHeading}</span>
											<p>{this.state.BannerParagraph}	</p>
										</div>
										<PVIndustryLinkBar/>
	
								</div>
											<div className="pv-new-page-contentdiv"/>
								</div>

							</React.Fragment>
        )
    };
};

export default PVMainGlobal;