import React, { Suspense } from 'react';
import { AlertType, removeAlertByType } from '../../../redux/alerts';

const routeChangeComponent = (WrappedComponent) => {
    class RouteChangeComponent extends React.Component {
        componentWillUnmount() {
            removeAlertByType(AlertType.VALIDATION);
            removeAlertByType(AlertType.PAGE);
        }

        render() {
            const { props } = this;
            return (
                <Suspense fallback={<div className="container">Loading...</div>}>
                    <WrappedComponent {...props} />
                </Suspense>
            );
        }
    }

    return RouteChangeComponent;
};

export default routeChangeComponent;
