import React from 'react';
import PropTypes from 'prop-types';
import PVBaseModal from './PVBaseModal';

const PVModalDialog = (props) => {
    const { headerContent, bodyContent, footerContent, ...modalProps } = props;
    const contentMissing = (contentObj) => {
        const objtype = typeof contentObj;
        const ret =
            objtype === 'undefined' ||
            objtype === 'null' ||
            (objtype === 'string' && contentObj.length === 0) ||
            (Array.isArray(contentObj) && contentObj.length === 0) ||
            (objtype === 'object' && Object.keys(contentObj).length === 0);
        return ret;
    };
    const bodyMissing = contentMissing(bodyContent);
    const footerMissing = contentMissing(footerContent);
    const headerMissing = contentMissing(headerContent);
    const header = typeof headerContent === 'string' ? <h6>{headerContent}</h6> : { headerContent };

    return (
        <PVBaseModal {...modalProps}>
            <div className="modal-content">
                {!headerMissing && <div className="modal-header">{header}</div>}
                {!bodyMissing && <div className="modal-body">{bodyContent}</div>}
                {!footerMissing && <div className="modal-footer">{footerContent}</div>}
            </div>
        </PVBaseModal>
    );
};

PVModalDialog.propTypes = {
    bodyContent: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
    headerContent: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
    footerContent: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
};
PVModalDialog.defaultProps = { headerContent: '', bodyContent: '', footerContent: '' };

export default PVModalDialog;
