import React from 'react';
import { LoginLink } from '../../../helpers/siteConfigConstants';

const data = {
    '404': {
        bgClass: 'pv404-bg-image',
        headlineTop: 'Loose Connection?',
        paragraphs: [
            { key: 0, text: 'The page you are looking for could not be found.' },
            {
                key: 1,
                text:
                    'The link to this address may be outdated or we may have moved the page since you last bookmarked it.',
            },
            { key: 2, text: 'It may also be temporarily unavailable.' },
        ],
        headlineBottom: '404 NOT FOUND',
        linkButton: {
            text: 'Shop Now',
            linkTo: '/',
            btnClass: 'pv btn btn-primary shop-now',
        },
    },
    '403': {
        bgClass: 'pv403-bg-image',
        headlineTop: "You can't go in there",
        paragraphs: [
            {
                key: 0,
                text:
                    'You do not have permission to open this page. If you need assistance please contact us at 1.800.205.8620',
            },
        ],
        headlineBottom: '403 Access Denied',
        linkButton: {
            text: 'Home',
            linkTo: '/',
            btnClass: 'pv btn btn-primary shop-now',
        },
    },
    '401': {
        bgClass: 'pv401-bg-image',
        headlineTop: 'Who are you?',
        components: [
            {
                key: 0,
                component: (
                    <div>
                        Please sign in with your PerfectVision account to view this page. If you are
                        not a PerfectVision customer, you can&nbsp;
                        <a href="/CreateAccount/CreateAccount.aspx">register for an account here</a>
                    </div>
                ),
            },
        ],
        headlineBottom: '401 Authentication Required',
        linkButton: {
            text: 'Sign In',
            linkTo: LoginLink,
            btnClass: 'pv btn btn-primary shop-now',
        },
    },
    '500': {
        bgClass: 'pv500-bg-image',
        showLogo: true,
        headlineTop: 'NO SIGNAL?',
        paragraphs: [
            {
                key: 0,
                text:
                    'The server encountered an internal error or misconfiguration and was unable to complete your request. Please try again later.',
            },
        ],
        headlineBottom: '500 Server Error',
    },
};

export default data;
