import React from 'react';

const PVBlockSearchPlaceHolder = () => {
    return (
        <React.Fragment>
            <form id="block-search-mobile" method="get" className="block-search-mobile">
                <div className="form-content">
                    <div className="control">
                        <a href="/" className="close-block-search">
                            <span className="icon fa fa-times" />
                        </a>
                        <input
                            type="text"
                            name="search"
                            placeholder="Search"
                            className="input-subscribe"
                        />
                        <button type="submit" className="btn search">
                            <span>
                                <i className="fa fa-search" aria-hidden="true" />
                            </span>
                        </button>
                    </div>
                </div>
            </form>
            <div id="block-quick-view-popup" className="block-quick-view-popup">
                <div className="quick-view-content">
                    <a href="/" className="popup-btn-close">
                        <i className="fa fa-times" aria-hidden="true" />
                    </a>
                    <div className="slide-quick">
                        <div className="product-thumbs">
                            {/* eslint-disable prettier/prettier */}
                            {/* <a href="/" className="img-product-thumb">
                                <img src="/WebSupport/images/s-pop1.jpg" alt="p1" />
                            </a>
                            <a href="/" className="img-product-thumb">
                                <img src="/WebSupport/images/s-pop2.jpg" alt="p1" />
                            </a>
                            <a href="/" className="img-product-thumb">
                                <img src="/WebSupport/images/s-pop3.jpg" alt="p1" />
                            </a>
                            <a href="/" className="img-product-thumb">
                                <img src="/WebSupport/images/s-pop4.jpg" alt="p1" />
                            </a>*/}
                            {/* eslint-enable prettier/prettier */}
                        </div>
                    </div>
                    <div className="product-items">
                        <div className="product-image">
                            {/* eslint-disable prettier/prettier */}
                            {/*
                            <a href="/">
                                <img src="/WebSupport/images/popup-pro.jpg" alt="p1" />
                            </a>
                            */}
                            {/* eslint-enable prettier/prettier */}
                        </div>
                        <div className="product-info">
                            <div className="product-name">
                                <a href="/">Acer&apos;s Aspire S7 is a thin and portable laptop</a>
                            </div>
                            <span className="star-rating">
                                <i className="fa fa-star" aria-hidden="true" />
                                <i className="fa fa-star" aria-hidden="true" />
                                <i className="fa fa-star" aria-hidden="true" />
                                <i className="fa fa-star" aria-hidden="true" />
                                <i className="fa fa-star" aria-hidden="true" />
                                <span className="review">5 Review(s)</span>
                            </span>
                            <a href="/" className="wishlist">
                                <i className="fa fa-heart-o" aria-hidden="true" />
                                Add to Wishlist
                            </a>
                            <div className="product-information">
                                Description Our new HPB12 / A12 battery is rated at 2000mAh and
                                designed to power up Black and Decker FireStorm line of 12V tools
                                allowing...
                            </div>
                        </div>
                        <div className="product-info-price">
                            <span className="price">
                                <ins>$229.00</ins>
                                <del>$259.00</del>
                            </span>
                            <div className="quantity">
                                <h6 className="quantity-title">Quantity:</h6>
                                <div className="buttons-added">
                                    <input
                                        type="text"
                                        defaultValue={1}
                                        title="Qty"
                                        className="input-text qty text"
                                        size={1}
                                    />
                                    <a href="/" className="sign plus">
                                        <i className="fa fa-plus" />
                                    </a>
                                    <a href="/" className="sign minus">
                                        <i className="fa fa-minus" />
                                    </a>
                                </div>
                            </div>
                            <a href="/" className="btn-add-to-cart">
                                Add to cart
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default PVBlockSearchPlaceHolder;
