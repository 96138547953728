import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useLocation, useHistory } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';
import PVMiniCart from './PVMiniCart';
import PVHamburger from './PVMenuBar';
import PVLogo from './PVLogo';
import PVSearchAutoSuggest from './PVSearchAutoSuggest';
import { selectTopLevelCategories } from '../../../../redux/products/category/selectors';
import { selectLoginStatus } from '../../../../redux/userProfile/selectors';
import { LoginLink } from '../../../../helpers/siteConfigConstants';

const PVSearchBar = ({ showCart, categoryList, isLoggedIn }) => {
    const location = useLocation();
    const history = useHistory();
    //const searchBoxStyle = showCart ? 'col-md-7 nav-mind' : 'col-md-9 nav-mind';
    const isMounted = useRef(null);

    const [pcID, setPcID] = useState('0');
    const [search, setSearch] = useState('');
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
        };
    }, []);

    const [handleResize] = useDebouncedCallback(() => {
        setScreenWidth(window.innerWidth);
    }, 600);

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return (_) => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const getParamValueFromURL = (paramName) => {
        if (location.search === '') {
            return '';
        }
        const queryString = location.search.split('?')[1];
        const keyValuePairs = queryString.split('&');
        let paramValue = '';
        keyValuePairs.map((keyValuePair) => {
            const pairParts = keyValuePair.split('=');
            const key = pairParts[0];
            const value = decodeURIComponent(pairParts[1]);
            if (key === paramName) {
                paramValue = value;
            }
            return null;
        });
        return paramValue;
    };

    useEffect(() => {
        const urlSearch = getParamValueFromURL('search');
        const urlPcID = getParamValueFromURL('pcID');
        setSearch(urlSearch);
        //document.getElementById('pv-search-text').value = urlSearch;
        setPcID(urlPcID);
    }, [location.search]);

    const buildQueryString = (newPcID, newSearch) => {
        let queryString = '';

        if (newPcID.length > 0 && newPcID !== '0' && newSearch.trim().length > 0) {
            queryString = `pcID=${newPcID}&search=${newSearch.trim()}`;
        } else if (newSearch.trim().length > 0) {
            queryString = `search=${newSearch.trim()}`;
        } else if (newPcID.length > 0 && newPcID !== '0') {
            queryString = `pcID=${newPcID}`;
        }

        return queryString;
    };

    const handleOnChangeCombo = (e) => {
        const newPcID = e.currentTarget.selectedOptions[0].value;
        setPcID(newPcID);
        //setSearch('');
        //const queryString = buildQueryString(newPcID, '');
        //history.push(`/Products?${queryString}`);
    };

    const handleOnClick = (e) => {
        const newPcID = document.getElementById('pv-search-combo').selectedOptions[0].value;
        const newSearch = document.getElementById('pv-search-text').value.trim();
        const queryString = buildQueryString(newPcID, newSearch);
        history.push(`/Products?${queryString}`);
        e.preventDefault();
    };

    const renderCategories = () => {
        if (categoryList === null || categoryList.length === 0) {
            return null;
        }

        return (
            <React.Fragment>
                {categoryList.map((category, i) => (
                    <option key={category.Id || i} value={category.Id}>
                        {category.DisplayName}
                    </option>
                ))}
            </React.Fragment>
        );
    };

    return (
        <div className="header-content">
            <div className="container">
                <div className="row">
                    <div className="col-xs-2 col-sm-1 col-md-1 hidden-lg pv-hamburger-wrapper">
                        {screenWidth > 1199 ? '' : <PVHamburger />}
                    </div>
                    <div className="hidden-xs col-xs-4 col-sm-4 col-md-3 nav-left">
                        <PVLogo />
                    </div>
                    {/* search */}
                    <div id="searchWrapper" className="col-xs-7 col-sm-5 col-md-6 col-lg-6">
                        <div className="input-group">
                            <div className="input-group-btn hidden-xs hidden-sm">
                                <div id="pv-search-combo-wrapper">
                                    <select
                                        id="pv-search-combo"
                                        data-placeholder="All Products"
                                        onChange={handleOnChangeCombo}
                                        value={pcID}
                                    >
                                        <option value="0">All Products</option>
                                        {renderCategories()}
                                    </select>
                                </div>
                            </div>
                            <PVSearchAutoSuggest id="pv-search-text" startValue={search} />
                            <div className="input-group-btn">
                                <button
                                    id="pv-search-button"
                                    className="btn btn-default"
                                    type="button"
                                    onClick={handleOnClick}
                                >
                                    <span> Search</span>
                                    <i className="fa fa-search" aria-hidden="true" />
                                </button>
                            </div>
                        </div>
                    </div>
                    {/* end search */}
                    <div className="col-xs-1 col-sm-1 hidden-md hidden-lg">
                        {isLoggedIn ? (
                            <div className="pv-header-account-icon pv-account-tooltip">
                                <i className="fa fa-user" />
                                <div className="tooltip-content">
                                    <a
                                        href="/MyProfile/UserManagement/UserManagement.aspx"
                                        className="pv btn btn-primary"
                                    >
                                        My Profile
                                    </a>
                                    <a href="/Logout.aspx" className="pv btn btn-primary">
                                        Sign Out
                                    </a>
                                </div>
                            </div>
                        ) : (
                            <a className="pv-header-account-icon" href={LoginLink}>
                                <i className="fa fa-user" />
                            </a>
                        )}
                    </div>
                    {showCart ? (
                        <div className="col-xs-1 col-sm-1 col-md-2 col-lg-3">
                            <PVMiniCart />
                        </div>
                    ) : (
                        ''
                    )}
                </div>
            </div>
        </div>
    );
};

PVSearchBar.propTypes = {
    showCart: PropTypes.bool,
    categoryList: PropTypes.arrayOf(PropTypes.object).isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
};

PVSearchBar.defaultProps = {
    showCart: true,
};

const mapStateToProps = (state) => {
    return {
        categoryList: selectTopLevelCategories(state),
        isLoggedIn: selectLoginStatus(state),
    };
};
export default connect(mapStateToProps)(PVSearchBar);
