function createHTMLText(incomingText) {
    if (incomingText !== undefined && incomingText !== null) {
        return { __html: `${incomingText}` };
    } else {
        return { __html: `` };
    }
}
const parseUrl = (url) => {
    const a = document.createElement('a');
    a.href = url;
    return a.pathname;
};
const parseQueryString = (locationObj, str = '') => {
    let toParse = locationObj && locationObj.search ? locationObj.search : str;

    if (toParse && toParse.length > 0) {
        toParse = toParse.split('?').length > 1 ? toParse.split('?')[1] : toParse;
    }
    const paramStrings = toParse.split('&').reduce((resultObj, current) => {
        const split = current.split('=');
        if (split.length === 2) {
            return { ...resultObj, [split[0]]: split[1] };
        }
        return resultObj;
    }, {});
    return paramStrings;
};
const truncateString = (
    incomingStr = '',
    minChar = 10,
    maxChar = 200,
    positionArray = [' '],
    prioritzePositions = false,
    bailOnHtml = false
) => {
    const truncated = false;
    const origStr = (incomingStr || '').trim();
    let str = origStr;
    const min = str.length < minChar ? str.length : minChar;
    if (str.length <= maxChar) {
        return { str, truncated };
    }
    str = str.substr(0, maxChar);
    const positions =
        positionArray
            .reduce((arr, c) => {
                return [...arr, str.lastIndexOf(c)];
            }, [])
            .filter((pos) => pos > min)
            .sort((a, b) => {
                if (prioritzePositions) {
                    return 1;
                }
                return b - a;
            }) || [];
    str = str.substring(0, positions[0] || maxChar);

    /*now we need to handle embedded html
    right now we are going to just crop at the first '<' instance.*/
    const hasHtmlResult = str.indexOf('</') > -1 || str.indexOf('/>') > -1;
    if (hasHtmlResult && !bailOnHtml) {
        str = origStr.substr(0, origStr.indexOf('<') - 1);
    } else if (hasHtmlResult) {
        str = origStr;
    }
    return { str, truncated: str.length < origStr.length };
};

const getPriceString = (num) => {
    if (num) {
        return num.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    }
    if (num === 0) {
        return num.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    }
    return '';
};

const phoneNumberFormatTypes = {
    PARENS: '($1) $2-$3',
    DOTS: '$1.$2.$3',
    DASHES: '$1-$2-$3',
};
const formatPhoneNumber = (str, format = phoneNumberFormatTypes.DASHES) => {
    const val = str || '';
    const txPhFormat = new RegExp(/(\d{3})(\d{3})(\d{4})/);
    const cleaned = val.toString().replace(/[^\d]/g, '').toString();

    const formatted = cleaned.replace(txPhFormat, format);
    return cleaned.length === 10 ? formatted : val;
};

const cleanPhoneNumber = (phone) => {
    let cleanPhone = phone
        .replace(/\./g, '')
        .replace(/-/g, '')
        .replace(/\s/g, '')
        .replace(/\(/g, '')
        .replace(/\)/g, '');
    const regexE = /e/i;
    const regexX = /x/i;
    let i = cleanPhone.search(regexE);
    if (i > 0) {
        cleanPhone = cleanPhone.substring(0, i);
    }
    i = cleanPhone.search(regexX);
    if (i > 0) {
        cleanPhone = cleanPhone.substring(0, i);
    }
    return cleanPhone;
};

export {
    parseUrl,
    parseQueryString,
    createHTMLText,
    truncateString,
    getPriceString,
    phoneNumberFormatTypes,
    formatPhoneNumber,
    cleanPhoneNumber,
};

export default createHTMLText;
