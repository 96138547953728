import { AuthHeader } from '../../helpers/siteConfigConstants';

const webSupportBase = '/WebSupport/PV_React';

const fetchJson = (websupportPath) => {
    const path = (websupportPath || '').trim().toLowerCase();
    if (path.endsWith('.json')) {
        const cleanedPath = path.startsWith('/') ? path.substring(1, path.length - 1) : path;
        const webSupportUri = `${webSupportBase}/${cleanedPath}`;
        return fetch(webSupportUri, {
            method: 'GET',
            headers: { Authorization: AuthHeader },
            cache: 'no-cache',
        });
    }

    return {};
};

export default fetchJson;
