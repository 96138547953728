import { WebServiceHosts, AuthHeader } from '../../helpers/siteConfigConstants';
import { shouldFetch, ABORTREASONS } from '../../helpers/apiShared';

export const freightTypes = {
    STANDARD: 'standard',
    EXPEDITED: 'expedited',
    LTL: 'ltl',
};
const baseUri = `${WebServiceHosts.WebStore}/api/checkout`;
const freightUri = `${baseUri}/freight`;
const getOptions = { headers: { Authorization: AuthHeader }, method: 'GET', cache: 'no-cache' };

export const fetchFreightQuote = (freightType = freightTypes.STANDARD, signal) => {
    const uri = `${freightUri}/quote/${freightType.toString()}`;
    const freightQuoteOptions = signal ? { ...getOptions, signal } : getOptions;
    return fetch(uri, freightQuoteOptions);
};

export const fetchInitialQuotes = (booGetStandard = true, booGetLtl = true) => {
    const uri = `${freightUri}/quote?getStandardQuoteRates=${booGetStandard}&getLTLQuoteRates=${booGetLtl}`;
    return fetch(uri, getOptions);
};

export const putResetFreight = () => {
    const uri = `${freightUri}/reset`;
    const options = { ...getOptions, method: 'PUT' };
    return fetch(uri, options);
};
export const shouldFetchFreight = (freightSubslice, delayMs = 200) => {
    const status = shouldFetch(freightSubslice.isFetching, freightSubslice.receivedAt, delayMs);
    return status === ABORTREASONS.NO_ABORT;
};
