import { WebServiceHosts, AuthHeader } from '../../helpers/siteConfigConstants';
import { shouldFetch, ABORTREASONS } from '../../helpers/apiShared';

const baseUri = `${WebServiceHosts.WebStore}/api/checkout/addresses`;
const fetchOpts = { headers: { Authorization: AuthHeader }, cache: 'no-cache' };

const fetchLastAddress = () => {
    const lastAddressUri = `${baseUri}/last`;
    const opts = { ...fetchOpts, method: 'GET' };
    return fetch(lastAddressUri, opts);
};
const fetchSaved = () => {
    const savedUri = `${baseUri}`;
    const opts = { ...fetchOpts, method: 'GET' };
    return fetch(savedUri, opts);
};

const fetchCityState = (zip) => {
    const zipUri = `${baseUri}/zip/${zip}`;
    return fetch(zipUri, { ...fetchOpts, method: 'GET' });
};
const putAddress = (address, id) => {
    const putUri = `${baseUri}/${id}`;
    const putOpts = {
        headers: { Authorization: AuthHeader, 'Content-Type': 'application/json' },
        cache: 'no-cache',
        method: 'PUT',
        body: JSON.stringify(address),
    };
    return fetch(putUri, putOpts);
};
const postNew = (address) => {
    const postUri = `${baseUri}`;
    const postOpts = {
        headers: { Authorization: AuthHeader, 'Content-Type': 'application/json' },
        cache: 'no-cache',
        method: 'POST',
        body: JSON.stringify(address),
    };
    return fetch(postUri, postOpts);
};
const postValidate = (address) => {
    const validateUri = `${baseUri}/validate/true`;
    const postOpts = {
        headers: { Authorization: AuthHeader, 'Content-Type': 'application/json' },
        cache: 'no-cache',
        method: 'POST',
        body: JSON.stringify(address),
    };
    return fetch(validateUri, postOpts);
};
const fetchPickupLocations = () => {
    const uri = `${baseUri}/pickuplocations`;
    return fetch(uri, fetchOpts);
};

const shouldFetchAddress = (stateSlice, delayMs = 500) => {
    let booFetch = false;
    if (!stateSlice) {
        return true;
    }
    const fetchStatus = shouldFetch(stateSlice.isFetching, stateSlice.receivedAt, delayMs);
    booFetch = fetchStatus === ABORTREASONS.NO_ABORT;
    return booFetch;
};

export {
    fetchLastAddress,
    fetchSaved,
    fetchCityState,
    fetchPickupLocations,
    putAddress,
    postNew,
    postValidate,
    shouldFetchAddress,
};
