import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
/*this is a higher-order function to wrap an item (any item!) and have it expire after n seconds*/

function PVExpire({ children, expiresMs, onComplete, hidden }) {
    const [visible, setVisible] = useState(true);
    const isMounted = useRef();

    const setInvisible = () => {
        onComplete();
        if (isMounted.current) setVisible(false);
    };

    useEffect(() => {
        isMounted.current = true;

        return () => {
            isMounted.current = false;
        };
    }, []);

    useEffect(() => {
        let timer;
        if (children && expiresMs > 0) {
            timer = setTimeout(setInvisible, expiresMs);
        }
        return () => clearTimeout(timer);
    }, []);

    return <React.Fragment>{visible && !hidden && <div>{children}</div>}</React.Fragment>;
}
PVExpire.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    expiresMs: PropTypes.number,
    onComplete: PropTypes.func,
    hidden: PropTypes.bool,
};

PVExpire.defaultProps = {
    children: <span />,
    expiresMs: 0,
    onComplete: () => {},
    hidden: false,
};

export default PVExpire;
