import { WebServiceHosts, AuthHeader } from '../../helpers/siteConfigConstants';

const accountControllerUri = `${WebServiceHosts.WebStore}/api/account`;
const setDealerId = (DealerId) => {
    const setDealerUrl = `${accountControllerUri}/dealerId/${DealerId}`;
    return fetch(setDealerUrl, {
        headers: {
            Authorization: AuthHeader,
        },
        method: 'PATCH',
        cache: 'no-cache',
    });
};

const setInvoiceAccount = (invoiceAccount) => {
    const setInvAccountUrl = `${accountControllerUri}/invoiceAccount/${invoiceAccount}`;
    return fetch(setInvAccountUrl, {
        headers: { Authorization: AuthHeader },
        method: 'PATCH',
        cache: 'no-cache',
    });
};
const fetchProfile = () => {
    const fetchProfileUri = `${accountControllerUri}/profile`;
    return fetch(fetchProfileUri, {
        headers: { Authorization: AuthHeader },
        method: 'GET',
        cache: 'no-cache',
    });
};

export { setDealerId, setInvoiceAccount, fetchProfile };
