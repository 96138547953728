import React from 'react';
import PropTypes from 'prop-types';
import PVExpire from './PVExpire';
import { createHTMLText } from '../../../helpers/textFunctions';

// import alertType from '../../../redux/alerts/alertSlice';

const PVAlert = (props) => {
    const { displayType, expiresMs, dismissable, message } = props;
    const classProp = `alert alert-${displayType.toLowerCase()}`;

    const alertDiv = (
        <div className={classProp}>
            {dismissable ? (
                <button
                    style={{ cursor: 'pointer' }}
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            ) : (
                ''
            )}
            {/* eslint-disable-next-line react/no-danger  */}
            <span dangerouslySetInnerHTML={createHTMLText(message)} />
        </div>
    );

    return <PVExpire expiresMs={expiresMs}> {alertDiv} </PVExpire>;
};

PVAlert.propTypes = {
    expiresMs: PropTypes.number,
    dismissable: PropTypes.bool,
    message: PropTypes.string,
    displayType: PropTypes.string.isRequired,
};
PVAlert.defaultProps = { expiresMs: 0, dismissable: true, message: '' };

export default PVAlert;
