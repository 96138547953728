/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';

import { SiteConfig } from '../../../../helpers/initSite';

/*eslint no-script-url: "off"*/



const PVFooterNortonSecured = () => {
    const geotruststyle = {
        color: '#000000',
        textDecoration: 'none',
        font: 'bold 10px verdana, sans-serif',
        letterSpacing: '.5px',
        textAlign: 'center',
        margin: '0px',
        padding: '0px',
    };
    //var script = document.createElement("script");
    //script.src = "NortonScript.js";
    //script.async = true;
    //document.body.appendChild(script)
           
    return (
        <React.Fragment>
            <table
                width="135"
                border="0"
                cellPadding="2"
                cellSpacing="0"
                title="Click to Verify - This site chose DigiCert SSL for secure e-commerce and confidential communications."
            >
                <tbody>
                    <tr>

                     {/*  DigiCert Seal Code Ticket# 7328 (2 of 2)  */}
                    <td width="135" align="center" valign="top">
                            <div id="DigiCertClickID_Tz51rlXg"></div>
                           
                            <a href="https://www.digicert.com/what-is-ssl-tls-https/" target="_blank" style={geotruststyle}> How SSL Secures You</a>
                     </td>
                 </tr>
                 </tbody>
            </table>
         </React.Fragment>
    );
};

export default PVFooterNortonSecured;
