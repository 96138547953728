import { PVPaymentTypeEnum } from '../../../components/PVWebstore/PropTypes/PVPropsPaymentInfo';

const buildPaymentRadioArray = (booUseTerms, achAccounts, achHasPin, booCpu, booCrossShip) => {
    return [
        {
            sortOrder: 0,
            value: PVPaymentTypeEnum.ACH,
            rdId: 'rdAch',
            label: 'ACH',
            objKey: 'ACH',
            enabled: achAccounts && achAccounts.length > 0 && achHasPin,
            default: false,
            action: () => {},
            ready: true,
            hidden: !achHasPin && achAccounts && achAccounts.length > 0,
        },
        {
            sortOrder: 1,
            value: PVPaymentTypeEnum.CO_Check,
            rdId: 'rdTerms',
            label: 'Payment Terms',
            objKey: 'CO_Check',
            enabled: booUseTerms,
            default: false,
            action: () => {},
            ready: true,
            hidden: false,
        },
        {
            sortOrder: 2,
            value: PVPaymentTypeEnum.Cash,
            rdId: 'rdCashAtPickup',
            label: 'Cash at Pickup',
            objKey: 'Cash',
            enabled: booCpu,
            default: false,
            action: () => {},
            ready: true,
            hidden: !booCpu,
        },
        {
            sortOrder: 3,
            value: PVPaymentTypeEnum.CreditCard,
            rdId: 'rdCreditCard',
            label: 'Credit Card',
            objKey: 'CreditCard',
            enabled: true,
            default: false,
            action: () => {},
            ready: true,
            hidden: false,
        },
        {
            sortOrder: 4,
            value: PVPaymentTypeEnum.PayPal,
            rdId: 'rdPayPal',
            label: 'PayPal',
            objKey: 'PayPal',
            enabled: !booCrossShip,
            default: true,
            action: () => {},
            ready: true,
            hidden: false,
        },
    ];
};
const getPaymentTypeName = (paymentTypeId) => {
    const searchType = (paymentTypeId || '').toString();
    const typeArray = Object.keys(PVPaymentTypeEnum).reduce((arr, pte) => {
        return [...arr, { type: pte, value: PVPaymentTypeEnum[pte].toString() }];
    }, []);
    const foundType = typeArray.find((x) => x.value === searchType) || {};
    return foundType.type || '';
};

const createNewCardSubmissionObject = (values, initialAddress, dealerInfo, cardType) => {
    const {
        ContactName,
        Street,
        Phone,
        ZipCode,
        City,
        State,
        Email,
        SecurityCode,
        ExpirationMonth,
        ExpirationYear,
        NameOnCard,
        OrderReference,
        CreditCardNumber,
    } = values;
    const addressBody = {
        ...initialAddress,
        Edited: true,
        LookUpName: ContactName,
        BusinessName: dealerInfo.InvBizName || ContactName,
        ContactName,
        Street,
        Phone,
        ZipCode,
        City,
        State,
        Email,
    };
    const creditCardObj = {
        CreditCardID: 0,
        CreditCardType: cardType,
        CreditCardNumber,
        SecurityCode,
        ExpirationMonth,
        ExpirationYear,
        NameOnCard,
        OrderReference,
        BillToStreet: Street,
        BillToZip: ZipCode,
        BillToCity: City,
        BillToState: State,
        Address: addressBody,
    };
    return creditCardObj;
};

export { getPaymentTypeName, buildPaymentRadioArray, createNewCardSubmissionObject };
