import delve from 'dlv';
import { actions } from './userProfileSlice';
import { actions as categoryActions } from '../products/category/categorySlice';
import { actions as menuActions } from '../menus/menusSlice';
import * as api from './api';
import { shouldFetchForSlice } from '../../helpers/apiShared';
import { getCart } from '../cart/actionsAsync';
import { invalidateItems } from '../products/items/itemsSlice';
import { actions as featuredActions } from '../products/featured/featuredSlice';
import { actions as orderHistoryActions } from '../orderhistory/orderedSlice';
import extractInitStateObject from '../../helpers/initSiteConfig';
import {
    selectInvoiceAccount,
    selectUserProfile,
    selectActiveDealerIdForAccount,
    selectSiteId,
    selectDealerInfoByKey,
} from './selectors';
import { invalidateCheckout } from '../checkout-selections/actionsAsync';

const resetData = async (dispatch) => {
    await Promise.all([
        (async () => {
            await dispatch(invalidateCheckout());
            dispatch(orderHistoryActions.invalidateOrders());
            dispatch(getCart());
        })(),
        (async () => {
            dispatch(invalidateItems());
            dispatch(categoryActions.invalidateSubcategoryProducts());
            dispatch(featuredActions.invalidateFeatured());
        })(),
    ]);
};
const changeDealerId = (dealerKey) => {
    return async (dispatch, getState) => {
        const initialState = selectUserProfile(getState());
        const doFetch = shouldFetchForSlice(initialState);

        if (doFetch) {
            dispatch(actions.changeDealerIdSent(dealerKey));
            await api.setDealerId(dealerKey).then((response) => {
                if (response.status === 200) {
                    resetData(dispatch);
                    const dealerInfo = selectDealerInfoByKey(getState(), dealerKey);

                    const { DealerActID, ID } = dealerInfo;
                    return dispatch(
                        actions.changeDealerIdSuccess({
                            DealerId: DealerActID,
                            ActiveDealerKey: ID,
                        })
                    );
                }
                return Promise.reject(dispatch(actions.changeDealerIdFailure(dealerKey)));
            });
        }
        return Promise.resolve();
    };
};
const changeInvoiceAccount = (custId) => {
    return async (dispatch, getState) => {
        const state = getState();
        const initialState = selectUserProfile(state);
        const initialSiteId = selectSiteId(state);
        const initialInvAccount = selectInvoiceAccount(initialState);
        const doFetch = shouldFetchForSlice(initialState);
        const trimmedId = (custId || '').trim();
        if (doFetch && trimmedId.length > 4) {
            dispatch(actions.changeCustIdSent(trimmedId));
            const response = await api.setInvoiceAccount(trimmedId);

            const json = await response.json();
            const newSiteId = delve(json, 'AccountInfo.SiteId', 10);
            const payload = extractInitStateObject(json);

            if (response.status === 200) {
                if (
                    newSiteId === initialSiteId &&
                    window.location.pathname.indexOf('checkout') === -1
                ) {
                    const { userProfile, menus, product } = payload;
                    await resetData(dispatch);
                    if (product) {
                        dispatch(categoryActions.setCategories(product.category));
                    }
                    dispatch(menuActions.replaceMenus(menus.topMenus));
                    return Promise.resolve(dispatch(actions.changeCustIdSuccess(userProfile)));
                }
                window.location.reload();
                return Promise.resolve();
            }

            dispatch(actions.changeCustIdFailure(initialInvAccount));
            dispatch(actions.resetProfile(initialState));
            return Promise.reject();
        }
        if (trimmedId.length <= 4) {
            return Promise.reject();
        }

        return Promise.resolve();
    };
};

const refreshProfile = (_sourcePage = '', delay = 250) => {
    return async (dispatch, getState) => {
        const state = getState();
        const initialState = selectUserProfile(state);
        const initialCustId = selectInvoiceAccount(state);
        const initDealerId = selectActiveDealerIdForAccount(state);
        const doFetch = shouldFetchForSlice(initialState, delay);
        if (doFetch) {
            dispatch(actions.refreshProfileSent({ req: 'requested' }));
            const response = await api.fetchProfile();
            const json = await response.json();
            if (response.status === 200) {
                const payload = extractInitStateObject(json);
                const { userProfile } = payload;
                if (
                    userProfile.InvoiceAccount !== initialCustId ||
                    userProfile.DealerId !== initDealerId
                ) {
                    resetData(dispatch);
                }
                return Promise.resolve(
                    dispatch(actions.refreshProfileSuccess(payload.userProfile))
                );
            }
            return Promise.reject(dispatch(actions.refreshProfileFailure(json)));
        }
        return Promise.resolve();
    };
};

const refreshProfileMenus = (_sourcePage = '', delay = 250) => {
    return async (dispatch, getState) => {
        const state = getState();
        const initialState = selectUserProfile(state);
        const doFetch = shouldFetchForSlice(initialState, delay);
        if (doFetch) {
            dispatch(actions.refreshProfileMenuSent({ req: 'requested' }));
            const response = await api.fetchProfile();
            const json = await response.json();
            if (response.status === 200) {
                const payload = extractInitStateObject(json);
                const { menus } = payload;
                dispatch(menuActions.replaceMenus(menus.topMenus));
                return Promise.resolve(
                    dispatch(actions.refreshProfileMenuSuccess(payload.userProfile))
                );
            }
            return Promise.reject(dispatch(actions.refreshMenuFailure(json)));
        }
        return Promise.resolve();
    };
};

export { changeDealerId, changeInvoiceAccount, refreshProfile, refreshProfileMenus };
