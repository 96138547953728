import { createSelector } from 'reselect';
import { selectItemsSlice } from '../items';
import * as nrml from '../../normalize';

const selectProductsSlice = (state) => {
    return (state || {}).products || {};
};

const selectCategorySlice = createSelector(selectProductsSlice, (products) => {
    return products.category || {};
});
const selectCategorySliceInvalid = createSelector(selectCategorySlice, (slice) => {
    return slice.invalid || false;
});
const selectCategoryInput = (
    state,
    opts = { parent: '', subcategory: '', page: 0, numProducts: 25 }
) => {
    return opts;
};
const selectCategory = createSelector(
    [selectCategorySlice, selectCategoryInput],
    (categorySlice, { parent }) => {
        return categorySlice[parent] || {};
    }
);
const selectCategoryReady = createSelector([selectCategory], (category) => {
    return category.isFetching === false && category.receivedAt > 0;
});
const selectSubcategory = createSelector(
    [selectCategorySlice, selectCategoryInput],
    (categorySlice, { parent, subcategory }) => {
        const slice = categorySlice || {};
        const parentCat = slice[parent] || {};
        return parentCat[subcategory] || {};
    }
);
const selectSubcategoryReady = createSelector([selectSubcategory], (subcategory) => {
    return subcategory.isFetching === false && subcategory.receivedAt > 0;
});
const selectTotalItemsForSubcategory = createSelector(selectSubcategory, (category) => {
    return category.TotalProducts;
});
const selectCategoryItemIds = createSelector(selectSubcategory, (category) => {
    return category.list || [];
});

const selectIndexedItems = createSelector(
    [selectCategoryItemIds, selectItemsSlice],
    (categoryIds, items) => {
        return nrml.getIndexedValues(items, categoryIds);
    }
);

const selectSubcategoryProducts = createSelector(selectIndexedItems, (idx) => {
    return Object.values(idx) || [];
});
const selectTopLevelCategories = createSelector(selectCategorySlice, (slice) => {
    const topLevel = Object.values(slice).filter((v) => {
        const { categoryName, DisplayName } = v;
        return categoryName != null && DisplayName != null;
    });
    return topLevel || [];
});
const selectIdInput = (_state, id) => {
    return id;
};
const selectTopLevelCategoryById = createSelector(
    [selectTopLevelCategories, selectIdInput],
    (categories, id) => {
        const foundCategory = categories.find((c) => c.Id.toString() === id.toString());
        return foundCategory || {};
    }
);

const selectors = {
    selectTopLevelCategoryById,
    selectProductsSlice,
    selectCategorySlice,
    selectCategory,
    selectSubcategory,
    selectSubcategoryProducts,
    selectTotalItemsForSubcategory,
    selectCategoryItemIds,
    selectCategorySliceInvalid,
    selectCategoryReady,
    selectSubcategoryReady,
    selectTopLevelCategories,
};
export {
    selectProductsSlice,
    selectCategorySlice,
    selectCategory,
    selectSubcategory,
    selectSubcategoryProducts,
    selectTotalItemsForSubcategory,
    selectors,
    selectCategorySliceInvalid,
    selectCategoryReady,
    selectSubcategoryReady,
    selectTopLevelCategories,
    selectTopLevelCategoryById,
};
export default selectCategorySlice;
