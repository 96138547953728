import { WebServiceHosts, AuthHeader } from '../../helpers/siteConfigConstants';
import { shouldFetch, ABORTREASONS } from '../../helpers/apiShared';

const baseUri = `${WebServiceHosts.WebStore}/api`;
const cartUrl = `${baseUri}/cart`;
const getItems = () => {
    const fetchCartItemsUrl = `${baseUri}/shoppingcarts`;
    return fetch(fetchCartItemsUrl, {
        headers: {
            Authorization: AuthHeader,
        },
        method: 'GET',
        cache: 'no-cache',
    });
};

const getCartWithRealTimeChecks = () => {
    const fetchCartItemsUrl = `${baseUri}/shoppingcarts/availability`;
    return fetch(fetchCartItemsUrl, {
        headers: {
            Authorization: AuthHeader,
        },
        method: 'GET',
        cache: 'no-cache',
    });
};

const postCartItems = (reqBody) => {
    const postCartItemsUrl = `${cartUrl}/add`;
    return fetch(postCartItemsUrl, {
        headers: {
            Authorization: AuthHeader,
            'Content-Type': 'application/json',
        },
        mode: 'cors',
        method: 'POST',
        cache: 'no-cache',
        body: JSON.stringify(reqBody),
    });
};
const postCanAddCartItems = (reqBody) => {
    const postCartItemsUrl = `${cartUrl}/canadd`;
    return fetch(postCartItemsUrl, {
        headers: {
            Authorization: AuthHeader,
            'Content-Type': 'application/json',
        },
        mode: 'cors',
        method: 'POST',
        cache: 'no-cache',
        body: JSON.stringify(reqBody),
    });
};
const putAddCartItem = (itemId, qty, controlName) => {
    const addCartItemsUrl = `${cartUrl}/item/add/${controlName}/${itemId}/${qty}`;
    return fetch(addCartItemsUrl, {
        headers: {
            Authorization: AuthHeader,
        },
        method: 'PUT',
        cache: 'no-cache',
    });
};

const updateItemQuantity = (productId, qty, controlName = 'webstore') => {
    const updateUrl = `${cartUrl}/item/update/${productId}/${qty}/${controlName}`;
    return fetch(updateUrl, {
        headers: {
            Authorization: AuthHeader,
        },
        method: 'POST',
        cache: 'no-cache',
    });
};

const delCartItem = (productId) => {
    const deleteCartItemUrl = `${cartUrl}/item/remove/${productId}`;
    return fetch(deleteCartItemUrl, {
        headers: {
            Authorization: AuthHeader,
        },
        method: 'POST',
        cache: 'no-cache',
    });
};

const clearCart = () => {
    const clearUri = `${cartUrl}/clear`;
    return fetch(clearUri, {
        headers: {
            Authorization: AuthHeader,
        },
        method: 'GET',
        cache: 'no-cache',
    });
};
const deleteWarehouseCarts = (warehouseIds) => {
    const delUri = `${baseUri}/shoppingcarts`;
    return fetch(delUri, {
        headers: {
            Authorization: AuthHeader,
            'Content-Type': 'application/json',
        },
        method: 'POST',
        cache: 'no-cache',
        body: JSON.stringify(warehouseIds),
    });
};

const shouldCallCartApi = (state, delayMs = 250) => {
    if (delayMs === 0) {
        return true;
    }
    let booFetch = true;
    if (!state || !state.cart === undefined) {
        booFetch = true;
    } else {
        const fetchStatus = shouldFetch(state.cart.isFetching, state.cart.receivedAt, delayMs);
        booFetch = fetchStatus === ABORTREASONS.NO_ABORT;
    }
    return booFetch;
};

export {
    getItems,
    getCartWithRealTimeChecks,
    putAddCartItem,
    updateItemQuantity,
    delCartItem,
    shouldCallCartApi,
    clearCart,
    postCartItems,
    postCanAddCartItems,
    deleteWarehouseCarts,
};
