import { actions, reducer } from './itemsSlice';
import selectors, { selectItemsSlice, selectItemId } from './selectors';
import { getItemWithApiFallback, getItem } from './actionsAsync';

const { addStoreItems } = actions;
const items = {
    actions,
    reducer,
    selectors,
    addStoreItems,
    selectItemsSlice,
    selectItemId,
    getItemWithApiFallback,
};
export {
    actions,
    reducer,
    selectors,
    addStoreItems,
    selectItemsSlice,
    getItem,
    selectItemId,
    getItemWithApiFallback,
};
export default items;
