import * as Yup from 'yup';
import USAStates from '../../json';
import { convertDelimeters, textDelimeterEnum } from '../../convertDelimeters';
//import { yupToFormErrors } from 'formik';

const contactName = Yup.string()
    .min(2, 'Contact Name must be at least 2 characters long.')
    .max(30, 'Contact Name must be no longer than 30 characters.');
const contactNameReq = contactName.required('Contact Name is Required');
const emailStringAsArray = Yup.array().transform((value, originalValue) => {
    if (Array.isArray(value)) {
        return value;
    }
    const convertedVal = convertDelimeters(originalValue, textDelimeterEnum.SEMICOLON, true);
    const arr = convertedVal.split(textDelimeterEnum.SEMICOLON).filter((e) => (e || '') !== '');
    return arr;
});
const email = Yup.string().email('Email is not valid!').required('Email is required');
const streetAddress = Yup.string().required('Street is required');
const streetAddressOptional = Yup.string();
const city = Yup.string().required('City is required.');
const state = Yup.string().required('State is required');
const stateUSAbbrev = Yup.mixed().oneOf(
    USAStates.map((stateItem) => {
        return stateItem.Abbr;
    }),
    'Select a valid US State'
);
const usZip5 = Yup.string().matches(/^\d{5}$/, 'ZIP Code must be 5 digits');
const usZip5Optional4 = Yup.string().matches(
    /^[0-9]{5}(?:-[0-9]{4})?/,
    'ZIP Code must be 5 or 9 digits.'
);
const addressFields = {
    contactName,
    contactNameReq,
    email,
    streetAddress,
    streetAddressOptional,
    city,
    state,
    stateUSAbbrev,
    usZip5,
    usZip5Optional4,
    emailStringAsArray,
};
export default addressFields;
