import { createSelector } from 'reselect';
import delve from 'dlv';

const selectCartSlice = (state) => {
    return state.cart || {};
};

const selectCartItemIdInput = (_state, itemId) => {
    return itemId || '';
};
const selectCartFetchStatus = createSelector(selectCartSlice, (slice) => {
    return slice.isFetching;
});

const selectCartIsReady = createSelector(selectCartSlice, (slice) => {
    return slice.isFetching === false && slice.receivedAt > 0;
});
const selectAllCarts = createSelector(selectCartSlice, (cartslice) => {
    return cartslice.ShoppingCarts || [];
});

const selectCartPositionInput = (_state, position) => {
    return position;
};

const selectWarehouseIdInput = (_state, warehouseId) => {
    return warehouseId;
};
const selectSingleCart = createSelector(
    [selectAllCarts, selectCartPositionInput],
    (allCarts, position) => {
        const pos = position || 0;
        const singleCart = allCarts[pos];
        return singleCart || {};
    }
);

const selectCartByWarehouse = createSelector(
    [selectAllCarts, selectWarehouseIdInput],
    (allCarts, warehouseId) => {
        const found = allCarts.find((x) => x.WarehouseID === warehouseId) || {};
        return found;
    }
);

const selectWarehouseGroundEligible = createSelector(selectCartByWarehouse, (warehouseCart) => {
    return warehouseCart.GroundShipping || false;
});

const selectWarehouseLtlEligible = createSelector(selectCartByWarehouse, (warehouseCart) => {
    return warehouseCart.LTLShipping || false;
});

const mapCartItems = (cart) => {
    const c = cart || {};
    const contents = c.ShoppingCartItems ? c.ShoppingCartItems : [];
    const cartItems = contents.map((item) => {
        const {
            Quantity,
            UnitPrice,
            Discount,
            TotalLineAmount,
            TotalLineDiscount,
            FinalLineAmount,
        } = item;
        const cartItem = {
            ...item.Product,
            Quantity,
            UnitPrice,
            Discount,
            TotalLineAmount,
            TotalLineDiscount,
            FinalLineAmount,
        };
        return cartItem;
    });
    return cartItems;
};

const selectCartItems = createSelector(selectAllCarts, (allCarts) => {
    return allCarts.reduce((arr, curr) => {
        const newItems = mapCartItems(curr);
        return [...arr, ...newItems];
    }, []);
});

const selectLowQuantityItemsSubSlice = createSelector(selectCartSlice, (cartSlice) => {
    return delve(cartSlice, 'LowQuantityItems', {});
});
const selectAvailabilityCheckInProgress = createSelector(
    selectLowQuantityItemsSubSlice,
    (subSlice) => {
        const inProgress = delve(subSlice, 'isFetching', false);
        return inProgress;
    }
);
const selectItemsRemovedByServer = createSelector(
    selectLowQuantityItemsSubSlice,
    (lowQtySubSlice) => {
        return lowQtySubSlice.RemovedItems || [];
    }
);
const selectHasRemovedItems = createSelector(selectItemsRemovedByServer, (removedByServer) => {
    return removedByServer && removedByServer.length > 0;
});

const selectQuantityChangedItems = createSelector(
    selectLowQuantityItemsSubSlice,
    (lowQtySubSlice) => {
        return lowQtySubSlice.QuantityChangedItems || [];
    }
);

const selectHasItemChangedByItemId = createSelector(
    [selectQuantityChangedItems, selectCartItemIdInput],
    (lowQuantityItemIds, itemId) => {
        const itemIdStr = itemId.toLowerCase().trim();
        const itemQtyChanged = lowQuantityItemIds
            .map((x) => (x || '').toLowerCase().trim())
            .some((lowQtyId) => {
                const ret = lowQtyId === itemIdStr;
                return ret;
            });
        return itemQtyChanged;
    }
);
const selectHasItemsWithUpdateQtys = createSelector(
    selectQuantityChangedItems,
    (updateQtysItems) => {
        return updateQtysItems && updateQtysItems.length > 0;
    }
);

const selectCartItem = createSelector([selectCartItems, selectCartItemIdInput], (items, itemId) => {
    const found = items.find((x) => x.ItemID.toLowerCase() === itemId.toLowerCase());
    return found;
});

const selectCartQty = createSelector(selectCartItems, (items) =>
    items.reduce((acc, item) => {
        return acc + item.Quantity;
    }, 0)
);
const selectCartTotalQty = createSelector(selectCartItems, (items) => {
    const arr = items || [];
    if (arr.length > 0) {
        const totQty = items.reduce((acc, item) => {
            const qty = item.Quantity ? item.Quantity : 0;
            return acc + qty;
        }, 0);
        return totQty;
    }
    return 0;
});

const selectCartTotalPrice = createSelector(selectCartSlice, (slice) => {
    return slice.TotalAmount;
});
const selectNumWarehouses = createSelector(selectAllCarts, (allCarts) => {
    return (allCarts || []).length;
});
const selectCartWarehouse = createSelector(selectSingleCart, (cart) => {
    return (cart || {}).Warehouse || {};
});
const selectFreeShippingQualified = createSelector(selectCartSlice, (cartSlice) => {
    return cartSlice.FreeShipping || false;
});
const selectRemainingAccToFreeShipping = createSelector(selectCartSlice, (cartSlice) => {
    return cartSlice.RemainingAccToFreeShipping;
});
const selectTotalShipping = createSelector(selectCartSlice, (cart) => {
    return cart.TotalShipping;
});
const selectSalesTax = createSelector(selectCartSlice, (cart) => {
    return cart.TotalSalesTax;
});
const selectIsCrossShip = createSelector(selectCartSlice, (cart) => {
    return cart.CrossShip || false;
});
const selectSalesTaxProcessed = createSelector(selectCartSlice, (slice) => {
    return slice.SalesTaxProcessed || false;
});

const selectors = {
    selectCartFetchStatus,
    selectCartIsReady,
    selectCartItems,
    selectCartItem,
    selectCartQty,
    selectCartTotalQty,
    selectCartTotalPrice,
    selectNumWarehouses,
    selectCartWarehouse,
    selectFreeShippingQualified,
    selectRemainingAccToFreeShipping,
    selectTotalShipping,
    selectSalesTax,
    selectIsCrossShip,
    selectWarehouseGroundEligible,
    selectWarehouseLtlEligible,
    selectAllCarts,
    selectCartSlice,
    selectSalesTaxProcessed,
    selectHasItemsWithUpdateQtys,
    selectQuantityChangedItems,
    selectItemsRemovedByServer,
    selectHasRemovedItems,
    selectHasItemChangedByItemId,
};

export {
    selectors,
    selectCartFetchStatus,
    selectCartIsReady,
    selectCartItems,
    selectCartItem,
    selectCartQty,
    selectCartTotalQty,
    selectCartTotalPrice,
    selectNumWarehouses,
    selectCartWarehouse,
    selectFreeShippingQualified,
    selectRemainingAccToFreeShipping,
    selectTotalShipping,
    selectSalesTax,
    selectIsCrossShip,
    selectWarehouseGroundEligible,
    selectWarehouseLtlEligible,
    selectAllCarts,
    selectCartSlice,
    selectSalesTaxProcessed,
    selectHasItemsWithUpdateQtys,
    selectQuantityChangedItems,
    selectItemsRemovedByServer,
    selectHasRemovedItems,
    selectHasItemChangedByItemId,
    selectAvailabilityCheckInProgress,
};
