import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './rootReducer';
import { initialState } from '../helpers/initSite';

const store = configureStore({
    reducer: rootReducer,
    preloadedState: initialState,
    devTools: true,
});

export default store;
