import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PVAlert from '../ContentContainers/PVAlert';

/*Placeholder for a content container to hold multiple alert bars in a centralized location on the page*/

const PVPageAlerts = (props) => {
    const { alerts } = props;
    let renderedAlerts = '';
    if (alerts && alerts.length > 0) {
        renderedAlerts = alerts.map((alert) => (
            <PVAlert key={alert.dateAdded.toString()} {...alert} />
        ));
    }
    return <div className="container">{renderedAlerts}</div>;
};

PVPageAlerts.propTypes = {
    alerts: PropTypes.arrayOf(PropTypes.object),
};

PVPageAlerts.defaultProps = {
    alerts: [],
};
const mapStateToProps = (state) => {
    return {
        alerts: state.alerts,
    };
};

export default connect(mapStateToProps)(PVPageAlerts);
