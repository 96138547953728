import * as validation from '../../helpers/validation';
import * as pvYupValidate from './validation/pvYupValidate';
import * as textFunctions from '../../helpers/textFunctions';
import * as storage from '../../helpers/localStorage';
import USAStates from '../../helpers/json';
import keyCode from '../../helpers/keyCode';
import { shouldFetchForSlice } from '../../helpers/apiShared';
import * as paymentSchemas from './validation/paymentInfo';
import * as alert from '../alerts/index';
import paymentCardTypes from '../../helpers/json/paymentCardTypes.json';
import * as dates from '../../helpers/pvdate';
import * as addressSchemas from './validation/addressSchemas';
import * as convertDelimeters from '../../helpers/convertDelimeters';

const helpers = {
    alert,
    api: { shouldFetchForSlice },
    validation: {
        ...validation,
        ...addressSchemas,
        ...paymentSchemas,
        ...pvYupValidate,
    },
    textFunctions,
    keyCode,
    USAStates,
    storage,
    paymentCardTypes,
    dates,
    convertDelimeters,
};

export default helpers;
