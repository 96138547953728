import { createSlice } from '@reduxjs/toolkit';

const checkoutStepsSlice = createSlice({
    name: 'checkout-steps',
    initialState: {
        showAccountNotAllowed: false,
        panels: [
            {
                id: 0,
                panelHeading: 'Shipping Address',
                showLoader: false,
            },
            {
                id: 1,
                panelHeading: 'Shipping Options',
                showLoader: false,
            },
            {
                id: 2,
                panelHeading: 'Payment Options',
                showLoader: false,
            },
            {
                id: 3,
                panelHeading: 'Final Review',
                showLoader: false,
            },
        ],
    },
    reducers: {
        togglePanelLoader: (state, action) => {
            const { id, showLoader } = action.payload;
            const { panels } = state;
            const newPanels = panels.map((panel) => {
                return panel.id === id ? { ...panel, showLoader } : panel;
            }, []);
            return { ...state, panels: newPanels };
        },
        setAccountNotAllowed: (state, action) => {
            const { showAccountNotAllowed } = action.payload;
            return { ...state, showAccountNotAllowed };
        },
    },
});

export const { reducer, actions } = checkoutStepsSlice;
export default reducer;

// const checkoutStepsSlice = createSlice({
//     name: 'checkout-steps',
//     initialState: {
//         currentPanel: -1,
//         steps: [
//             {
//                 id: 0,
//                 panelHeading: 'Shipping Address',
//                 enabled: true,
//             },
//             {
//                 id: 1,
//                 panelHeading: 'Shipping Options',
//                 enabled: false,
//             },
//             {
//                 id: 2,
//                 panelHeading: 'Payment Options',
//                 enabled: false,
//             },
//             {
//                 id: 3,
//                 panelHeading: 'Final Review',
//                 enabled: false,
//             },
//         ],
//     },
//     reducers: {
//         togglePanel: (state, action) => {
//             const { id, enabled } = action.payload;
//             const panels = state.panels.map(panel => {
//                 return panel.id === id ? { ...panel, enabled } : panel;
//             }, []);
//             return { ...state, panels };
//         },
//         setCurrentPanel: (state, action) => {
//             const { id } = action.payload;
//             const panels = state.panels.map(panel => {
//                 return panel.id <= id ? { ...panel, enabled: true } : panel;
//             }, []);
//             return { ...state, currentPanel: id, panels };
//         },
//     },
// });
