import { WebServiceHosts, AuthHeader } from '../../../helpers/siteConfigConstants';

const productControllerUri = `${WebServiceHosts.WebStore}/api/products`;
const categoryUri = `${productControllerUri}/category`;

const fetchCategoryProducts = (categoryName, subCategoryName, numProducts = 25, page = 0) => {
    const fullUri = `${categoryUri}/${categoryName}/${subCategoryName}/${numProducts}/${page}`;
    return fetch(fullUri, {
        headers: {
            Authorization: AuthHeader,
        },
        method: 'GET',
        cache: 'no-cache',
    });
};

const fetchSubcatgories = (categoryName) => {
    const fullUri = `${categoryUri}/${categoryName}`;
    return fetch(fullUri, {
        headers: {
            Authorization: AuthHeader,
        },
        method: 'GET',
        cache: 'no-cache',
    });
};

const fetchTopLevelCategories = () => {
    const fullUri = `${categoryUri}/topLevel`;
    return fetch(fullUri, {
        headers: {
            Authorization: AuthHeader,
        },
        method: 'GET',
        cache: 'no-cache',
    });
};

export { fetchCategoryProducts, fetchSubcatgories, fetchTopLevelCategories };
const api = { fetchCategoryProducts, fetchSubcatgories, fetchTopLevelCategories };
export default api;
