import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { WebServiceHosts, AuthHeader } from '../../helpers/siteConfigConstants';
import { selectUserAccountId } from '../../redux/userProfile/selectors';
import { selectPageAccess } from '../../redux/menus/selectors';
import PVLoadingModalBlockSite from './Content/Modals/PVLoadingModalBlockSite';
import { actions as utils } from '../../redux/siteUtils/siteUtilsSlice';

const RouteAuth = (props) => {
    const { access, accountID, children, rdxSetPageLoading } = props;
    const isMounted = useRef(null);
    const location = useLocation();
    const [showPage, setShowPage] = useState(true);
    const whiteList = ['/products', '/shop/product', '/shop/category', '/shop/checkout/paypal', '/partner/internetservicesretailerprogram'];
    useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
        };
    }, []);

    function authenticatePage() {
        const requestUri = `${WebServiceHosts.WebStore}/api/security/access`;
        const fetchBody = `{"accountID": "${accountID}", "relativePath": "${location.pathname}"}`;

        //don't authenticate any paths in the whitelist
        const isWhiteList = whiteList.map((path) => {
            if (location.pathname.toLowerCase().indexOf(path) >= 0) {
                return true;
            }
            return false;
        });

        if (!isWhiteList.some((w) => w === true)) {
            setShowPage(false);
            fetch(requestUri, {
                method: 'POST',
                mode: 'cors',
                cache: 'no-cache',
                headers: {
                    Authorization: AuthHeader,
                    'Content-Type': 'application/json',
                },
                body: fetchBody,
            })
                .then((res) => res.json())
                .then((response) => {
                    if (isMounted.current) {
                        if (response) {
                            setShowPage(response);
                            rdxSetPageLoading(false);
                        } else {
                            window.location.href = '/error/403';
                        }
                    }
                })
                .catch((_error) => {
                    if (isMounted.current) {
                        setShowPage(false);
                        rdxSetPageLoading(false);
                        window.location.href = '/error/403';
                    }
                });
        } else if (isMounted.current) {
            setShowPage(true);
            rdxSetPageLoading(false);
        }
    }

    useEffect(() => {
        rdxSetPageLoading(true);
        if (!access) {
            authenticatePage();
        } else if (isMounted.current) {
            setShowPage(true);
            rdxSetPageLoading(false);
        }
    }, []);

    return (
        <React.Fragment>
            {showPage && children} {!showPage && <PVLoadingModalBlockSite />}
        </React.Fragment>
    );
};

RouteAuth.propTypes = {
    access: PropTypes.bool.isRequired,
    accountID: PropTypes.number.isRequired,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    rdxSetPageLoading: PropTypes.func.isRequired,
};

RouteAuth.defaultProps = {
    children: <span />,
};

const mapStateToProps = (state, ownProps) => {
    return {
        access: selectPageAccess(state, ownProps.location.pathname),
        accountID: selectUserAccountId(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        rdxSetPageLoading: (val) => dispatch(utils.setPageLoading({ pageLoading: val })),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RouteAuth);
