import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import PVFooterNortonSecured from './PVFooterNortonSecured';
import paymentTypes from '../../../../helpers/json/paymentCardTypes.json';
import { getThisYear } from '../../../../helpers/pvdate';

const PVFooter = (props) => {
    const { displayFullFooter } = props;
    const FooterTop = () => (
        <div className="footer-top">
            <div className="container">
                <div className="row">
                    <div className="col-md-3">
                        <div className="newsletter-title hidden-xs hidden-sm">
                            <img
                                src="/WebSupport/PV_React/GlobalFooter/PV-logo-learn.png"
                                alt="learn more about perfectvision"
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="newsletter-form">
                            <a target="_blank" href="https://vimeo.com/670206068/98af482d70" className="btn btn-lrg btn-block pv-btn-newsletter">LEARN MORE ABOUT PERFECTVISION
                            </a>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-12 center-col">
                        <div className="socials text-center">
                            <a href="https://twitter.com/PerfectVision79" className="social">
                                <i className="fa fa-twitter" aria-hidden="true" />
                            </a>
                            <a
                                href="https://www.facebook.com/PVBetterSolutions/"
                                className="social"
                            >
                                <i className="fa fa-facebook-f" aria-hidden="true" />
                            </a>
                            <a
                                href="https://www.linkedin.com/company/pvbettersolutions/"
                                className="social"
                            >
                                <i className="fa fa-linkedin" aria-hidden="true" />
                            </a>
                            <a href="https://www.instagram.com/perfectvision_/" className="social">
                                <i className="fa fa-instagram" aria-hidden="true" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    const FooterColumn = () => (
        <div className="footer-column equal-container">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12 equal-elem">
                        <h3 className="title-of-section">About Us</h3>
                        <div className="contacts">
                            <p className="contacts-info">
                                PerfectVision was founded and is operated upon the core tenets of
                                Quality, Value, Availability and Compatibility. Our products are
                                designed and manufactured with the highest quality products and
                                standards. We deliver premium products at value prices, to keep your
                                business running smoothly. We utilize best-in-class logistics to
                                make our products available to you as soon as possible. We have
                                representatives world-wide to service our international partners.
                                All of our products are designed and manufactured to work with any
                                manufacturer&apos;s product, making them highly compatible with your
                                existing infrastructure. Customer service is a priority at
                                PerfectVision. You are not just a customer at PerfectVision, you are
                                a business partner, and we succeed when you succeed.
                            </p>
                            {/* <span className="contacts-info info-address ">
                                16101 La Grande Drive, Little Rock, AR 72223
                            </span>
                            <span className="contacts-info info-phone">
                                (501) 955-0033 | (800) 205-8620
                            </span> */}
                        </div>
                        <div className="warning-wrapper">
                            <div className="warning-header">
                                <i className="fa fa-exclamation-triangle warning-yellow" />
                                &nbsp;Attention California Residents: Warning
                            </div>
                            <div className="warning-body">
                                These products can expose you to chemicals including lead, which is
                                known to the state of California to cause cancer and birth defects
                                or other reproductive harm. For more information, go to&nbsp;
                                <a
                                    href="https://www.p65warnings.ca.gov/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    www.p65warnings.ca.gov
                                </a>
                                .
                            </div>
                            <div className="warning-footer">
                                Use gloves and wash hands after use
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-12 equal-elem">
                        <div className="links">
                            <h3 className="title-of-section">Company Info</h3>
                            <ul>
                                <li>
                                    <Link to="/Company/AboutUs">About Us</Link>
                                </li>
                                <li>
                                    <a href="/Company/Careers.aspx">Careers</a>
                                </li>

                                <li>
                                    <Link to="/Company/ContactUs">Contact Us</Link>
                                </li>
                                <li>
                                    <a href="/Company/PrivacyPolicy.aspx">Privacy Policy</a>
                                </li>
                                <li>
                                    <a href="/Company/TOS.aspx">Terms of Service</a>
                                </li>
                                <li>
                                    <a href="/WebSupport/PDFs/Returns/ReturnPolicy.pdf">Return Policy</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-12 equal-elem">
                        <div className="links">
                            <h3 className="title-of-section">Let Us Help You</h3>
                            <ul>
                                <li>
                                    <a href="/DealerAccounting/MyAccountLanding.aspx">My Account</a>
                                </li>
                                <li>
                                    <a href="https://www.surveymonkey.com/r/PVCustFeedback">
                                        Take Our Survey
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    const CopyRightStatement = () => (
        <div className="copyright-right">
            By signing in to the PerfectVision website you are agreeing to and acknowledging your
            compliance with the Terms of Service and Privacy Policy used to govern the PerfectVision
            website.
            <br />
            &copy; Copyright {getThisYear()} PerfectVision. All Rights Reserved.
            <br />
            &nbsp;
        </div>
    );

    const CopyRight = () => {
        if (displayFullFooter) {
            return (
                <div className="copyright">
                    <div className="container">
                        <div className="col-sm-6 col-md-6">
                            <CopyRightStatement />
                        </div>
                        <div className="col-sm-2 col-md-1">
                            <div className="pv-norton">
                                <PVFooterNortonSecured />
                            </div>
                        </div>

                        <div className="col-sm-4 col-md-5">
                            <div className="pay-men">
                                {paymentTypes
                                    .filter((pt) => pt.footerDisplay)
                                    .map((pt) => {
                                        return <img key={pt.type} alt={pt.type} src={pt.image} />;
                                    })}
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        return (
            <div className="copyright">
                <div className="container">

                    <div className="col-sm-12">
                        <PVFooterNortonSecured />
                    </div>

                    <div className="col-sm-12">
                        <div className="copyright-right">
                            <CopyRightStatement />
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    if (displayFullFooter) {
        return (
            <React.Fragment>
                <FooterTop />

                {/* 7332 - BrightEdge AutoPilot - Part 2 of 3 */}
		        <div className="be-ix-link-block"></div>

                <FooterColumn />
                <CopyRight />
            </React.Fragment>
        );
    }
    return (
        <React.Fragment>
            <CopyRight />
        </React.Fragment>
    );
};

PVFooter.propTypes = {
    displayFullFooter: PropTypes.bool,
};

PVFooter.defaultProps = {
    displayFullFooter: true,
};

export default PVFooter;
