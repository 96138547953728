import PropTypes from 'prop-types';
import { PVPropsACH } from './PVPropsACH';
import { PVPropsCreditCard } from './PVPropsCreditCard';

const PVPaymentTypeEnum = {
    CO_Check: 1,
    CreditCard: 2,
    PayPal: 3,
    Cash: 4,
    ACH: 5,
    LTL_Certif: 6,
    MoneyOrder: 7,
    UPS_COD: 8,
    Wire: 9,
};
const paymentTypesArray = Object.keys(PVPaymentTypeEnum).map((k) => {
    return PVPaymentTypeEnum[k].toString();
});

//POST http://localhost:59056/api/checkout/payment/
const PVPropsPaymentInfo = PropTypes.shape({
    PaymentType: PropTypes.oneOf(paymentTypesArray),
    ACH: PVPropsACH,
    CreditCard: PVPropsCreditCard,
    OrderReference: PropTypes.string,
});

export default PVPropsPaymentInfo;

export { PVPaymentTypeEnum };
