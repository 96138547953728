import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { changeDealerId } from '../../../../redux/userProfile/actionsAsync';
import {
    selectActiveDealerKey,
    selectDisplayDealerList,
    selectAccessoryOnlyBit,
    selectSiteId,
    selectActiveDealerIdForAccount,
} from '../../../../redux/userProfile/selectors';
import PVDealerIdChangedModal from './PVDealerIdChangedModal';

const PVSelectDealer = ({
    dealerList,
    booAccessoryOnly,
    dealerKey,
    dealerId,
    siteId,
    apiSelectDealerId,
}) => {
    const [holdDealerKey, setHoldDealerKey] = useState('');
    const [changeDealerDialog, setChangeDealerDialog] = useState(false);

    const setDealerId = (value) => {
        if (value === true) {
            apiSelectDealerId(holdDealerKey);
            setChangeDealerDialog(false);
        }
    };

    const renderOneDealer = dealerList.length <= 1 || booAccessoryOnly;

    const handleSelect = (e, newDealerKey) => {
        if (newDealerKey !== dealerKey) {
            // show warning modal: OK calls setDealerId and uses holdDealerKey
            e.preventDefault();
            setHoldDealerKey(newDealerKey);
            setChangeDealerDialog(true);
        }
    };
    const ddlContent = renderOneDealer ? (
        []
    ) : (
        <div className="pv-header-dealer-id-select-wrapper">
            <select
                className="pv-header-dealer-id-select"
                role="menu"
                defaultValue={dealerKey}
                onChange={(e) => handleSelect(e, e.target.value)}
            >
                {dealerList.map((dealer, idx) => (
                    <option
                        key={dealer.ID}
                        value={dealer.ID}
                        className="switcher-option"
                        role="menuitem"
                        tabIndex={idx === 0 ? 0 : -1}
                        //selected={dealer.ID === dealerKey ? 'selected' : ''}
                    >
                        {dealer.Display}
                    </option>
                ))}
            </select>
        </div>
    );
    const renderedDealerId =
        dealerList.length <= 1 ? (
            <React.Fragment>
                <div className="pv-header-dealer-id">
                    <div className="pv-header-dealer-id-label">DealerID:</div>
                    <div className="pv-header-dealer-id-value">{dealerId}</div>
                </div>
                <div className="pv-header-divider" />
            </React.Fragment>
        ) : (
            <React.Fragment>
                <div className="pv-header-dealer-id">
                    <div className="pv-header-dealer-id-label">DealerID:</div>
                    {ddlContent}
                </div>
                <div className="pv-header-divider" />
            </React.Fragment>
        );
    return (
        <React.Fragment>
            {siteId === 12 || siteId === 13 ? <React.Fragment /> : renderedDealerId}
            {changeDealerDialog && (
                <PVDealerIdChangedModal
                    onRequestClose={() => setChangeDealerDialog(false)}
                    isOpen={changeDealerDialog}
                    response={(val) => setDealerId(val)}
                />
            )}
        </React.Fragment>
    );
};

PVSelectDealer.propTypes = {
    dealerKey: PropTypes.string.isRequired,
    dealerId: PropTypes.string.isRequired,
    dealerList: PropTypes.arrayOf(PropTypes.object).isRequired,
    apiSelectDealerId: PropTypes.func.isRequired,
    booAccessoryOnly: PropTypes.bool.isRequired,
    siteId: PropTypes.number.isRequired,
};
const mapStateToProps = (state) => {
    return {
        dealerKey: selectActiveDealerKey(state),
        dealerId: selectActiveDealerIdForAccount(state),
        dealerList: selectDisplayDealerList(state),
        booAccessoryOnly: selectAccessoryOnlyBit(state),
        siteId: selectSiteId(state),
    };
};
const mapDispatchToProps = (dispatch) => {
    return { apiSelectDealerId: (idKey) => dispatch(changeDealerId(idKey)) };
};
export default connect(mapStateToProps, mapDispatchToProps)(PVSelectDealer);
