import React from 'react';
import PropTypes from 'prop-types';
import PVFeaturedProductsPanel from './PVFeaturedProductsPanel';

const PVFeaturedProducts = (props) => {
    const { showPricing } = props;
    return (
        <div className="block-feature-product">
            <div className="container">
                <div className="title-of-section">Featured Products</div>
                <div className="tab-content">
                    <div className="tab-container">
                        <PVFeaturedProductsPanel
                            key={0}
                            industry="none"
                            categoryId={0}
                            numProducts={10}
                            tabId={0}
                            className="active"
                            showPricing={showPricing}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

PVFeaturedProducts.propTypes = {
    showPricing: PropTypes.bool,
};

PVFeaturedProducts.defaultProps = {
    showPricing: true,
};

export default PVFeaturedProducts;
