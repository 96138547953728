import { actions } from './categorySlice';
import { addStoreItems } from '../items';
import { fetchCategoryProducts, fetchSubcatgories } from './api';
import { shouldFetchForSlice } from '../../../helpers/apiShared';
import * as nrml from '../../normalize';
import { selectCategorySlice, selectCategory } from './selectors';

const getProductsForCategory = (categoryName, subcategory, numProducts = 25, page = 0) => {
    return async (dispatch, getState) => {
        const initialState = (selectCategorySlice(getState())[categoryName] || {})[subcategory];
        //update for paging considerations
        if (shouldFetchForSlice(initialState, 500)) {
            dispatch(actions.getSubcategoryProductsSent({ categoryName, subcategory, list: [] }));
            const response = await fetchCategoryProducts(
                categoryName,
                subcategory,
                numProducts,
                page
            );
            const json = await response.json();
            if (response.status === 200) {
                const { ID, Name, ParentName, WebDescription, BannerImage, TotalProducts, ProductList } = json;
                const payload = {
                    categoryName,
                    subcategory,
                    ID,
                    Name,
                    ParentName,
                    WebDescription,
                    BannerImage,
                    TotalProducts,
                    numProducts,
                    page,
                };
                const oldList = (
                    ((getState().products.category || {})[categoryName] || {})[subcategory] || {}
                ).list;
                if (!oldList)
                    await dispatch(
                        actions.initSubcategoryArray({ categoryName, subcategory, TotalProducts })
                    );

                let list = [];
                if (ProductList) {
                    const items = nrml.createKeyedObj(ProductList, 'ItemID');
                    list = Object.keys(items);
                    dispatch(addStoreItems({ items }));
                }
                return dispatch(
                    actions.getSubcategoryProductsSuccess({
                        ...payload,
                        list,
                    })
                );
            }
            return dispatch(actions.getSubcategoryProductsFailure({ error: json }));
        }
        return Promise.resolve();
    };
};

const getSubcategories = (categoryName) => {
    return async (dispatch, getState) => {
        const doFetch = shouldFetchForSlice(selectCategory(getState()));
        if (doFetch) {
            dispatch(actions.getCategorySubcategoriesSent({ categoryName }));
            const response = await fetchSubcatgories(categoryName);
            const json = await response.json();
            if (response.status === 200) {
                return dispatch(actions.getCategorySubcategoriesSuccess({ categoryName, ...json }));
            }
            return dispatch(actions.getCategorySubcategoriesFailure({ categoryName, error: json }));
        }
        return Promise.resolve();
    };
};

export { getSubcategories };
export default getProductsForCategory;
