import * as api from './api';
import { selectShipToSubslice, selectFreightSubslice, selectPaymentSubslice } from './selectors';
import { actions as shippingActions } from '../checkout-shipping/checkoutShippingSlice';
import { actions as cartActions } from '../cart/cartSlice';
import { actions as paymentActions } from '../checkout-payment/checkoutPaymentSlice';
import { actions as selectionActions } from './checkoutSelectionsSlice';
import { actions as addressesActions } from '../checkout-addresses/checkoutAddressesSlice';
import { getCartRealTimeAvailability } from '../cart/actionsAsync';
// import { resetFreight } from '../checkout-shipping/actionsasync';

const sendShipToAddress = (address, id = 0, isCpu = false) => {
    return async (dispatch, getState) => {
        const initialState = selectShipToSubslice(getState());
        if (api.shouldFetchSelection(initialState, 250)) {
            const { Edited } = address;
            const addressId = Edited || address.ValidOverride ? 0 : id;
            const req = { requested: true, address, addressId, isCpu };
            dispatch(shippingActions.invalidateShipping());
            dispatch(selectionActions.setShipToSent(req));

            let response;
            const json = await api
                .postShipToAddress(address, addressId, isCpu)
                .then((resp) => {
                    response = resp;
                    // await dispatch(getCart);
                    return resp.json();
                })
                .catch((err) => {
                    if (typeof err === 'string') {
                        return { error: err };
                    }
                    return err;
                });

            if (response.ok) {
                const address1 = { ...address, ...json };
                const payload = {
                    address: { ...address1 },
                    id,
                    isCpu,
                };
                dispatch(addressesActions.setShippingAddressLocal({ shippingAddress: address1 }));
                dispatch(selectionActions.setShipToSuccess({ ...payload }));
                await dispatch(getCartRealTimeAvailability());
                return Promise.resolve(address1);
            }
            dispatch(selectionActions.setShipToFailure({ ...json }));
            return Promise.reject(json);
        }
        return Promise.resolve();
    };
};

const sendFreightSelections = (freightQuoteList, requireFreightQuoteSuccess = true) => {
    return async (dispatch, getState) => {
        const initialState = selectFreightSubslice(getState());
        const doFetch = api.shouldFetchSelection(initialState, 250);
        if (doFetch) {
            dispatch(selectionActions.sendFreightSent({ request: 'sent', list: freightQuoteList }));
            let response;
            const json = await api
                .putFreightSelection(freightQuoteList)
                .then((resp) => {
                    response = resp;
                    return resp.json();
                })
                .catch((err) => {
                    if (typeof err === 'string') {
                        return { error: err };
                    }
                    return err;
                });
            if (response.ok) {
                if (requireFreightQuoteSuccess && json.FreightQuoteSaved) {
                    dispatch(cartActions.updateCartFromFreightSuccess(json));
                    return dispatch(selectionActions.sendFreightSuccess(json));
                }
                return Promise.reject(
                    dispatch(
                        selectionActions.sendFreightFailure({
                            ...json,
                            error: ['Shipping options not saved.'],
                        })
                    )
                );
            }
            return Promise.reject(dispatch(selectionActions.sendFreightFailure(json)));
        }

        return Promise.resolve();
    };
};

const sendPayment = (paymentInfo) => {
    return async (dispatch, getState) => {
        if (api.shouldFetchSelection(selectPaymentSubslice(getState()))) {
            dispatch(selectionActions.sendPaymentSent(paymentInfo));
            let response = {};
            const json = await api
                .postPayment(paymentInfo)
                .then((success) => {
                    response = success;
                    const ret = success.json();
                    return ret;
                })
                .catch((err) => {
                    response = err;
                    if (typeof err === 'string') {
                        return { error: err };
                    }
                    return err;
                });
            if (response.ok) {
                dispatch(selectionActions.sendPaymentSuccess({ results: json }));
                return Promise.resolve(json);
            }

            dispatch(selectionActions.sendPaymentFailure({ paymentInfo, error: json }));
            return Promise.reject(json);
        }
        return Promise.resolve();
    };
};

const setPaymentOptionsLocal = (paymentInfo) => {
    return async (dispatch) => {
        dispatch(selectionActions.setPaymentLocal(paymentInfo));
    };
};

const rehydrateAfterPaypal = (reduxData) => {
    return async (dispatch) => {
        const {
            checkoutAddresses,
            checkoutShipping,
            checkoutPayment,
            checkoutSelections,
        } = reduxData;
        if (!checkoutPayment && !checkoutPayment.paypal) {
            return Promise.reject(new Error('No data found'));
        }
        const promises = [
            dispatch(addressesActions.rehydrateAddresses(checkoutAddresses)),
            dispatch(shippingActions.rehydrateShipping(checkoutShipping)),
            dispatch(paymentActions.rehydratePayment(checkoutPayment)),
            dispatch(selectionActions.rehydrateSelections(checkoutSelections)),
        ];

        await Promise.all(promises).catch((error) => {
            return Promise.reject(error);
        });
        return Promise.resolve();
    };
};

const invalidateCheckout = () => {
    return async (dispatch) => {
        const promises = [
            dispatch(selectionActions.invalidateCheckoutSelections()),
            dispatch(addressesActions.invalidateCheckoutAddresses()),
            dispatch(shippingActions.invalidateShipping()),
            dispatch(paymentActions.invalidatePayment()),
        ];
        return Promise.allSettled(promises);
        // return Promise.allSettled(promises)
        //     .then(() => dispatch(resetFreight()))
        //     .catch(error => Promise.reject(error));
    };
};

export {
    sendShipToAddress,
    sendFreightSelections,
    setPaymentOptionsLocal,
    sendPayment,
    rehydrateAfterPaypal,
    invalidateCheckout,
};
