import React from 'react';
import PropTypes from 'prop-types';
import getFallbackImage from '../../../../helpers/getFallbackImage';

const PVBanner = ({ heading, bannerUrl, renderBanner }) => {
    let banner = '';
    if (renderBanner) {
        banner = (
            <div className="pv-banner shop full-width">
                <div>
                    {bannerUrl.length > 0 ? (
                        <img
                            src={bannerUrl}
                            onError={(e) => {
                                getFallbackImage(
                                    e,
                                    '/WebSupport/PV_React/WebStore/category-banners/category-generic-bnr.jpg'
                                );
                            }}
                            alt={heading}
                            className="pv-banner shop full-width"
                        />
                    ) : (
                        <div className="pv-banner-image-placeholder" />
                    )}
                    <div className="container">
                        <div className="row">
                            <div className="col col-md-12">
                                <div className="pv-banner-heading-wrapper">
                                    <div className="pv-banner-heading-content">
                                        <h1>{heading}</h1>
                                     </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return banner;
};

PVBanner.propTypes = {
    heading: PropTypes.string.isRequired,
    renderBanner: PropTypes.bool,
    bannerUrl: PropTypes.string,
};
PVBanner.defaultProps = {
    renderBanner: false,
    bannerUrl: '',
};

export default PVBanner;
