import { createSlice } from '@reduxjs/toolkit';
import cloneDeep from 'clone-deep';

const alertsSlice = createSlice({
    name: 'alert',
    initialState: [],
    reducers: {
        alertAdded: (state, action) => {
            const { payload } = action;
            const currentAlerts = cloneDeep(state);
            return [...currentAlerts, payload];
        },
        removeAllAlerts: () => {
            const alerts = [];
            return alerts;
        },
        removeAlertByType: (state, action) => {
            const alertType = action.payload;
            const filteredAlerts = (state || []).filter((alert) => {
                return alert.alertType.toLowerCase() !== alertType.toLowerCase();
            });
            return filteredAlerts;
        },
        removeById: (state, action) => {
            const id = action.payload;
            const filteredAlerts = (state || []).filter((alert) => {
                return alert.id.toLowerCase() !== id.toLowerCase();
            });
            return filteredAlerts;
        },
    },
});

export const { reducer, actions } = alertsSlice;
export default reducer;

/*
To use alerts, import the addAlertFromError method and call it. No need to connect the caller to the redux store (I think)
Using AlertObj gets you a constructor. Alerttype gets you allowable alertTypes.

import { addAlertFromError, AlertObj, AlertType } from '../../../redux/alerts/alertSlice';
    
    const a = new AlertObj('my id', DisplayType.SUCCESS, 'your alert was added', AlertType.VALIDATION, true, 0);
    addAlertFromError(a);
    
    Alerts with the type of VALIDATION OR PAGE will clear automatically when the user changes Routes.
*/
