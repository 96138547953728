import { createSlice } from '@reduxjs/toolkit';
import { propsByActionType, APIACTIONS } from '../../helpers/apiShared';

const checkoutPaymentSlice = createSlice({
    name: 'checkoutPayment',
    initialState: {},
    reducers: {
        getPaymentOptionsSent: (state, action) => {
            const fetchProps = propsByActionType(APIACTIONS.REQUEST);
            const { payload } = action;
            const options = { ...fetchProps, ...payload };
            return { ...state, rehydrated: false, options };
        },
        getPaymentOptionsSuccess: (state, action) => {
            const fetchProps = propsByActionType(APIACTIONS.SUCCESS);
            const { payload } = action;
            const options = { ...fetchProps, ...payload };
            return { ...state, options };
        },
        getPaymentOptionsFailure: (state, action) => {
            const fetchProps = propsByActionType(APIACTIONS.FAILURE);
            const { payload } = action;
            const options = { ...fetchProps, ...payload };
            return { ...state, options };
        },
        createPaypalTokenSent: (state, action) => {
            const fetchProps = propsByActionType(APIACTIONS.REQUEST);
            const { payload } = action;
            const paypal = { ...fetchProps, ...payload };
            return { ...state, paypal };
        },
        createPaypalTokenSuccess: (state, action) => {
            const fetchProps = propsByActionType(APIACTIONS.SUCCESS);
            const { payload } = action;
            const paypal = { ...fetchProps, ...payload };
            return { ...state, paypal };
        },
        createPaypalTokenFailure: (state, action) => {
            const fetchProps = propsByActionType(APIACTIONS.FAILURE);
            const { payload } = action;
            const paypal = { ...fetchProps, ...payload };
            return { ...state, paypal };
        },
        invalidatePayment: (_state, _action) => {
            return {};
        },
        rehydratePayment: (state, action) => {
            const { payload } = action;
            const payment = { ...payload, rehydrated: true };
            return { ...state, ...payment };
        },
        dehydratePaypal: (state, action) => {
            const { payload } = action;
            const payment = { ...payload, rehydrated: false };
            return { ...state, ...payment };
        },
    },
});

export const { actions, reducer } = checkoutPaymentSlice;
