import React from 'react';
import { useLocation, useHistory } from 'react-router';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { actions } from '../../../../redux/checkout-steps/checkoutStepsSlice';
import { selectShowNotAllowed } from '../../../../redux/checkout-steps/selectors';
import { PVWarningModal } from '../../ContentContainers/PVModals';

const PVStoppedCustomerModal = ({ rdxCloseModal, isOpen }) => {
    const location = useLocation();
    const history = useHistory();
    const header = 'Notice';
    const footer = (
        <button
            type="button"
            className="pv btn btn-primary button-row"
            onClick={() => {
                const locationPath = location.pathname.toLowerCase();
                if (
                    locationPath.indexOf('shop') > -1 &&
                    locationPath.indexOf('checkout') > -1 &&
                    location.pathname.indexOf('complete') < 0
                ) {
                    history.push('/Shop/Cart');
                }
                return rdxCloseModal();
            }}
        >
            OK
        </button>
    );
    return (
        <PVWarningModal
            onRequestClose={rdxCloseModal}
            isOpen={isOpen}
            headerContent={header}
            shouldCloseOnEsc={false}
            footerContent={footer}
            bodyContent="This account is currently not able to complete purchases. Please contact your sales person or a member of the Account Services team."
            contentLabel="AccountStoppedModal"
        />
    );
};

PVStoppedCustomerModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    rdxCloseModal: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
    return {
        isOpen: selectShowNotAllowed(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        rdxCloseModal: () =>
            dispatch(actions.setAccountNotAllowed({ showAccountNotAllowed: false })),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PVStoppedCustomerModal);
