import { actions } from './cartSlice';
import * as api from './api';
import {
    selectHasItemsWithUpdateQtys,
    selectHasRemovedItems,
    selectItemsRemovedByServer,
    selectQuantityChangedItems,
    selectCartItems,
} from './selectors';

export const getCart = (delayMs = 500) => {
    return async (dispatch, getState) => {
        const initialState = getState();
        const doFetch = api.shouldCallCartApi(initialState, delayMs);
        if (doFetch) {
            dispatch(
                actions.getCartSent({ timestamp: new Date().toString(), note: 'initial fetch' })
            );
            let response;

            const json = await api
                .getItems()
                .then((res) => {
                    response = res;
                    return res.json();
                })
                .catch((err) => {
                    const obj = { error: err.toString() };
                    response = obj;
                    return obj;
                });

            if (response.ok && json.ShoppingCarts) {
                return Promise.resolve(dispatch(actions.getCartSuccess(json)));
            }
            return Promise.reject(dispatch(actions.getCartFailure({ error: { ...json } })));
        }
        return Promise.resolve();
    };
};

export const getCartRealTimeAvailability = () => {
    return async (dispatch) => {
        dispatch(
            actions.getCartAvailabilitySent({
                timestamp: new Date().toString(),
                note: 'checkingAvailability',
            })
        );
        let response;

        const json = await api
            .getCartWithRealTimeChecks()
            .then((res) => {
                response = res;
                return res.json();
            })
            .catch((err) => {
                const obj = { error: err.toString() };
                response = obj;
                return obj;
            });

        if (response.ok && json.ShoppingCarts) {
            return Promise.resolve(dispatch(actions.getCartAvailabilitySuccess(json)));
        }
        return Promise.reject(dispatch(actions.getCartAvailabilityFailure({ error: { json } })));
    };
};

const updateLowQuantityItems = (itemIds = []) => {
    return async (dispatch, getState) => {
        const ids = (Array.isArray(itemIds) ? itemIds : [itemIds])
            .filter((f) => typeof f === 'string')
            .map((i) => i.toLowerCase().trim());
        const state = getState();
        const hasAdjustedQtys = selectHasItemsWithUpdateQtys(state);
        const hasRemovedItems = selectHasRemovedItems(state);
        if (hasAdjustedQtys || hasRemovedItems) {
            const RemovedItems = selectItemsRemovedByServer(state)
                .map((itemid) => itemid.toLowerCase().trim())
                .filter((removedItem) => ids.indexOf(removedItem) < 0);
            const QuantityChangedItems = selectQuantityChangedItems(state)
                .map((c) => c.toLowerCase().trim())
                .filter((updateItem) => ids.indexOf(updateItem) < 0);
            dispatch(actions.setLowQuantityItems({ RemovedItems, QuantityChangedItems }));
        }
    };
};
export const addMultiple = (reqBody) => {
    return async (dispatch, getState) => {
        const initialState = getState();
        const doFetch = api.shouldCallCartApi(initialState);
        if (doFetch) {
            dispatch(actions.addMultipleSent(reqBody));
            const response = await api.postCartItems(reqBody);
            const json = await response.json();
            if (response.ok && json.ShoppingCarts) {
                dispatch(updateLowQuantityItems(reqBody));
                return dispatch(actions.addCartItemSuccess(json));
            }
            return Promise.reject(dispatch(actions.addMultipleFailure({ error: json })));
        }
        return Promise.resolve();
    };
};
export const canAddMultiple = (reqBody) => {
    return async (dispatch, getState) => {
        const initialState = getState();
        const doFetch = api.shouldCallCartApi(initialState);
        if (doFetch) {
            dispatch(actions.canAddMultipleSent(reqBody));
            const response = await api.postCanAddCartItems(reqBody);
            const json = await response.json();
            if (response.ok && json.ShoppingCarts) {
                //dispatch(updateLowQuantityItems(reqBody));
                return dispatch(actions.addCartItemSuccess(json));
            }
            return Promise.reject(dispatch(actions.addMultipleFailure({ error: json })));
        }
        return Promise.resolve();
    };
};
export const addItem = (itemId, qty, controlName) => {
    const itemSentPayLoad = {
        addItemRequest: {
            timestamp: new Date().toString(),
            itemId,
            quantity: qty,
            controlName,
        },
    };

    // 6647 Issue 113 - removing "doFetch" to allow multiple async calls to addItem
    return async (dispatch) => {
        dispatch(actions.addCartItemSent(itemSentPayLoad));
        const response = await api.putAddCartItem(itemId, qty, controlName);
        const json = await response.json();
        if (response.ok && json.ShoppingCarts) {
            dispatch(updateLowQuantityItems([itemId]));
            return dispatch(actions.addCartItemSuccess(json));
        }
        return Promise.reject(dispatch(actions.addCartItemFailure(json)));
    };
};

export const removeItem = (productId, controlName) => {
    // 6647 Issue 113 - removing "doFetch" to allow multiple async calls to removeItem
    return async (dispatch, getState) => {
        const deleteItemPayload = {
            deleteItemRequest: {
                timestamp: new Date().toString(),
                productId,
                controlName,
            },
        };
        dispatch(actions.deleteCartItemSent(deleteItemPayload));
        const response = await api.delCartItem(productId);
        const json = await response.json();
        if (response.ok && json.CustID) {
            const cartItems = selectCartItems(getState());
            const foundItem =
                cartItems.find((cartItem) => cartItem.ID.toString() === productId.toString()) || {};
            dispatch(updateLowQuantityItems([foundItem.ItemID]));
            return dispatch(actions.deleteCartItemSuccess(json));
        }
        return Promise.reject(dispatch(actions.deleteCartItemFailure(json)));
    };
};

export const updateQuantity = (productId, qty, controlName) => {
    // 6647 Issue 113 - removing "doFetch" to allow multiple async calls to addItem
    return async (dispatch, getState) => {
        const updateQuantityPayload = {
            updateQtyPost: {
                timestamp: new Date().toString(),
                productId,
                qty,
                controlName,
            },
        };
        dispatch(actions.updateCartItemSent(updateQuantityPayload));
        const response = await api.updateItemQuantity(productId, qty, controlName);
        const json = await response.json();
        if (response.ok && json.CustID) {
            const cartItems = selectCartItems(getState());
            const foundItem =
                cartItems.find((cartItem) => cartItem.ID.toString() === productId.toString()) || {};
            dispatch(updateLowQuantityItems([foundItem.ItemID]));
            return dispatch(actions.updateCartItemSuccess(json));
        }
        return Promise.reject(dispatch(actions.updateCartItemFailure(json)));
    };
};

export const emptyCart = () => {
    return async (dispatch, getState) => {
        const initialState = getState();
        const doFetch = api.shouldCallCartApi(initialState);
        if (doFetch) {
            const updateQuantityPayload = {
                clearCartRequest: {
                    timestamp: new Date().toString(),
                },
            };
            dispatch(actions.updateClearCartSent(updateQuantityPayload));
            const response = await api.clearCart();
            const json = await response.json();
            if (response.ok && json.CustID) {
                return dispatch(actions.updateClearCartSuccess(json));
            }
            return Promise.reject(dispatch(actions.updateClearCartFailure({ error: { json } })));
        }
        return Promise.resolve();
    };
};

export const deleteWarehouseCarts = (warehouseIds) => {
    const ids = Array.isArray(warehouseIds) ? [...warehouseIds] : [warehouseIds];
    return async (dispatch, getState) => {
        const initialState = getState();
        const doFetch = api.shouldCallCartApi(initialState, 50);
        if (doFetch) {
            dispatch(actions.deleteWarehouseCartSent({ request: 'deleteWarehouseCarts', ids }));
            let response;
            const json = await api
                .deleteWarehouseCarts(ids)
                .then((resp) => {
                    response = resp;
                    return resp.json();
                })
                .catch((err) => {
                    return err.json ? err.json() : err;
                });

            if (response.ok) {
                return Promise.resolve(dispatch(actions.deleteWarehouseCartSuccess(json)));
            }
            return Promise.reject(
                dispatch(actions.deleteWarehouseCartFailure({ error: { ...json } }))
            );
        }
        return Promise.resolve();
    };
};
