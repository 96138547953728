import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import loadable from '@loadable/component';
import { selectors, api, featuredTypes } from '../../../../redux/products/featured';

const PVFeaturedProductsSlider = loadable(
    () => import('../../../PVWebstore/ProductSliders/PVFeaturedProductsSlider'),
    { fallback: <div className="pv-spinner sm" /> }
);

const PVFeaturedProductsPanel = (props) => {
    const {
        numProducts,
        industry,
        items,
        className,
        tabId,
        apiGetFeatured,
        featuredReady,
        featuredLoading,
    } = props;

    useEffect(() => {
        if (!featuredReady && !featuredLoading) {
            apiGetFeatured(numProducts, industry);
        }
    }, [featuredReady, numProducts, industry, featuredLoading]);

    return (
        <React.Fragment>
            {items.length > 0 ? (
                <div id={`tab-${tabId}`} className={`tab-panel ${className}`}>
                    <PVFeaturedProductsSlider {...props} />
                </div>
            ) : null}
        </React.Fragment>
    );
};
PVFeaturedProductsPanel.propTypes = {
    tabId: PropTypes.number.isRequired,
    numProducts: PropTypes.number,
    industry: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.object),
    apiGetFeatured: PropTypes.func.isRequired,
    className: PropTypes.string,
    showPricing: PropTypes.bool,
    featuredReady: PropTypes.bool.isRequired,
    featuredLoading: PropTypes.bool.isRequired,
};
PVFeaturedProductsPanel.defaultProps = {
    numProducts: 12,
    industry: '',
    items: [],
    className: '',
    showPricing: true,
};
const mapStateToProps = (state) => {
    const selectorOptions = { featuredType: featuredTypes.FEATURED };

    return {
        items: selectors.selectFeaturedItemsCollated(state, selectorOptions),
        featuredReady: selectors.selectLoadCompleteForType(state, selectorOptions),
        featuredLoading: selectors.selectIsLoadingForType(state, selectorOptions),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        apiGetFeatured: (qty, industry) => {
            return dispatch(api.getFeaturedProducts(qty, industry, featuredTypes.FEATURED));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PVFeaturedProductsPanel);
