import { createSlice } from '@reduxjs/toolkit';

const contentSlice = createSlice({
    name: 'content',
    initialState: { landings: {} },
    reducers: {
        setLandingContent: (state, action) => {
            const { landingName, jsonContent } = action.payload;
            return { ...state, landings: { ...state.landings, [landingName]: jsonContent } };
        },
    },
});

export const { actions, reducer } = contentSlice;
export const { replaceMenus } = actions;
export default reducer;
