import { createSlice } from '@reduxjs/toolkit';
import produce from 'immer';
import * as cloneDeep from 'clone-deep';
import { propsByActionType, APIACTIONS } from '../../helpers/apiShared';

const checkoutShippingSlice = createSlice({
    name: 'checkoutShipping',
    initialState: {},
    reducers: {
        getFreightQuoteSent: (state, action) => {
            const fetchProps = propsByActionType(APIACTIONS.REQUEST);
            const { freightType, freightPayload } = action.payload;
            const typeObj = { [freightType]: { ...fetchProps, ...freightPayload } };
            return { ...state, ...typeObj };
        },
        getFreightQuoteSuccess: (state, action) => {
            const fetchProps = propsByActionType(APIACTIONS.SUCCESS);
            const { freightType, freightPayload } = action.payload;
            const typeObj = { [freightType]: { ...fetchProps, ...freightPayload } };
            return { ...state, ...typeObj };
        },
        getFreightQuoteFailure: (state, action) => {
            const fetchProps = propsByActionType(APIACTIONS.FAILURE);
            const { freightType, freightPayload } = action.payload;
            const typeObj = { [freightType]: { ...fetchProps, ...freightPayload } };
            return { ...state, ...typeObj };
        },
        getFreightQuoteCancelled: (_state, action) => {
            const fetchProps = propsByActionType(APIACTIONS.ABORT);
            const { cancelReason } = action.payload;
            const cancelObj = { ...fetchProps, cancelReason };
            return {
                ltl: cancelObj,
                standard: cancelObj,
                expedeited: cancelObj,
            };
        },
        setFreightOptions: (state, action) => {
            const newState = produce(state, (draftInitial) => {
                const fetchProps = propsByActionType(APIACTIONS.LOCALCHANGE);
                const draft = cloneDeep(draftInitial);
                const currentSelection = draft.selection;
                const { payload } = action;
                const newDraft = {
                    ...draft,
                    selectionTime: fetchProps.updateAt,
                    selection: { ...currentSelection, ...payload },
                };
                return newDraft;
            });

            return { ...newState };
        },
        resetFreightSent: (state, action) => {
            const fetchProps = propsByActionType(APIACTIONS.REQUEST);
            const { payload } = action;
            return { ...state, ...fetchProps, ...payload };
        },
        resetFreightSuccess: (_state, _action) => {
            const fetchProps = propsByActionType(APIACTIONS.SUCCESS);
            return { ...fetchProps };
        },
        resetFreightFailure: (state, action) => {
            const fetchProps = propsByActionType(APIACTIONS.FAILURE);
            const { payload } = action;
            return { ...state, ...fetchProps, ...payload };
        },
        invalidateShipping: (_state, _action) => {
            return {};
        },
        rehydrateShipping: (state, action) => {
            const { payload } = action;
            return { ...state, ...payload };
        },
        setWarehouseQuotes: (state, action) => {
            const { warehouseQuotes } = action.payload;
            return { ...state, warehouseQuotes };
        },
        updateWarehouseQuoteLists: (state, action) => {
            const newState = produce(state, (initialDraft) => {
                const draft = cloneDeep(initialDraft);
                const { freightType, freightPayload } = action.payload;
                const currentWarehouseQuotes = draft.warehouseQuotes;

                if (freightPayload.FreightQuotes) {
                    const { FreightQuotes } = freightPayload;
                    const warehouseQuotes = FreightQuotes.reduce((acc, fq) => {
                        const { WarehouseID } = fq;
                        const currWh = currentWarehouseQuotes[WarehouseID]
                            ? cloneDeep(currentWarehouseQuotes[WarehouseID])
                            : {};
                        acc[WarehouseID] = {
                            ...currWh,
                            [freightType]: {
                                ...currWh[freightType],
                                list: fq.FreightQuoteItems || [],
                                loaded: true,
                            },
                        };

                        return acc;
                    }, {});

                    const newDraft = { ...draft, warehouseQuotes };
                    return newDraft;
                }
                return draft;
            });

            return newState;
        },
    },
});

export const { actions, reducer } = checkoutShippingSlice;
