import { createSlice } from '@reduxjs/toolkit';
import { propsByActionType, APIACTIONS } from '../../helpers/apiShared';

const checkoutSelectionsSlice = createSlice({
    name: 'checkoutSelections',
    initialState: {},
    reducers: {
        setShipToSent: (state, action) => {
            const fetchProps = propsByActionType(APIACTIONS.REQUEST);
            const { payload } = action;
            const shipTo = { ...fetchProps, ...payload };
            return { ...state, shipTo };
        },
        setShipToSuccess: (state, action) => {
            const fetchProps = propsByActionType(APIACTIONS.SUCCESS);
            const { payload } = action;
            const shipTo = { ...fetchProps, ...payload };
            return { ...state, shipTo };
        },
        setShipToFailure: (state, action) => {
            const fetchProps = propsByActionType(APIACTIONS.FAILURE);
            const { payload } = action;
            const shipTo = { ...fetchProps, ...payload };
            return { ...state, shipTo };
        },
        invalidateShipTo: (state, _action) => {
            const shipTo = {};
            return { ...state, shipTo };
        },
        sendFreightSent: (state, action) => {
            const fetchProps = propsByActionType(APIACTIONS.REQUEST);
            const { payload } = action;
            const freight = { ...fetchProps, ...payload };
            return { ...state, freight };
        },
        sendFreightSuccess: (state, action) => {
            const fetchProps = propsByActionType(APIACTIONS.SUCCESS);
            const { payload } = action;
            const freight = { ...fetchProps, ...payload };
            return { ...state, freight };
        },
        sendFreightFailure: (state, action) => {
            const fetchProps = propsByActionType(APIACTIONS.FAILURE);
            const { payload } = action;
            const freight = { ...fetchProps, ...payload };
            return { ...state, freight };
        },
        invalidateFreight: (state, _action) => {
            const freight = {};
            return { ...state, freight };
        },
        setPaymentLocal: (state, action) => {
            const payment = action.payload;
            return { ...state, payment };
        },
        sendPaymentSent: (state, action) => {
            const fetchProps = propsByActionType(APIACTIONS.REQUEST);
            const { payload } = action;
            const payment = { ...fetchProps, ...payload };
            return { ...state, payment };
        },
        sendPaymentSuccess: (state, action) => {
            const fetchProps = propsByActionType(APIACTIONS.SUCCESS);
            const { payload } = action;
            const payment = { ...fetchProps, ...payload };
            return { ...state, payment };
        },
        sendPaymentFailure: (state, action) => {
            const fetchProps = propsByActionType(APIACTIONS.FAILURE);
            const { payload } = action;
            const payment = { ...fetchProps, ...payload };
            return { ...state, payment };
        },
        invalidateCheckoutSelections: (_state, _action) => {
            return {};
        },
        rehydrateSelections: (state, action) => {
            const { payload } = action;
            return { ...state, ...payload };
        },
    },
});

export const { actions, reducer } = checkoutSelectionsSlice;
export const { setShipToSent, setShipToSuccess, setShipToFailure } = actions;
