const ABORTREASONS = {
    STILL_FETCHING: 'STILL_FETCHING',
    TIME_DELAY: 'TIME_DELAY',
    USER_CANCEL: 'USER_CANCEL', //only implemented in certain ES specs. Here to differentiate between a logic abort and a user abort of an XHR
    NO_ABORT: 'NO_ABORT',
};

const APIACTIONS = {
    REQUEST: 'REQUEST',
    SUCCESS: 'SUCCESS',
    FAILURE: 'FAILURE',
    ABORT: 'ABORT',
    LOCALCHANGE: 'LOCALCHANGE',
    LOCALCLEAR: 'LOCALCLEAR',
};

function propsByActionType(apiActionType) {
    switch (apiActionType) {
        case APIACTIONS.REQUEST:
            return {
                isFetching: true,
                receivedAt: null,
                updateAt: Date.now(),
                success: false,
                localOnlyChange: true,
                reason: apiActionType,
            };
        case APIACTIONS.SUCCESS:
            return {
                isFetching: false,
                receivedAt: Date.now(),
                updateAt: Date.now(),
                success: true,
                localOnlyChange: false,
                reason: apiActionType,
            };
        case APIACTIONS.LOCALCHANGE:
            return {
                isFetching: false,
                receivedAt: null,
                updateAt: Date.now(),
                success: true,
                localOnlyChange: true,
                reason: apiActionType,
            };
        case APIACTIONS.LOCALCLEAR: {
            return {
                isFetching: false,
                receivedAt: null,
                updateAt: Date.now(),
                success: null,
                localOnlyChange: true,
                reason: apiActionType,
            };
        }
        case APIACTIONS.FAILURE:
            return {
                isFetching: false,
                receivedAt: Date.now(),
                updateAt: Date.now(),
                success: false,
                localOnlyChange: false,
                reason: apiActionType,
            };
        default:
            return {
                isfetching: undefined,
                receivedAt: undefined,
                updateadAt: Date.now(),
                success: undefined,
                localOnlyChange: undefined,
                reason: apiActionType,
            };
    }
}

const isFetchingProps = (apiActionType = APIACTIONS.REQUEST, state = {}, newObj = {}) => {
    const fetchingProps = propsByActionType(apiActionType);
    const returnProps = { ...state, ...newObj, ...fetchingProps };
    return returnProps;
};

const shouldFetch = (inProgress = false, lastFetch = 0, delayMs = 500) => {
    const now = Date.now();
    const timeDiff = now - lastFetch;
    if (inProgress) {
        return ABORTREASONS.STILL_FETCHING;
    }
    if (lastFetch !== 0 && timeDiff < delayMs) {
        return ABORTREASONS.TIME_DELAY;
    }
    return ABORTREASONS.NO_ABORT;
};

const shouldFetchForSlice = (stateSlice, delayMs = 500) => {
    const slice = stateSlice || {};
    return shouldFetch(slice.isFetching, slice.receivedAt, delayMs);
};

export {
    ABORTREASONS,
    APIACTIONS,
    shouldFetch,
    shouldFetchForSlice,
    isFetchingProps,
    propsByActionType,
};
export default shouldFetchForSlice;
