import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    selectCartQty,
    selectCartIsReady,
    selectCartTotalPrice,
} from '../../../../../redux/cart/selectors';
import { getPriceString } from '../../../../../helpers/textFunctions';

const PVCartHeaderDisplay = (props) => {
    //preventClick = e => {
    //    e.preventDefault();
    //};
    const width = window.matchMedia('(min-width: 768px) and (max-width: 1200px)');
    function preventClick(e) {
        if (width.matches) {
            e.preventDefault();
        } else {
            //do nothing;
        }
    }
    width.addListener(preventClick);

    const { qty, isReady, totalPrice } = props;

    const waitingIcon = !isReady
        ? {
              color: 'rgb(155,155,155)',
          }
        : null;
    const counterWaiting = !isReady
        ? {
              backgroundColor: 'rgb(155,155,155)',
          }
        : null;
    return (
        <React.Fragment>
            <a className="minicart" onClick={preventClick} href="/Shop/Cart">
                <span className="counter qty">
                    <span className="cart-icon">
                        <i className="fa fa-shopping-bag" aria-hidden="true" style={waitingIcon} />
                    </span>
                    <span className="counter-number" style={counterWaiting}>
                        {qty}
                    </span>
                </span>
                <span className="counter-your-cart">
                    <span className="counter-label hidden-xs hidden-sm" hidden={!isReady}>
                        Your Cart:
                    </span>
                    <span className="counter-price hidden-xs hidden-sm" hidden={!isReady}>
                        {getPriceString(totalPrice)}
                    </span>
                </span>
            </a>
        </React.Fragment>
    );
};

PVCartHeaderDisplay.propTypes = {
    qty: PropTypes.number,
    isReady: PropTypes.bool,
    totalPrice: PropTypes.number,
};

PVCartHeaderDisplay.defaultProps = {
    qty: 0,
    isReady: false,
    totalPrice: 0.0,
};

const mapStateToProps = (state) => ({
    qty: selectCartQty(state),
    isReady: selectCartIsReady(state),
    totalPrice: selectCartTotalPrice(state),
});

export default connect(mapStateToProps, null)(PVCartHeaderDisplay);
