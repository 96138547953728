import extractInitStateObject from './initSiteConfig';

const node = document.getElementById('ClientApp');
const nodeData = node.dataset.react ? JSON.parse(node.dataset.react) : undefined;
const { SiteConfig, ...payload } = nodeData;

const initialState = extractInitStateObject({ ...payload });

export { initialState, SiteConfig };
/* SiteConfig lives here because while we deliver the object in some calls, the data in it only changes with a deploy or with a complete login/out
It is effectively an application constant. We destructure it in siteConfigConstants.js*/
