import * as shippingSelectors from './selectors';
import * as shippingAsync from './actionsasync';
import { actions as shippingActions } from './checkoutShippingSlice';

const rdxShipping = {
    actionsAsync: shippingAsync,
    selectors: shippingSelectors,
    actions: shippingActions,
};

export default rdxShipping;
