import delve from 'dlv';
import { createSelector } from 'reselect';
import { selectSiteId, selectLoginStatus } from '../userProfile/selectors';

const selectContentSlice = (state) => {
    return state.content;
};
const selectSubPropertyOptions = (_state, options) => {
    return options;
};

const selectSiteName = createSelector([selectSiteId, selectLoginStatus], (siteId, loginStatus) => {
    if (!loginStatus) {
        return 'global';
    }
    switch ((siteId || '').toString()) {
        case '11':
            return 'dom';
        case '12':
            return 'wire';
        case '13':
            return 'intl';
        default:
            return 'global';
    }
});
const selectSubProperty = createSelector(
    [selectContentSlice, selectSubPropertyOptions],
    (slice, options) => {
        const { subslice, property } = options;
        const dlvPath = `${subslice}.${property}`;
        return delve(slice, dlvPath, null);
    }
);

export { selectSubProperty, selectSiteName };
export default selectSubProperty;
