import { createSelector } from 'reselect';

const selectSiteUtilsSlice = (state) => {
    return state.siteUtils || {};
};
const selectPageLoading = createSelector(selectSiteUtilsSlice, (subSlice) => {
    return subSlice.pageLoading || false;
});

export { selectPageLoading };
export default selectPageLoading;
