import { actions } from './featuredSlice';
import { addStoreItems } from '../items';
import { selectFeaturedByType } from './selectors';
import fetchFeatured, { featuredTypes } from './api';
import { shouldFetchForSlice } from '../../../helpers/apiShared';
import * as nrml from '../../normalize';

const getFeaturedProducts = (
    numProducts,
    industry,
    featuredType,
    itemId = '',
    prodDetail,
    delayMs = 500
) => {
    return async (dispatch, getState) => {
        const initialState = selectFeaturedByType(getState(), featuredType.toLowerCase());

        if (shouldFetchForSlice(initialState, delayMs)) {
            dispatch(actions.getFeaturedSent({ featuredType, numProducts, itemId, prodDetail }));
            let response = {};
            const json = await fetchFeatured(
                numProducts,
                industry,
                featuredType,
                itemId,
                prodDetail
            )
                .then((data) => {
                    response = data;
                    return data.json();
                })
                .catch((error) => {
                    response = error;
                    return error.toString();
                });

            if (response.status === 200) {
                const items = nrml.createKeyedObj(json, 'ItemID');
                dispatch(addStoreItems({ items }));
                const list = json
                    .map((item) => {
                        const { Rank, ItemID } = item;
                        return { Rank, ItemID };
                    })
                    .sort((a, b) => a.Rank - b.Rank);

                return dispatch(actions.getFeaturedSuccess({ featuredType, itemId, list }));
            }

            return dispatch(actions.getFeaturedFailure({ featuredType, itemId, error: json }));
        }
        return Promise.resolve();
    };
};

export { featuredTypes, getFeaturedProducts };
export default getFeaturedProducts;
