import { createSelector } from 'reselect';

const selectProductsSlice = (state) => {
    return state.products || {};
};
const selectItemsSlice = createSelector(selectProductsSlice, (products) => {
    return products.items || {};
});
const selectItemIdInput = (_state, itemId) => {
    return itemId;
};
const selectItemId = createSelector([selectItemsSlice, selectItemIdInput], (items, itemId) => {
    return items[itemId] || {};
});

const selectItemIdListSubslice = createSelector(selectItemsSlice, (itemsSlice) => {
    return itemsSlice.ids || {};
});
const selectItemIdsList = createSelector(selectItemIdListSubslice, (listSubslice) => {
    return listSubslice.list || [];
});

const selectors = { selectItemsSlice, selectItemId, selectItemIdsList };
export { selectItemsSlice, selectItemId, selectItemIdsList };
export default selectors;
