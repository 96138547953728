const getFallBackImage = (e, specificImage) => {
    e.target.onerror = null;

    const defaultFallback = '/WebSupport/PV_React/WebStore/Products/picturenotavailable.jpg';
    const replacement = specificImage || defaultFallback;

    if (e.currentTarget.src.toLowerCase().indexOf(replacement.toLowerCase()) > -1) {
        return;
    }

    e.target.src = replacement;
};

export default getFallBackImage;
