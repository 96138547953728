import { WebServiceHosts, AuthHeader } from '../../../helpers/siteConfigConstants';
//import { shouldFetch, ABORTREASONS } from '../../helpers/apiShared';

export const featuredTypes = {
    FEATURED: 'FEATURED',
    TOPBYUSER: 'TOPBYUSER',
    RECENT: 'RECENT',
    SUGGESTED: 'SUGGESTED',
    ALSOPURCHASED: 'ALSOPURCHASED',
    POPULAR: 'POPULAR',
};

const productControllerUri = `${WebServiceHosts.WebStore}/api/products`;

const fetchFeatured = (
    numProducts = 8,
    industry = '',
    featuredType = featuredTypes.FEATURED,
    itemId = '',
    prodDetail = false
) => {
    let uriAppend = '';
    switch (featuredType) {
        case featuredTypes.FEATURED:
            uriAppend = 'featured';
            break;
        case featuredTypes.TOPBYUSER:
            uriAppend = 'userTop';
            break;
        case featuredTypes.RECENT:
            uriAppend = 'recent';
            break;
        case featuredTypes.SUGGESTED:
            uriAppend = `suggested/${itemId}`;
            break;
        case featuredTypes.ALSOPURCHASED:
            uriAppend = `alsoPurchased/${itemId}/${prodDetail}`;
            break;
        case featuredTypes.POPULAR:
            uriAppend = `popular`;
            break;
        default:
            uriAppend = 'featured';
            break;
    }
    let featuredUri = `${productControllerUri}/${uriAppend}`;

    if (numProducts && numProducts > 0) {
        featuredUri = `${featuredUri}/${numProducts}`;
    }

    if (industry && industry !== '') {
        featuredUri = `${featuredUri}/${industry}`;
    }

    return fetch(featuredUri, {
        headers: {
            Authorization: AuthHeader,
        },
        method: 'GET',
        cache: 'no-cache',
    });
};

// eslint-disable-next-line import/prefer-default-export
export default fetchFeatured;
