import * as Yup from 'yup';
import { partnerFields, addressFields } from '../../../helpers/validation';

const baseFields = {
    Street: Yup.string()
        .required('Please enter Street Address')
        .test('is-not-pobox', 'Address can not be a post office box', (value) => {
            const lowerVal = (value || '')
                .toLowerCase()
                .replace(' ', '')
                .replace('.', '')
                .replace('-', '');
            const expression = /p(ost)?o(ffice)?box/i;
            return !expression.test(lowerVal);
        }),
    Phone: partnerFields.usPhone,
    City: Yup.string().required('Please enter a City'),
    State: Yup.mixed()
        .notOneOf(['', undefined], 'Please enter a State')
        .concat(addressFields.stateUSAbbrev),
    ZipCode: Yup.string().required('Please enter Zip Code').concat(addressFields.usZip5Optional4),
};
const shippingAddressSchema = Yup.object().shape({
    ...baseFields,
    ContactName: addressFields.contactName.concat(
        Yup.string()
            .required('Please enter a Ship To Name')
            .max(50, 'Ship To Name can be no longer than 50 characters.')
    ),
    Email: addressFields.emailStringAsArray
        .required('Please enter at least one email address')
        // eslint-disable-next-line no-template-curly-in-string
        .of(Yup.string().email('${value} is not a valid email address')),
});

const billingAddressFields = {
    ...baseFields,
    ContactName: addressFields.contactName.concat(
        Yup.string()
            .required('Please enter a Contact Name')
            .max(50, 'Ship To Name can be no longer than 50 characters.')
    ),
};
const billingAddressSchema = Yup.object().shape({ billingAddressFields });

const customerPickupSchema = Yup.object().shape({
    ...baseFields,
    ContactName: billingAddressFields.ContactName,
});

export {
    shippingAddressSchema,
    billingAddressSchema,
    billingAddressFields,
    customerPickupSchema,
    baseFields,
};
