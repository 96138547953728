import delve from 'dlv';
import * as yup from 'yup';
import { billingAddressFields } from './addressSchemas';
import paymentCardTypes from '../../../helpers/json/paymentCardTypes.json';
import * as paymentHelpers from './pvPaymentHelpers';

const cardNames = paymentCardTypes
    .filter((pt) => pt.isCreditCard || pt.schemaType !== undefined)
    .map((c) => c.type);

const baseFields = {
    OrderReference: yup.string().required('Please enter Customer Reference'),
};

const achSchemaFields = {
    RecID: yup.string().required('Please select a Bank Account'),
    CheckNumber: yup.string().required('Please enter a Check Number'),
    PIN: yup
        .string()
        .required('Please enter PIN')
        .transform((value) => {
            const newVal = value !== null ? value.replace(/\D/g, '').toString() : undefined;
            return newVal;
        })
        .matches(/^\d{4}$/, 'Please enter a four-digit PIN'),
};

const creditCardOnFileSchemaFields = {
    CreditCardID: yup.string(),
    CreditCardType: yup
        .mixed()
        .required('Credit Card Type is Required')
        .oneOf(
            cardNames,
            'Your Card Number must fit that of a valid type: Visa, Mastercard, Discover, or American Express'
        ),
    SecurityCode: yup
        .string()
        .required('Please enter Security Code')
        .when('CreditCardType', (cardType, schema) => {
            const ccTypeString = cardType || '';
            return ccTypeString.startsWith('Am')
                ? schema.matches(/^\d{4}$/, 'Please enter a 4-digit Security Code')
                : schema.matches(/^\d{3}$/, 'Please enter a 3 digit Security Code');
        }),
};

const getPaymentInfoSchema = (values) => {
    const { PaymentType, CreditCard } = values;
    if (PaymentType === '5') {
        return yup.object().shape({
            ...baseFields,
            ACH: yup.object().shape(achSchemaFields),
        });
    }

    if (PaymentType === '2') {
        if (!CreditCard || CreditCard.ID === 0 || CreditCard.ID < 0) {
            return yup.object().shape({
                ...baseFields,
                CreditCard: yup.object().shape({
                    ...creditCardOnFileSchemaFields,
                    Address: yup.object().shape(billingAddressFields),
                }),
            });
        }
        return yup.object().shape({
            ...baseFields,
            CreditCard: yup.object().shape(creditCardOnFileSchemaFields),
        });
    }
    return yup.object().shape(baseFields);
};

const creditCardNewSchemaFieldsFlat = {
    ...baseFields,
    NameOnCard: yup.string().required('Please enter name'),
    ...creditCardOnFileSchemaFields,
    ...billingAddressFields,
    CreditCardNumber: yup.string().required('Please enter Credit Card Number'),
};
const creditCardOnFileSchema = yup
    .object()
    .shape({ ...baseFields, ...creditCardOnFileSchemaFields });
const creditCardNewSchema = yup.object().shape({
    ...baseFields,
    ...creditCardOnFileSchemaFields,
    Address: yup.object().shape(billingAddressFields),
});
const achSchema = yup.object().shape({ ...baseFields, ...achSchemaFields });

const createPaymentInfo = (values) => {
    const paymentType = delve(values, 'PaymentType', false);
    const typeName = paymentHelpers.getPaymentTypeName(paymentType);
    const { OrderReference, ...paymentTypeValues } = values;
    const newPaymentInfo = {
        PaymentType: paymentType.toString(),
        [typeName]: paymentTypeValues,
        OrderReference,
    };
    return newPaymentInfo;
};

export default getPaymentInfoSchema;

export {
    getPaymentInfoSchema,
    achSchema,
    creditCardOnFileSchema,
    creditCardNewSchema,
    baseFields,
    creditCardOnFileSchemaFields,
    achSchemaFields,
    creditCardNewSchemaFieldsFlat,
    createPaymentInfo,
};
