/* eslint-disable import/prefer-default-export */

import { actions } from './checkoutAddressesSlice';
import * as api from './api';
import {
    selectLastAddressSubslice,
    selectSavedSubslice,
    selectShippingAddressSubslice,
    selectZipInfo,
    selectPickupLocationsSubslice,
} from './selectors';

const getLastUsedAddress = () => {
    return async (dispatch, getState) => {
        const initialState = getState();
        const doFetch = api.shouldFetchAddress(selectLastAddressSubslice(initialState));
        if (doFetch) {
            dispatch(actions.getLastUsedSent('sent'));
            let response;
            const json = await api
                .fetchLastAddress()
                .then((resp) => {
                    response = resp;
                    return resp.json();
                })
                .catch((e) => {
                    if (response.status === 204) {
                        return Promise.reject(
                            dispatch(actions.getLastUsedFailure({ error: 'No address found.' }))
                        );
                    }
                    return e;
                });

            if (response.status === 200) {
                return Promise.resolve(dispatch(actions.getLastUsedSuccess({ address: json })));
            }
            return Promise.reject(dispatch(actions.getLastUsedFailure({ error: json })));
        }
        return Promise.resolve();
    };
};

const getSaved = () => {
    return async (dispatch, getState) => {
        const initialState = getState();
        const doFetch = api.shouldFetchAddress(selectSavedSubslice(initialState));
        if (doFetch) {
            dispatch(actions.getSavedListSent('sent'));
            let response;
            const json = await api
                .fetchSaved()
                .then((res) => {
                    response = res;
                    return res.json();
                })
                .catch((err) => {
                    const obj = { error: err.toString() };
                    response = obj;
                    return obj;
                });
            if (response.ok) {
                return Promise.resolve(dispatch(actions.getSavedListSuccess({ addresses: json })));
            }
            return Promise.reject(dispatch(actions.getSavedListFailure(json)));
        }
        return Promise.resolve();
    };
};

const getCityStateForZip = (zip) => {
    return async (dispatch, getState) => {
        const zipInfo = selectZipInfo(getState(), zip);
        if (!zipInfo) {
            dispatch(actions.getCityStateSent({ zip, payload: { requested: true } }));
            let response = {};

            const json = await api
                .fetchCityState(zip)
                .then((data) => {
                    response = data;
                    return data.json();
                })
                .catch((error) => {
                    try {
                        return { error: error.toString() };
                    } catch {
                        return error;
                    }
                });
            if (response.ok) {
                return dispatch(actions.getCityStateSuccess({ zip, json }));
            }
            return Promise.reject(dispatch(actions.getCityStateFailure({ zip, json })));
        }
        return Promise.resolve(zipInfo);
    };
};

const sendShippingAddress = (id, address, isLoggedIn, validateOnly = false) => {
    return async (dispatch, getState) => {
        const initialAddress = selectShippingAddressSubslice(getState());
        const doFetch = api.shouldFetchAddress(selectShippingAddressSubslice(getState()));
        if (doFetch) {
            const Edited = address.Edited || false;
            dispatch(actions.setShippingAddressSent({ saved: false, Edited }));

            let apiCall;
            if (validateOnly) {
                apiCall = api.postValidate;
            } else if (id && id > 0 && !address.IsPrimary) {
                apiCall = api.putAddress;
            } else {
                apiCall = api.postNew;
            }
            const submissionAddress = !address.IsPrimary
                ? address
                : { ...address, isPrimary: false, ID: 0 };
            let response;
            const json = await apiCall(submissionAddress, id)
                .then((res) => {
                    response = res;
                    return res.json();
                })
                .catch((error) => {
                    if (typeof error === 'string') {
                        return { error };
                    }
                    return error;
                });
            const { Valid, ValidatorModified } = json;
            const { ValidOverride } = address;
            const saved = !validateOnly || !Valid;
            const updateAddress = {
                ...initialAddress,
                ...json,
                saved,
                Valid,
                ValidOverride,
                isLoggedIn,
            };

            if (response.ok && (Valid || ValidOverride)) {
                if (!validateOnly && Valid && !ValidatorModified) {
                    dispatch(getSaved());
                }
                /*eslint-disable */
                const promises = [
                    dispatch(actions.setShippingAddressSuccess({ shippingAddress: updateAddress })),
                ];

                /*eslint-enable*/
                return Promise.allSettled(promises);
            }
            return Promise.reject(
                dispatch(
                    actions.setShippingAddressFailure({ ...json, ...initialAddress, saved: false })
                )
            );
        }
        return Promise.resolve();
    };
};
const getPickupLocations = () => {
    return async (dispatch, getState) => {
        const initialState = selectPickupLocationsSubslice(getState());
        const doFetch = api.shouldFetchAddress(initialState, 30000); //we just don't need pickup locations that often
        if (doFetch && (initialState.list || []).length === 0) {
            dispatch(actions.getPickupLocationsSent({ requested: true }));
            let response;
            const json = await api
                .fetchPickupLocations()
                .then((data) => {
                    response = data;
                    return data.json();
                })
                .catch((error) => {
                    response = error;
                    try {
                        return { error: error.toString() };
                    } catch {
                        return error;
                    }
                });

            if (response.ok) {
                return dispatch(actions.getPickupLocationsSuccess(json));
            }
            return Promise.reject(dispatch(actions.getPickupLocationsFailure(json)));
        }
        return Promise.resolve();
    };
};

export {
    getLastUsedAddress,
    getSaved,
    getCityStateForZip,
    sendShippingAddress,
    getPickupLocations,
};
